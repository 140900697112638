import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import { Button } from '../components';
import Loadable from 'react-loadable';
import {
    // Logout,
    MerchantRedirect,
    AmbassadorRedirect,
    ImpersonateRedirect,
} from '../components';
import Loading from '../pages/Loading';
import { AppLayout, BlankLayout, NoLinkAppLayout } from './layouts';

const Loader = props => {
    if (props.error) {
        console.log(props.error);
        if (!localStorage.getItem('CBLoaderError')) {
            localStorage.setItem('CBLoaderError', 'true');
            window.location.reload(true);
        }
        return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Error Loading Page
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This sometimes happens when we've made changes to the
                        site. Either click retry below or reload the page for
                        the latest updates!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.retry} color="primary">
                        Retry
                    </Button>
                    <Button
                        onClick={() => window.location.reload(true)}
                        color="primary"
                    >
                        Reload
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else if (props.pastDelay) {
        return <Loading />;
    } else if (props.isLoading) {
        return null;
    } else {
        localStorage.removeItem('CBLoaderError');
        return null;
    }
};

const page = p =>
    Loadable({
        loader: () => import(`../pages/${p}`),
        loading: Loader,
        delay: 300,
    });
const Login = page('Login');
const PrivateRoute = graphql(gql`
    {
        currentUser {
            id
            firstName
            lastName
            roles
        }
    }
`)(({ component: Component, data, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (data.loading) {
                return <Loading />;
            } else if (data.currentUser && data.currentUser.firstName) {
                return <Component {...props} />;
            } else {
                return <Login />;
            }
        }}
    />
));

export default () => (
    <Switch>
        <Route exact path="/merchant-redirect" component={MerchantRedirect} />
        <Route
            exact
            path="/impersonate/:token"
            component={ImpersonateRedirect}
        />
        <NoLinkAppLayout
            exact
            path="/m/:slug"
            component={page('MerchantInfo')}
        />
        <NoLinkAppLayout
            exact
            path="/rm/:slug"
            component={page('MerchantInfo')}
        />
        <Route
            exact
            path="/ambassador-redirect"
            component={AmbassadorRedirect}
        />
        <NoLinkAppLayout
            exact
            path="/a/:slug"
            component={page('AmbassadorInfo')}
        />
        <NoLinkAppLayout
            exact
            path="/ra/:slug"
            component={page('AmbassadorInfo')}
        />
        <BlankLayout
            path="/application/:id"
            component={page('MerchantApplication')}
        />
        <BlankLayout
            path="/proposal/:id"
            component={page('MerchantProposal')}
        />
        <AppLayout
            path="/"
            component={() => (
                <Switch>
                    {/* <Route exact path="/login" component={page('Login')} />
                    <Route exact path="/logout" component={Logout} /> */}
                    <Route
                        exact
                        path="/crm"
                        render={() => <Redirect to="/crm/marinating" />}
                    />

                    <PrivateRoute path="/crm/:status" component={page('CRM')} />
                    {/*<PrivateRoute
                        exact
                        path="/dash"
                        component={page('PendingReferrals')}
                    />
                    <PrivateRoute
                        path="/referMerchant"
                        component={page('ReferMerchant')}
                    />
                    <PrivateRoute
                        path="/referAmbassador"
                        component={page('ReferAmbassador')}
                    />
                    <PrivateRoute path="/reports" component={page('Reports')} />
                    <PrivateRoute
                        path="/MerchantLead/:id"
                        component={page('ViewMerchantLead')}
                    />
                    <PrivateRoute
                        path="/Merchant/:id"
                        component={page('ViewMerchant')}
                    />
                    <PrivateRoute
                        path="/AmbassadorLead/:id"
                        component={page('ViewAmbassadorLead')}
                    />
                    <PrivateRoute
                        path="/Ambassador/:id"
                        component={page('ViewAmbassador')}
                    />
                    <PrivateRoute
                        path="/estimator"
                        component={page('AmbassadorCommissionEstimator')}
                    />
                    <PrivateRoute
                        path="/downline"
                        component={page('Downline')}
                    />
                    <PrivateRoute
                        path="/video-decider"
                        component={page('VideoDecider')}
                    />
                    <PrivateRoute
                        path="/knowledge-base"
                        component={page('KnowledgeBase')}
                    />
                    <PrivateRoute
                        path="/view-all/:slug"
                        component={page('ViewAll')}
                    />
                    <PrivateRoute
                        path="/generate-email"
                        component={page('ReferMerchantEmailGenerator')}
                    />
                    <PrivateRoute exact path="/" component={page('Main')} /> */}
                    <Route component={page('Main')} />
                </Switch>
            )}
        />
    </Switch>
);
