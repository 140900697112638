import * as React from 'react';
import { pathOr } from 'ramda';
import { LoginCurrentUserComponent } from '../generated/prisma';

export class AdminOnly extends React.Component {
    render() {
        return (
            <LoginCurrentUserComponent
                children={({ data }) =>
                    pathOr([], ['currentUser', 'roles'], data).includes('ADMIN')
                        ? this.props.children
                        : null
                }
            />
        );
    }
}

export class If extends React.Component<{ condition: boolean }> {
    render() {
        return this.props.condition ? this.props.children : null;
    }
}
