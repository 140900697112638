import React, { Fragment } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import './App.css';

import Routes from './config/routes';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
jss.options.insertionPoint = document.getElementById('jss-insertion-point');

const uri = `${process.env.REACT_APP_API_ENDPOINT}/prisma`;
const httpLink = new BatchHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
    const token =
        sessionStorage.getItem('CBImpersonateToken') ||
        localStorage.getItem('CBToken');
    // console.log({ token });
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
const App = () => {
    const typography = {
        fontFamily: ['Montserrat', 'sans-serif'],
        useNextVariants: true,
    };
    const palette = {
        type: 'dark',
        primary: { main: 'rgb(63, 208, 152)' },
        background: {
            paper: 'rgba(41,41,60,0.85)',
            default: 'rgba(41,41,60,0.85)',
        },
        secondary: { main: '#ffffff' },
    };

    return (
        <Fragment>
            <ApolloProvider client={client}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <JssProvider
                        jss={jss}
                        generateClassName={generateClassName}
                    >
                        <BrowserRouter>
                            <MuiThemeProvider
                                theme={createMuiTheme({
                                    palette,
                                    typography,
                                })}
                            >
                                <Routes />
                            </MuiThemeProvider>
                        </BrowserRouter>
                    </JssProvider>
                </MuiPickersUtilsProvider>
            </ApolloProvider>
        </Fragment>
    );
};

export default App;
