import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { ApolloClient } from 'apollo-client';

class Logout extends React.Component<{ client: ApolloClient<any> }> {
    render() {
        localStorage.removeItem('CBToken');
        this.props.client.resetStore();
        return <Redirect to="/login" />;
    }
}

export default withApollo(Logout);
