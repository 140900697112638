import * as React from 'react';
import MaskedInput from 'react-text-mask';
import * as PropTypes from 'prop-types';
import TextField from './TextField';
import { TextFieldProps } from '@material-ui/core/TextField';

const TF = TextField as any;
const mask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const TextMaskCustom: React.SFC<any> = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={mask}
            type="tel"
            placeholderChar={'\u2000'}
        />
    );
    // style={{ textAlign: 'center' }}
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default class SinglePhoneInput extends React.Component<TextFieldProps> {
    state = { val: '' };
    componentWillMount() {
        this.setState({ val: this.props.value });
    }
    componentWillReceiveProps(nextProps: TextFieldProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ val: nextProps.value });
        }
    }
    render() {
        const { InputProps = {}, ...other } = this.props;
        return (
            <TF
                {...other}
                value={this.state.val}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ val: e.target.value });
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (this.props.onBlur) {
                        this.props.onBlur(e);
                    }
                }}
                InputProps={{ inputComponent: TextMaskCustom, ...InputProps }}
            />
        );
    }
}
