import styled from 'react-emotion';

export const Table = styled('div')<{
    columns: string;
    style?: { [key: string]: string };
}>(({ columns, style = {} }) => ({
    flex: 1,
    ...style,
    display: 'grid',
    gridRowGap: '12.5px',
    gridTemplateColumns: columns,
    gridAutoRows: '1fr',
}));

interface IAlignments {
    left: string;
    center: string;
    right: string;
}

const alignments: IAlignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

export const Cell = styled('div')<{
    align?: keyof IAlignments;
    header?: boolean;
    bold?: boolean;
    span?: number;
}>(({ align = 'left' as keyof IAlignments, header, bold, span = 1 }) => ({
    fontSize: header ? '10px' : '12px',
    fontWeight: bold ? 500 : 300,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: alignments[align],
    gridColumn: `span ${span}`,
}));
