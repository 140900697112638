import * as React from 'react';
import * as qs from 'qs';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

class MerchantRedirect extends React.Component<RouteComponentProps<any>> {
    render() {
        const state = qs.parse(location.search.slice(1));
        localStorage.setItem('CBRedirectParams', location.search.slice(1));
        return <Redirect to={{ pathname: '/merchant-info', state }} />;
    }
}

export default withRouter(MerchantRedirect);
