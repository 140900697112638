import * as React from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

class ImpersonateRedirect extends React.Component<
    RouteComponentProps<{ token: string }>
> {
    render() {
        console.log(this.props.match);
        const { token } = this.props.match.params;
        sessionStorage.setItem('CBImpersonateToken', token);
        return <Redirect to={{ pathname: '/' }} />;
    }
}

export default withRouter(ImpersonateRedirect);
