import * as React from 'react';
import styled from 'react-emotion';
import { GREEN } from '../config/globals';

const BackgroundDiv = styled('div')({
    color: '#fff',
    backgroundImage:
        'linear-gradient(0deg, rgba(11,11,15,1) 0%, rgba(55,56,75,1) 100%)',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
});

const ContainerDiv = styled('div')({
    maxWidth: '1000px',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: '25px',
});

export class Background extends React.Component {
    render() {
        return (
            <BackgroundDiv>
                <ContainerDiv>{this.props.children}</ContainerDiv>
            </BackgroundDiv>
        );
    }
}

const SectionBody = styled('section')<{
    transparent?: boolean;
    flex?: boolean;
    bubble?: boolean;
    center?: boolean;
}>(({ transparent, flex, bubble, center }) => ({
    padding: bubble ? '45px 40px 20px 40px' : '20px 40px',
    backgroundColor: transparent ? 'transparent' : 'rgba(255,255,255,0.15)',
    '&:not(:last-child)': {
        marginBottom: '5px',
    },
    flex: flex ? 1 : 1,
    ...(center
        ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }
        : {}),
}));

const SectionHeader = styled('div')({
    padding: '15px 40px',
    fontSize: '12px',
    fontWeight: 300,
    backgroundColor: GREEN,
    display: 'flex',
    flexDirection: 'row',
});

export const Bubble = styled('div')({
    width: '70%',
    maxWidth: '500px',
    color: '#fff',
    fontWeight: 300,
    fontSize: '15px',
    textAlign: 'center',
    margin: 'auto',
    borderRadius: '45px',
    backgroundColor: GREEN,
    padding: '15px 0',
    position: 'relative',
    zIndex: 1100,
    opacity: 0.99,
    marginBottom: '-25px',
});

export const SectionGroup = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 700px)': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '5px',
    },
});

export class Section extends React.Component<{
    bubble?: boolean;
    header?: React.ReactElement<any> | string;
    transparent?: boolean;
    flex?: boolean;
    center?: boolean;
}> {
    render() {
        const { header, bubble, children, ...props } = this.props;
        const Header = bubble ? Bubble : SectionHeader;
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {header ? <Header>{header}</Header> : null}
                <SectionBody bubble={bubble} {...props}>
                    {children}
                </SectionBody>
            </div>
        );
    }
}

interface ISizes {
    sm: string;
    md: string;
    lg: string;
    xl: string;
}

const titleSizes: ISizes = {
    sm: '12px',
    md: '14px',
    lg: '18px',
    xl: '22px',
};
export const Title = styled('h1')<{ size: keyof ISizes }>(
    ({ size = 'md' as keyof ISizes }) => ({
        color: '#fff',
        margin: '5px 0px',
        fontWeight: 300,
        fontSize: titleSizes[size],
    }),
);
