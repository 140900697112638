export const FORMATTED_PHONE = '(888) 562-6489';
export const TEL_HREF = 'tel:18885626489';
export const GREEN = '#56BF93';
export const PURPLE = '#37384B';
export const DANGER_RED = '#bf5656';
export const WARNING_YELLOW = '#bfbb56';
export const VIMEO_FOUNDER = 'https://vimeo.com/275940796';
export const VIMEO_DONUT = 'https://vimeo.com/275941397';
export const VIMEO_CARTOON = 'https://vimeo.com/260112431';
export const VIMEO_AMBASSADOR = 'https://vimeo.com/276078065';
