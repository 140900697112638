import React from 'react';
import { withStyles } from '@material-ui/core';
import { curry } from 'ramda';

export const styleRoot = curry((component, styles) => {
    const fn = ({ classes, ...props }) =>
        React.createElement(component, { className: classes.root, ...props });
    return withStyles(theme => ({
        root: typeof styles === 'function' ? styles(theme) : styles,
    }))(fn);
});

export const styleKeys = curry((component, styles) => {
    const fn = props => React.createElement(component, props);
    return withStyles(
        typeof styles === 'function' ? theme => styles(theme) : styles,
    )(fn);
});

export default new Proxy(styleRoot, { get: (obj, prop) => obj(prop) });

export const Grid = ({
    component = 'div',
    style = {},
    area,
    areas,
    rows,
    autoRows,
    columns,
    autoColumns,
    row,
    column,
    gap,
    children,
    ...rest
}) => {
    const s = {
        ...style,
        gridGap: gap,
        gridRow: row,
        gridColumn: column,
        display: areas || rows || columns || gap ? 'grid' : 'block',
        gridTemplateAreas: areas,
        gridTemplateRows: rows,
        gridTemplateColumns: columns,
        gridAutoRows: autoRows,
        gridAutoColumns: autoColumns,
        border: '1px solid white',
    };
    if (area) {
        s.gridArea = area;
    }

    return React.createElement(component, { style: s, children, ...rest });
};
