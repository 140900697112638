import styled from 'react-emotion';
import { TextField } from '@material-ui/core';

export default styled(TextField)({
    width: '100%',
    '& label': {
        fontSize: '16px',
        fontWeight: 300,
    },
    '& input': {
        fontSize: '16px',
        fontWeight: 300,
    },
    '& input::placeholder': {
        fontSize: '16px',
        fontWeight: 300,
    },
});
