import * as React from 'react';
import * as qs from 'qs';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {}
class AmbassadorRedirect extends React.Component<Props> {
    render() {
        const state = qs.parse(this.props.location.search.slice(1));
        localStorage.setItem('CBRedirectParams', location.search.slice(1));
        return <Redirect to={{ pathname: '/ambassador-info', state }} />;
    }
}

export default withRouter(AmbassadorRedirect);
