import * as React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading: React.SFC = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <CircularProgress size={100} />
        </div>
    );
};

export default Loading;
