import * as React from 'react';
import styled from 'react-emotion';
import Button from '@material-ui/core/Button';
import { GREEN, PURPLE } from '../config/globals';

interface ColorMap {
    primary: string;
    secondary: string;
    lighter: string;
    darker: string;
}
const bgColors: ColorMap = {
    primary: GREEN,
    secondary: '#fff',
    lighter: 'rgba(255,255,255,0.2)',
    darker: 'rgba(0,0,0,0.2)',
};

const colors: ColorMap = {
    primary: '#fff',
    secondary: PURPLE,
    lighter: 'rgba(255,255,255,0.8)',
    darker: 'rgba(0,0,0,0.8)',
};

const disabledColors: ColorMap = {
    primary: '#ccc',
    secondary: '#ccc',
    lighter: 'rgba(255,255,255,0.3)',
    darker: 'rgba(0,0,0,0.3)',
};

export default styled(Button)<{ bg?: keyof ColorMap; margin?: string }>(
    ({ bg = 'primary' as keyof ColorMap, margin }) => ({
        borderRadius: '0px',
        boxShadow: 'none',
        backgroundColor: bgColors[bg],
        color: colors[bg],
        width: '100%',
        fontWeight: 300,
        textTransform: 'none',
        margin,
        '@media (hover: none)': {
            backgroundColor: `${bgColors[bg]} !important`,
        },
        '&:disabled': {
            color: disabledColors[bg],
        },
    }),
);

export const ButtonGroup = styled('div')<{
    direction?: 'row' | 'column';
    style?: { [key: string]: string };
    children: any;
}>(({ direction = 'row', style = {}, children }) => {
    const count = React.Children.toArray(children).length;
    return {
        ...style,
        display: 'grid',
        gridTemplateColumns:
            direction === 'row' ? `repeat(${count}, 1fr)` : '1fr',
        gridTemplateRows: direction === 'row' ? '1fr' : `repeat(${count}, 1fr)`,
        gridGap: '5px',
    };
});
