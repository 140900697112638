import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Paper,
} from '@material-ui/core';
import { AdminOnly } from './Conditionals';
import Button from './Button';

import Menu from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import Timeline from '@material-ui/icons/Timeline';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { LoginCurrentUserComponent } from '../generated/prisma';

import styled from 'react-emotion';
import Logo from './CB_Logo.png';

const Title = styled(Typography)({ flex: 1 });
const Bar = styled(AppBar as any)({
    zIndex: 1500,
    position: 'static',
    '@media (min-width: 700px)': {
        position: 'fixed',
    },
});
const NavDrawer = styled(Drawer)({
    zIndex: 9999,
});

export default class Navbar extends React.Component<{ links?: boolean }> {
    state = { menu: false, showSnack: true };
    render() {
        const { menu, showSnack } = this.state;
        const { links = true } = this.props;
        return (
            <React.Fragment>
                <Bar color="secondary">
                    <Toolbar>
                        <Title variant="h6" color="inherit">
                            {links ? (
                                <Link to="/" style={{ color: 'white' }}>
                                    <img
                                        src={Logo}
                                        alt="Commision Buddies Logo"
                                        style={{ width: '200px' }}
                                    />
                                </Link>
                            ) : (
                                <img
                                    src={Logo}
                                    alt="Commision Buddies Logo"
                                    style={{ width: '200px' }}
                                />
                            )}
                        </Title>
                        {links ? (
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                onClick={() => this.setState({ menu: true })}
                            >
                                <Menu />
                            </IconButton>
                        ) : null}
                    </Toolbar>
                </Bar>
                <NavDrawer
                    open={menu}
                    onClose={() => this.setState({ menu: false })}
                    anchor="right"
                >
                    <div style={{ width: 240 }}>
                        <List>
                            <Link
                                to="/"
                                onClick={() => this.setState({ menu: false })}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <Home />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </ListItem>
                            </Link>
                            <Link
                                to="/downline"
                                onClick={() => this.setState({ menu: false })}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <Timeline />
                                    </ListItemIcon>
                                    <ListItemText primary="Downline" />
                                </ListItem>
                            </Link>
                            <AdminOnly>
                                <Link
                                    to="/crm"
                                    onClick={() =>
                                        this.setState({ menu: false })
                                    }
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <SupervisedUserCircle />
                                        </ListItemIcon>
                                        <ListItemText primary="CRM" />
                                    </ListItem>
                                </Link>
                            </AdminOnly>
                            <AdminOnly>
                                <Link
                                    to="/view-all/Merchant"
                                    onClick={() =>
                                        this.setState({ menu: false })
                                    }
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <MenuIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View All" />
                                    </ListItem>
                                </Link>
                            </AdminOnly>
                            <Link
                                to="/logout"
                                onClick={() => this.setState({ menu: false })}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <ExitToApp />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItem>
                            </Link>
                        </List>
                    </div>
                </NavDrawer>
                {Boolean(sessionStorage.getItem('CBImpersonateToken')) ? (
                    <Paper style={{ textAlign: 'center' }}>
                        <LoginCurrentUserComponent
                            children={({ data }) => (
                                <Typography variant="h5">
                                    You are currently impersonating{' '}
                                    {data && data.currentUser
                                        ? ` ${data.currentUser.firstName} ${
                                              data.currentUser.lastName
                                          }`
                                        : ''}
                                    .
                                    <Button
                                        variant="contained"
                                        style={{
                                            width: '50px',
                                            marginLeft: '10px',
                                        }}
                                        onClick={() => {
                                            sessionStorage.removeItem(
                                                'CBImpersonateToken',
                                            );
                                            window.location.reload();
                                        }}
                                    >
                                        Stop
                                    </Button>
                                </Typography>
                            )}
                        />
                    </Paper>
                ) : null}
            </React.Fragment>
        );
    }
}
