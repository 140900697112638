export interface AmbassadorLeadWhereInput {
    /** Logical AND on all given filters. */
    AND?: AmbassadorLeadWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: AmbassadorLeadWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: AmbassadorLeadWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    status?: AmbassadorLeadStatus | null;
    /** All values that are not equal to given value. */
    status_not?: AmbassadorLeadStatus | null;
    /** All values that are contained in given list. */
    status_in?: AmbassadorLeadStatus[] | null;
    /** All values that are not contained in given list. */
    status_not_in?: AmbassadorLeadStatus[] | null;

    phone?: string | null;
    /** All values that are not equal to given value. */
    phone_not?: string | null;
    /** All values that are contained in given list. */
    phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    phone_not_in?: string[] | null;
    /** All values less than the given value. */
    phone_lt?: string | null;
    /** All values less than or equal the given value. */
    phone_lte?: string | null;
    /** All values greater than the given value. */
    phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    phone_gte?: string | null;
    /** All values containing the given string. */
    phone_contains?: string | null;
    /** All values not containing the given string. */
    phone_not_contains?: string | null;
    /** All values starting with the given string. */
    phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    phone_not_ends_with?: string | null;

    firstName?: string | null;
    /** All values that are not equal to given value. */
    firstName_not?: string | null;
    /** All values that are contained in given list. */
    firstName_in?: string[] | null;
    /** All values that are not contained in given list. */
    firstName_not_in?: string[] | null;
    /** All values less than the given value. */
    firstName_lt?: string | null;
    /** All values less than or equal the given value. */
    firstName_lte?: string | null;
    /** All values greater than the given value. */
    firstName_gt?: string | null;
    /** All values greater than or equal the given value. */
    firstName_gte?: string | null;
    /** All values containing the given string. */
    firstName_contains?: string | null;
    /** All values not containing the given string. */
    firstName_not_contains?: string | null;
    /** All values starting with the given string. */
    firstName_starts_with?: string | null;
    /** All values not starting with the given string. */
    firstName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    firstName_ends_with?: string | null;
    /** All values not ending with the given string. */
    firstName_not_ends_with?: string | null;

    lastName?: string | null;
    /** All values that are not equal to given value. */
    lastName_not?: string | null;
    /** All values that are contained in given list. */
    lastName_in?: string[] | null;
    /** All values that are not contained in given list. */
    lastName_not_in?: string[] | null;
    /** All values less than the given value. */
    lastName_lt?: string | null;
    /** All values less than or equal the given value. */
    lastName_lte?: string | null;
    /** All values greater than the given value. */
    lastName_gt?: string | null;
    /** All values greater than or equal the given value. */
    lastName_gte?: string | null;
    /** All values containing the given string. */
    lastName_contains?: string | null;
    /** All values not containing the given string. */
    lastName_not_contains?: string | null;
    /** All values starting with the given string. */
    lastName_starts_with?: string | null;
    /** All values not starting with the given string. */
    lastName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    lastName_ends_with?: string | null;
    /** All values not ending with the given string. */
    lastName_not_ends_with?: string | null;

    email?: string | null;
    /** All values that are not equal to given value. */
    email_not?: string | null;
    /** All values that are contained in given list. */
    email_in?: string[] | null;
    /** All values that are not contained in given list. */
    email_not_in?: string[] | null;
    /** All values less than the given value. */
    email_lt?: string | null;
    /** All values less than or equal the given value. */
    email_lte?: string | null;
    /** All values greater than the given value. */
    email_gt?: string | null;
    /** All values greater than or equal the given value. */
    email_gte?: string | null;
    /** All values containing the given string. */
    email_contains?: string | null;
    /** All values not containing the given string. */
    email_not_contains?: string | null;
    /** All values starting with the given string. */
    email_starts_with?: string | null;
    /** All values not starting with the given string. */
    email_not_starts_with?: string | null;
    /** All values ending with the given string. */
    email_ends_with?: string | null;
    /** All values not ending with the given string. */
    email_not_ends_with?: string | null;

    slug?: string | null;
    /** All values that are not equal to given value. */
    slug_not?: string | null;
    /** All values that are contained in given list. */
    slug_in?: string[] | null;
    /** All values that are not contained in given list. */
    slug_not_in?: string[] | null;
    /** All values less than the given value. */
    slug_lt?: string | null;
    /** All values less than or equal the given value. */
    slug_lte?: string | null;
    /** All values greater than the given value. */
    slug_gt?: string | null;
    /** All values greater than or equal the given value. */
    slug_gte?: string | null;
    /** All values containing the given string. */
    slug_contains?: string | null;
    /** All values not containing the given string. */
    slug_not_contains?: string | null;
    /** All values starting with the given string. */
    slug_starts_with?: string | null;
    /** All values not starting with the given string. */
    slug_not_starts_with?: string | null;
    /** All values ending with the given string. */
    slug_ends_with?: string | null;
    /** All values not ending with the given string. */
    slug_not_ends_with?: string | null;

    upline?: UserWhereInput | null;

    events_every?: EventWhereInput | null;

    events_some?: EventWhereInput | null;

    events_none?: EventWhereInput | null;
}

export interface UserWhereInput {
    /** Logical AND on all given filters. */
    AND?: UserWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: UserWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: UserWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    phone?: string | null;
    /** All values that are not equal to given value. */
    phone_not?: string | null;
    /** All values that are contained in given list. */
    phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    phone_not_in?: string[] | null;
    /** All values less than the given value. */
    phone_lt?: string | null;
    /** All values less than or equal the given value. */
    phone_lte?: string | null;
    /** All values greater than the given value. */
    phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    phone_gte?: string | null;
    /** All values containing the given string. */
    phone_contains?: string | null;
    /** All values not containing the given string. */
    phone_not_contains?: string | null;
    /** All values starting with the given string. */
    phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    phone_not_ends_with?: string | null;

    firstName?: string | null;
    /** All values that are not equal to given value. */
    firstName_not?: string | null;
    /** All values that are contained in given list. */
    firstName_in?: string[] | null;
    /** All values that are not contained in given list. */
    firstName_not_in?: string[] | null;
    /** All values less than the given value. */
    firstName_lt?: string | null;
    /** All values less than or equal the given value. */
    firstName_lte?: string | null;
    /** All values greater than the given value. */
    firstName_gt?: string | null;
    /** All values greater than or equal the given value. */
    firstName_gte?: string | null;
    /** All values containing the given string. */
    firstName_contains?: string | null;
    /** All values not containing the given string. */
    firstName_not_contains?: string | null;
    /** All values starting with the given string. */
    firstName_starts_with?: string | null;
    /** All values not starting with the given string. */
    firstName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    firstName_ends_with?: string | null;
    /** All values not ending with the given string. */
    firstName_not_ends_with?: string | null;

    lastName?: string | null;
    /** All values that are not equal to given value. */
    lastName_not?: string | null;
    /** All values that are contained in given list. */
    lastName_in?: string[] | null;
    /** All values that are not contained in given list. */
    lastName_not_in?: string[] | null;
    /** All values less than the given value. */
    lastName_lt?: string | null;
    /** All values less than or equal the given value. */
    lastName_lte?: string | null;
    /** All values greater than the given value. */
    lastName_gt?: string | null;
    /** All values greater than or equal the given value. */
    lastName_gte?: string | null;
    /** All values containing the given string. */
    lastName_contains?: string | null;
    /** All values not containing the given string. */
    lastName_not_contains?: string | null;
    /** All values starting with the given string. */
    lastName_starts_with?: string | null;
    /** All values not starting with the given string. */
    lastName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    lastName_ends_with?: string | null;
    /** All values not ending with the given string. */
    lastName_not_ends_with?: string | null;

    email?: string | null;
    /** All values that are not equal to given value. */
    email_not?: string | null;
    /** All values that are contained in given list. */
    email_in?: string[] | null;
    /** All values that are not contained in given list. */
    email_not_in?: string[] | null;
    /** All values less than the given value. */
    email_lt?: string | null;
    /** All values less than or equal the given value. */
    email_lte?: string | null;
    /** All values greater than the given value. */
    email_gt?: string | null;
    /** All values greater than or equal the given value. */
    email_gte?: string | null;
    /** All values containing the given string. */
    email_contains?: string | null;
    /** All values not containing the given string. */
    email_not_contains?: string | null;
    /** All values starting with the given string. */
    email_starts_with?: string | null;
    /** All values not starting with the given string. */
    email_not_starts_with?: string | null;
    /** All values ending with the given string. */
    email_ends_with?: string | null;
    /** All values not ending with the given string. */
    email_not_ends_with?: string | null;

    smsCode?: string | null;
    /** All values that are not equal to given value. */
    smsCode_not?: string | null;
    /** All values that are contained in given list. */
    smsCode_in?: string[] | null;
    /** All values that are not contained in given list. */
    smsCode_not_in?: string[] | null;
    /** All values less than the given value. */
    smsCode_lt?: string | null;
    /** All values less than or equal the given value. */
    smsCode_lte?: string | null;
    /** All values greater than the given value. */
    smsCode_gt?: string | null;
    /** All values greater than or equal the given value. */
    smsCode_gte?: string | null;
    /** All values containing the given string. */
    smsCode_contains?: string | null;
    /** All values not containing the given string. */
    smsCode_not_contains?: string | null;
    /** All values starting with the given string. */
    smsCode_starts_with?: string | null;
    /** All values not starting with the given string. */
    smsCode_not_starts_with?: string | null;
    /** All values ending with the given string. */
    smsCode_ends_with?: string | null;
    /** All values not ending with the given string. */
    smsCode_not_ends_with?: string | null;

    userRoles_every?: RoleWhereInput | null;

    userRoles_some?: RoleWhereInput | null;

    userRoles_none?: RoleWhereInput | null;

    enroller?: UserWhereInput | null;

    downline_every?: UserWhereInput | null;

    downline_some?: UserWhereInput | null;

    downline_none?: UserWhereInput | null;

    merchants_every?: MerchantWhereInput | null;

    merchants_some?: MerchantWhereInput | null;

    merchants_none?: MerchantWhereInput | null;

    ambassadorLeads_every?: AmbassadorLeadWhereInput | null;

    ambassadorLeads_some?: AmbassadorLeadWhereInput | null;

    ambassadorLeads_none?: AmbassadorLeadWhereInput | null;

    merchantLeads_every?: MerchantLeadWhereInput | null;

    merchantLeads_some?: MerchantLeadWhereInput | null;

    merchantLeads_none?: MerchantLeadWhereInput | null;

    draftMerchants_every?: DraftMerchantWhereInput | null;

    draftMerchants_some?: DraftMerchantWhereInput | null;

    draftMerchants_none?: DraftMerchantWhereInput | null;

    ownedBusinesses_every?: MerchantWhereInput | null;

    ownedBusinesses_some?: MerchantWhereInput | null;

    ownedBusinesses_none?: MerchantWhereInput | null;

    followups_every?: MerchantLeadFollowupWhereInput | null;

    followups_some?: MerchantLeadFollowupWhereInput | null;

    followups_none?: MerchantLeadFollowupWhereInput | null;
}

export interface RoleWhereInput {
    /** Logical AND on all given filters. */
    AND?: RoleWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: RoleWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: RoleWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    name?: UserRole | null;
    /** All values that are not equal to given value. */
    name_not?: UserRole | null;
    /** All values that are contained in given list. */
    name_in?: UserRole[] | null;
    /** All values that are not contained in given list. */
    name_not_in?: UserRole[] | null;
}

export interface MerchantWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    businessName?: string | null;
    /** All values that are not equal to given value. */
    businessName_not?: string | null;
    /** All values that are contained in given list. */
    businessName_in?: string[] | null;
    /** All values that are not contained in given list. */
    businessName_not_in?: string[] | null;
    /** All values less than the given value. */
    businessName_lt?: string | null;
    /** All values less than or equal the given value. */
    businessName_lte?: string | null;
    /** All values greater than the given value. */
    businessName_gt?: string | null;
    /** All values greater than or equal the given value. */
    businessName_gte?: string | null;
    /** All values containing the given string. */
    businessName_contains?: string | null;
    /** All values not containing the given string. */
    businessName_not_contains?: string | null;
    /** All values starting with the given string. */
    businessName_starts_with?: string | null;
    /** All values not starting with the given string. */
    businessName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    businessName_ends_with?: string | null;
    /** All values not ending with the given string. */
    businessName_not_ends_with?: string | null;

    industry?: string | null;
    /** All values that are not equal to given value. */
    industry_not?: string | null;
    /** All values that are contained in given list. */
    industry_in?: string[] | null;
    /** All values that are not contained in given list. */
    industry_not_in?: string[] | null;
    /** All values less than the given value. */
    industry_lt?: string | null;
    /** All values less than or equal the given value. */
    industry_lte?: string | null;
    /** All values greater than the given value. */
    industry_gt?: string | null;
    /** All values greater than or equal the given value. */
    industry_gte?: string | null;
    /** All values containing the given string. */
    industry_contains?: string | null;
    /** All values not containing the given string. */
    industry_not_contains?: string | null;
    /** All values starting with the given string. */
    industry_starts_with?: string | null;
    /** All values not starting with the given string. */
    industry_not_starts_with?: string | null;
    /** All values ending with the given string. */
    industry_ends_with?: string | null;
    /** All values not ending with the given string. */
    industry_not_ends_with?: string | null;

    merchantNumber?: string | null;
    /** All values that are not equal to given value. */
    merchantNumber_not?: string | null;
    /** All values that are contained in given list. */
    merchantNumber_in?: string[] | null;
    /** All values that are not contained in given list. */
    merchantNumber_not_in?: string[] | null;
    /** All values less than the given value. */
    merchantNumber_lt?: string | null;
    /** All values less than or equal the given value. */
    merchantNumber_lte?: string | null;
    /** All values greater than the given value. */
    merchantNumber_gt?: string | null;
    /** All values greater than or equal the given value. */
    merchantNumber_gte?: string | null;
    /** All values containing the given string. */
    merchantNumber_contains?: string | null;
    /** All values not containing the given string. */
    merchantNumber_not_contains?: string | null;
    /** All values starting with the given string. */
    merchantNumber_starts_with?: string | null;
    /** All values not starting with the given string. */
    merchantNumber_not_starts_with?: string | null;
    /** All values ending with the given string. */
    merchantNumber_ends_with?: string | null;
    /** All values not ending with the given string. */
    merchantNumber_not_ends_with?: string | null;

    bid?: string | null;
    /** All values that are not equal to given value. */
    bid_not?: string | null;
    /** All values that are contained in given list. */
    bid_in?: string[] | null;
    /** All values that are not contained in given list. */
    bid_not_in?: string[] | null;
    /** All values less than the given value. */
    bid_lt?: string | null;
    /** All values less than or equal the given value. */
    bid_lte?: string | null;
    /** All values greater than the given value. */
    bid_gt?: string | null;
    /** All values greater than or equal the given value. */
    bid_gte?: string | null;
    /** All values containing the given string. */
    bid_contains?: string | null;
    /** All values not containing the given string. */
    bid_not_contains?: string | null;
    /** All values starting with the given string. */
    bid_starts_with?: string | null;
    /** All values not starting with the given string. */
    bid_not_starts_with?: string | null;
    /** All values ending with the given string. */
    bid_ends_with?: string | null;
    /** All values not ending with the given string. */
    bid_not_ends_with?: string | null;

    visaBP?: number | null;
    /** All values that are not equal to given value. */
    visaBP_not?: number | null;
    /** All values that are contained in given list. */
    visaBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    visaBP_not_in?: number[] | null;
    /** All values less than the given value. */
    visaBP_lt?: number | null;
    /** All values less than or equal the given value. */
    visaBP_lte?: number | null;
    /** All values greater than the given value. */
    visaBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    visaBP_gte?: number | null;

    visaCents?: number | null;
    /** All values that are not equal to given value. */
    visaCents_not?: number | null;
    /** All values that are contained in given list. */
    visaCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    visaCents_not_in?: number[] | null;
    /** All values less than the given value. */
    visaCents_lt?: number | null;
    /** All values less than or equal the given value. */
    visaCents_lte?: number | null;
    /** All values greater than the given value. */
    visaCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    visaCents_gte?: number | null;

    mastercardBP?: number | null;
    /** All values that are not equal to given value. */
    mastercardBP_not?: number | null;
    /** All values that are contained in given list. */
    mastercardBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    mastercardBP_not_in?: number[] | null;
    /** All values less than the given value. */
    mastercardBP_lt?: number | null;
    /** All values less than or equal the given value. */
    mastercardBP_lte?: number | null;
    /** All values greater than the given value. */
    mastercardBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    mastercardBP_gte?: number | null;

    mastercardCents?: number | null;
    /** All values that are not equal to given value. */
    mastercardCents_not?: number | null;
    /** All values that are contained in given list. */
    mastercardCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    mastercardCents_not_in?: number[] | null;
    /** All values less than the given value. */
    mastercardCents_lt?: number | null;
    /** All values less than or equal the given value. */
    mastercardCents_lte?: number | null;
    /** All values greater than the given value. */
    mastercardCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    mastercardCents_gte?: number | null;

    discoverBP?: number | null;
    /** All values that are not equal to given value. */
    discoverBP_not?: number | null;
    /** All values that are contained in given list. */
    discoverBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    discoverBP_not_in?: number[] | null;
    /** All values less than the given value. */
    discoverBP_lt?: number | null;
    /** All values less than or equal the given value. */
    discoverBP_lte?: number | null;
    /** All values greater than the given value. */
    discoverBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    discoverBP_gte?: number | null;

    discoverCents?: number | null;
    /** All values that are not equal to given value. */
    discoverCents_not?: number | null;
    /** All values that are contained in given list. */
    discoverCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    discoverCents_not_in?: number[] | null;
    /** All values less than the given value. */
    discoverCents_lt?: number | null;
    /** All values less than or equal the given value. */
    discoverCents_lte?: number | null;
    /** All values greater than the given value. */
    discoverCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    discoverCents_gte?: number | null;

    amexBP?: number | null;
    /** All values that are not equal to given value. */
    amexBP_not?: number | null;
    /** All values that are contained in given list. */
    amexBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    amexBP_not_in?: number[] | null;
    /** All values less than the given value. */
    amexBP_lt?: number | null;
    /** All values less than or equal the given value. */
    amexBP_lte?: number | null;
    /** All values greater than the given value. */
    amexBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    amexBP_gte?: number | null;

    amexCents?: number | null;
    /** All values that are not equal to given value. */
    amexCents_not?: number | null;
    /** All values that are contained in given list. */
    amexCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    amexCents_not_in?: number[] | null;
    /** All values less than the given value. */
    amexCents_lt?: number | null;
    /** All values less than or equal the given value. */
    amexCents_lte?: number | null;
    /** All values greater than the given value. */
    amexCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    amexCents_gte?: number | null;

    monthlyFees?: number | null;
    /** All values that are not equal to given value. */
    monthlyFees_not?: number | null;
    /** All values that are contained in given list. */
    monthlyFees_in?: number[] | null;
    /** All values that are not contained in given list. */
    monthlyFees_not_in?: number[] | null;
    /** All values less than the given value. */
    monthlyFees_lt?: number | null;
    /** All values less than or equal the given value. */
    monthlyFees_lte?: number | null;
    /** All values greater than the given value. */
    monthlyFees_gt?: number | null;
    /** All values greater than or equal the given value. */
    monthlyFees_gte?: number | null;

    monthlyFixedExpenses?: number | null;
    /** All values that are not equal to given value. */
    monthlyFixedExpenses_not?: number | null;
    /** All values that are contained in given list. */
    monthlyFixedExpenses_in?: number[] | null;
    /** All values that are not contained in given list. */
    monthlyFixedExpenses_not_in?: number[] | null;
    /** All values less than the given value. */
    monthlyFixedExpenses_lt?: number | null;
    /** All values less than or equal the given value. */
    monthlyFixedExpenses_lte?: number | null;
    /** All values greater than the given value. */
    monthlyFixedExpenses_gt?: number | null;
    /** All values greater than or equal the given value. */
    monthlyFixedExpenses_gte?: number | null;

    expressMerchantFunding?: boolean | null;
    /** All values that are not equal to given value. */
    expressMerchantFunding_not?: boolean | null;

    owner?: UserWhereInput | null;

    enroller?: UserWhereInput | null;

    dailyEstimates_every?: MerchantDailyEstimateWhereInput | null;

    dailyEstimates_some?: MerchantDailyEstimateWhereInput | null;

    dailyEstimates_none?: MerchantDailyEstimateWhereInput | null;

    monthlyCommissions_every?: MerchantMonthlyCommissionWhereInput | null;

    monthlyCommissions_some?: MerchantMonthlyCommissionWhereInput | null;

    monthlyCommissions_none?: MerchantMonthlyCommissionWhereInput | null;

    commissionDeposits_every?: MerchantCommissionDepositWhereInput | null;

    commissionDeposits_some?: MerchantCommissionDepositWhereInput | null;

    commissionDeposits_none?: MerchantCommissionDepositWhereInput | null;
}

export interface MerchantDailyEstimateWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantDailyEstimateWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantDailyEstimateWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantDailyEstimateWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    key?: string | null;
    /** All values that are not equal to given value. */
    key_not?: string | null;
    /** All values that are contained in given list. */
    key_in?: string[] | null;
    /** All values that are not contained in given list. */
    key_not_in?: string[] | null;
    /** All values less than the given value. */
    key_lt?: string | null;
    /** All values less than or equal the given value. */
    key_lte?: string | null;
    /** All values greater than the given value. */
    key_gt?: string | null;
    /** All values greater than or equal the given value. */
    key_gte?: string | null;
    /** All values containing the given string. */
    key_contains?: string | null;
    /** All values not containing the given string. */
    key_not_contains?: string | null;
    /** All values starting with the given string. */
    key_starts_with?: string | null;
    /** All values not starting with the given string. */
    key_not_starts_with?: string | null;
    /** All values ending with the given string. */
    key_ends_with?: string | null;
    /** All values not ending with the given string. */
    key_not_ends_with?: string | null;

    dateString?: string | null;
    /** All values that are not equal to given value. */
    dateString_not?: string | null;
    /** All values that are contained in given list. */
    dateString_in?: string[] | null;
    /** All values that are not contained in given list. */
    dateString_not_in?: string[] | null;
    /** All values less than the given value. */
    dateString_lt?: string | null;
    /** All values less than or equal the given value. */
    dateString_lte?: string | null;
    /** All values greater than the given value. */
    dateString_gt?: string | null;
    /** All values greater than or equal the given value. */
    dateString_gte?: string | null;
    /** All values containing the given string. */
    dateString_contains?: string | null;
    /** All values not containing the given string. */
    dateString_not_contains?: string | null;
    /** All values starting with the given string. */
    dateString_starts_with?: string | null;
    /** All values not starting with the given string. */
    dateString_not_starts_with?: string | null;
    /** All values ending with the given string. */
    dateString_ends_with?: string | null;
    /** All values not ending with the given string. */
    dateString_not_ends_with?: string | null;

    merchantVariableAmt?: number | null;
    /** All values that are not equal to given value. */
    merchantVariableAmt_not?: number | null;
    /** All values that are contained in given list. */
    merchantVariableAmt_in?: number[] | null;
    /** All values that are not contained in given list. */
    merchantVariableAmt_not_in?: number[] | null;
    /** All values less than the given value. */
    merchantVariableAmt_lt?: number | null;
    /** All values less than or equal the given value. */
    merchantVariableAmt_lte?: number | null;
    /** All values greater than the given value. */
    merchantVariableAmt_gt?: number | null;
    /** All values greater than or equal the given value. */
    merchantVariableAmt_gte?: number | null;

    merchantFixedAmt?: number | null;
    /** All values that are not equal to given value. */
    merchantFixedAmt_not?: number | null;
    /** All values that are contained in given list. */
    merchantFixedAmt_in?: number[] | null;
    /** All values that are not contained in given list. */
    merchantFixedAmt_not_in?: number[] | null;
    /** All values less than the given value. */
    merchantFixedAmt_lt?: number | null;
    /** All values less than or equal the given value. */
    merchantFixedAmt_lte?: number | null;
    /** All values greater than the given value. */
    merchantFixedAmt_gt?: number | null;
    /** All values greater than or equal the given value. */
    merchantFixedAmt_gte?: number | null;

    merchant?: MerchantWhereInput | null;
}

export interface MerchantMonthlyCommissionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantMonthlyCommissionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantMonthlyCommissionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantMonthlyCommissionWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    key?: string | null;
    /** All values that are not equal to given value. */
    key_not?: string | null;
    /** All values that are contained in given list. */
    key_in?: string[] | null;
    /** All values that are not contained in given list. */
    key_not_in?: string[] | null;
    /** All values less than the given value. */
    key_lt?: string | null;
    /** All values less than or equal the given value. */
    key_lte?: string | null;
    /** All values greater than the given value. */
    key_gt?: string | null;
    /** All values greater than or equal the given value. */
    key_gte?: string | null;
    /** All values containing the given string. */
    key_contains?: string | null;
    /** All values not containing the given string. */
    key_not_contains?: string | null;
    /** All values starting with the given string. */
    key_starts_with?: string | null;
    /** All values not starting with the given string. */
    key_not_starts_with?: string | null;
    /** All values ending with the given string. */
    key_ends_with?: string | null;
    /** All values not ending with the given string. */
    key_not_ends_with?: string | null;

    monthString?: string | null;
    /** All values that are not equal to given value. */
    monthString_not?: string | null;
    /** All values that are contained in given list. */
    monthString_in?: string[] | null;
    /** All values that are not contained in given list. */
    monthString_not_in?: string[] | null;
    /** All values less than the given value. */
    monthString_lt?: string | null;
    /** All values less than or equal the given value. */
    monthString_lte?: string | null;
    /** All values greater than the given value. */
    monthString_gt?: string | null;
    /** All values greater than or equal the given value. */
    monthString_gte?: string | null;
    /** All values containing the given string. */
    monthString_contains?: string | null;
    /** All values not containing the given string. */
    monthString_not_contains?: string | null;
    /** All values starting with the given string. */
    monthString_starts_with?: string | null;
    /** All values not starting with the given string. */
    monthString_not_starts_with?: string | null;
    /** All values ending with the given string. */
    monthString_ends_with?: string | null;
    /** All values not ending with the given string. */
    monthString_not_ends_with?: string | null;

    amount?: number | null;
    /** All values that are not equal to given value. */
    amount_not?: number | null;
    /** All values that are contained in given list. */
    amount_in?: number[] | null;
    /** All values that are not contained in given list. */
    amount_not_in?: number[] | null;
    /** All values less than the given value. */
    amount_lt?: number | null;
    /** All values less than or equal the given value. */
    amount_lte?: number | null;
    /** All values greater than the given value. */
    amount_gt?: number | null;
    /** All values greater than or equal the given value. */
    amount_gte?: number | null;

    merchant?: MerchantWhereInput | null;
}

export interface MerchantCommissionDepositWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantCommissionDepositWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantCommissionDepositWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantCommissionDepositWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    dateString?: string | null;
    /** All values that are not equal to given value. */
    dateString_not?: string | null;
    /** All values that are contained in given list. */
    dateString_in?: string[] | null;
    /** All values that are not contained in given list. */
    dateString_not_in?: string[] | null;
    /** All values less than the given value. */
    dateString_lt?: string | null;
    /** All values less than or equal the given value. */
    dateString_lte?: string | null;
    /** All values greater than the given value. */
    dateString_gt?: string | null;
    /** All values greater than or equal the given value. */
    dateString_gte?: string | null;
    /** All values containing the given string. */
    dateString_contains?: string | null;
    /** All values not containing the given string. */
    dateString_not_contains?: string | null;
    /** All values starting with the given string. */
    dateString_starts_with?: string | null;
    /** All values not starting with the given string. */
    dateString_not_starts_with?: string | null;
    /** All values ending with the given string. */
    dateString_ends_with?: string | null;
    /** All values not ending with the given string. */
    dateString_not_ends_with?: string | null;

    amount?: number | null;
    /** All values that are not equal to given value. */
    amount_not?: number | null;
    /** All values that are contained in given list. */
    amount_in?: number[] | null;
    /** All values that are not contained in given list. */
    amount_not_in?: number[] | null;
    /** All values less than the given value. */
    amount_lt?: number | null;
    /** All values less than or equal the given value. */
    amount_lte?: number | null;
    /** All values greater than the given value. */
    amount_gt?: number | null;
    /** All values greater than or equal the given value. */
    amount_gte?: number | null;

    merchant?: MerchantWhereInput | null;
}

export interface MerchantLeadWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantLeadWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantLeadWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantLeadWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    createdAt?: DateTime | null;
    /** All values that are not equal to given value. */
    createdAt_not?: DateTime | null;
    /** All values that are contained in given list. */
    createdAt_in?: DateTime[] | null;
    /** All values that are not contained in given list. */
    createdAt_not_in?: DateTime[] | null;
    /** All values less than the given value. */
    createdAt_lt?: DateTime | null;
    /** All values less than or equal the given value. */
    createdAt_lte?: DateTime | null;
    /** All values greater than the given value. */
    createdAt_gt?: DateTime | null;
    /** All values greater than or equal the given value. */
    createdAt_gte?: DateTime | null;

    firstName?: string | null;
    /** All values that are not equal to given value. */
    firstName_not?: string | null;
    /** All values that are contained in given list. */
    firstName_in?: string[] | null;
    /** All values that are not contained in given list. */
    firstName_not_in?: string[] | null;
    /** All values less than the given value. */
    firstName_lt?: string | null;
    /** All values less than or equal the given value. */
    firstName_lte?: string | null;
    /** All values greater than the given value. */
    firstName_gt?: string | null;
    /** All values greater than or equal the given value. */
    firstName_gte?: string | null;
    /** All values containing the given string. */
    firstName_contains?: string | null;
    /** All values not containing the given string. */
    firstName_not_contains?: string | null;
    /** All values starting with the given string. */
    firstName_starts_with?: string | null;
    /** All values not starting with the given string. */
    firstName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    firstName_ends_with?: string | null;
    /** All values not ending with the given string. */
    firstName_not_ends_with?: string | null;

    lastName?: string | null;
    /** All values that are not equal to given value. */
    lastName_not?: string | null;
    /** All values that are contained in given list. */
    lastName_in?: string[] | null;
    /** All values that are not contained in given list. */
    lastName_not_in?: string[] | null;
    /** All values less than the given value. */
    lastName_lt?: string | null;
    /** All values less than or equal the given value. */
    lastName_lte?: string | null;
    /** All values greater than the given value. */
    lastName_gt?: string | null;
    /** All values greater than or equal the given value. */
    lastName_gte?: string | null;
    /** All values containing the given string. */
    lastName_contains?: string | null;
    /** All values not containing the given string. */
    lastName_not_contains?: string | null;
    /** All values starting with the given string. */
    lastName_starts_with?: string | null;
    /** All values not starting with the given string. */
    lastName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    lastName_ends_with?: string | null;
    /** All values not ending with the given string. */
    lastName_not_ends_with?: string | null;

    businessName?: string | null;
    /** All values that are not equal to given value. */
    businessName_not?: string | null;
    /** All values that are contained in given list. */
    businessName_in?: string[] | null;
    /** All values that are not contained in given list. */
    businessName_not_in?: string[] | null;
    /** All values less than the given value. */
    businessName_lt?: string | null;
    /** All values less than or equal the given value. */
    businessName_lte?: string | null;
    /** All values greater than the given value. */
    businessName_gt?: string | null;
    /** All values greater than or equal the given value. */
    businessName_gte?: string | null;
    /** All values containing the given string. */
    businessName_contains?: string | null;
    /** All values not containing the given string. */
    businessName_not_contains?: string | null;
    /** All values starting with the given string. */
    businessName_starts_with?: string | null;
    /** All values not starting with the given string. */
    businessName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    businessName_ends_with?: string | null;
    /** All values not ending with the given string. */
    businessName_not_ends_with?: string | null;

    relationship?: string | null;
    /** All values that are not equal to given value. */
    relationship_not?: string | null;
    /** All values that are contained in given list. */
    relationship_in?: string[] | null;
    /** All values that are not contained in given list. */
    relationship_not_in?: string[] | null;
    /** All values less than the given value. */
    relationship_lt?: string | null;
    /** All values less than or equal the given value. */
    relationship_lte?: string | null;
    /** All values greater than the given value. */
    relationship_gt?: string | null;
    /** All values greater than or equal the given value. */
    relationship_gte?: string | null;
    /** All values containing the given string. */
    relationship_contains?: string | null;
    /** All values not containing the given string. */
    relationship_not_contains?: string | null;
    /** All values starting with the given string. */
    relationship_starts_with?: string | null;
    /** All values not starting with the given string. */
    relationship_not_starts_with?: string | null;
    /** All values ending with the given string. */
    relationship_ends_with?: string | null;
    /** All values not ending with the given string. */
    relationship_not_ends_with?: string | null;

    infoVideo?: string | null;
    /** All values that are not equal to given value. */
    infoVideo_not?: string | null;
    /** All values that are contained in given list. */
    infoVideo_in?: string[] | null;
    /** All values that are not contained in given list. */
    infoVideo_not_in?: string[] | null;
    /** All values less than the given value. */
    infoVideo_lt?: string | null;
    /** All values less than or equal the given value. */
    infoVideo_lte?: string | null;
    /** All values greater than the given value. */
    infoVideo_gt?: string | null;
    /** All values greater than or equal the given value. */
    infoVideo_gte?: string | null;
    /** All values containing the given string. */
    infoVideo_contains?: string | null;
    /** All values not containing the given string. */
    infoVideo_not_contains?: string | null;
    /** All values starting with the given string. */
    infoVideo_starts_with?: string | null;
    /** All values not starting with the given string. */
    infoVideo_not_starts_with?: string | null;
    /** All values ending with the given string. */
    infoVideo_ends_with?: string | null;
    /** All values not ending with the given string. */
    infoVideo_not_ends_with?: string | null;

    website?: string | null;
    /** All values that are not equal to given value. */
    website_not?: string | null;
    /** All values that are contained in given list. */
    website_in?: string[] | null;
    /** All values that are not contained in given list. */
    website_not_in?: string[] | null;
    /** All values less than the given value. */
    website_lt?: string | null;
    /** All values less than or equal the given value. */
    website_lte?: string | null;
    /** All values greater than the given value. */
    website_gt?: string | null;
    /** All values greater than or equal the given value. */
    website_gte?: string | null;
    /** All values containing the given string. */
    website_contains?: string | null;
    /** All values not containing the given string. */
    website_not_contains?: string | null;
    /** All values starting with the given string. */
    website_starts_with?: string | null;
    /** All values not starting with the given string. */
    website_not_starts_with?: string | null;
    /** All values ending with the given string. */
    website_ends_with?: string | null;
    /** All values not ending with the given string. */
    website_not_ends_with?: string | null;

    email?: string | null;
    /** All values that are not equal to given value. */
    email_not?: string | null;
    /** All values that are contained in given list. */
    email_in?: string[] | null;
    /** All values that are not contained in given list. */
    email_not_in?: string[] | null;
    /** All values less than the given value. */
    email_lt?: string | null;
    /** All values less than or equal the given value. */
    email_lte?: string | null;
    /** All values greater than the given value. */
    email_gt?: string | null;
    /** All values greater than or equal the given value. */
    email_gte?: string | null;
    /** All values containing the given string. */
    email_contains?: string | null;
    /** All values not containing the given string. */
    email_not_contains?: string | null;
    /** All values starting with the given string. */
    email_starts_with?: string | null;
    /** All values not starting with the given string. */
    email_not_starts_with?: string | null;
    /** All values ending with the given string. */
    email_ends_with?: string | null;
    /** All values not ending with the given string. */
    email_not_ends_with?: string | null;

    slug?: string | null;
    /** All values that are not equal to given value. */
    slug_not?: string | null;
    /** All values that are contained in given list. */
    slug_in?: string[] | null;
    /** All values that are not contained in given list. */
    slug_not_in?: string[] | null;
    /** All values less than the given value. */
    slug_lt?: string | null;
    /** All values less than or equal the given value. */
    slug_lte?: string | null;
    /** All values greater than the given value. */
    slug_gt?: string | null;
    /** All values greater than or equal the given value. */
    slug_gte?: string | null;
    /** All values containing the given string. */
    slug_contains?: string | null;
    /** All values not containing the given string. */
    slug_not_contains?: string | null;
    /** All values starting with the given string. */
    slug_starts_with?: string | null;
    /** All values not starting with the given string. */
    slug_not_starts_with?: string | null;
    /** All values ending with the given string. */
    slug_ends_with?: string | null;
    /** All values not ending with the given string. */
    slug_not_ends_with?: string | null;

    status?: MerchantLeadStatus | null;
    /** All values that are not equal to given value. */
    status_not?: MerchantLeadStatus | null;
    /** All values that are contained in given list. */
    status_in?: MerchantLeadStatus[] | null;
    /** All values that are not contained in given list. */
    status_not_in?: MerchantLeadStatus[] | null;

    notes?: string | null;
    /** All values that are not equal to given value. */
    notes_not?: string | null;
    /** All values that are contained in given list. */
    notes_in?: string[] | null;
    /** All values that are not contained in given list. */
    notes_not_in?: string[] | null;
    /** All values less than the given value. */
    notes_lt?: string | null;
    /** All values less than or equal the given value. */
    notes_lte?: string | null;
    /** All values greater than the given value. */
    notes_gt?: string | null;
    /** All values greater than or equal the given value. */
    notes_gte?: string | null;
    /** All values containing the given string. */
    notes_contains?: string | null;
    /** All values not containing the given string. */
    notes_not_contains?: string | null;
    /** All values starting with the given string. */
    notes_starts_with?: string | null;
    /** All values not starting with the given string. */
    notes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    notes_ends_with?: string | null;
    /** All values not ending with the given string. */
    notes_not_ends_with?: string | null;

    leadScore?: number | null;
    /** All values that are not equal to given value. */
    leadScore_not?: number | null;
    /** All values that are contained in given list. */
    leadScore_in?: number[] | null;
    /** All values that are not contained in given list. */
    leadScore_not_in?: number[] | null;
    /** All values less than the given value. */
    leadScore_lt?: number | null;
    /** All values less than or equal the given value. */
    leadScore_lte?: number | null;
    /** All values greater than the given value. */
    leadScore_gt?: number | null;
    /** All values greater than or equal the given value. */
    leadScore_gte?: number | null;

    merchantNumber?: string | null;
    /** All values that are not equal to given value. */
    merchantNumber_not?: string | null;
    /** All values that are contained in given list. */
    merchantNumber_in?: string[] | null;
    /** All values that are not contained in given list. */
    merchantNumber_not_in?: string[] | null;
    /** All values less than the given value. */
    merchantNumber_lt?: string | null;
    /** All values less than or equal the given value. */
    merchantNumber_lte?: string | null;
    /** All values greater than the given value. */
    merchantNumber_gt?: string | null;
    /** All values greater than or equal the given value. */
    merchantNumber_gte?: string | null;
    /** All values containing the given string. */
    merchantNumber_contains?: string | null;
    /** All values not containing the given string. */
    merchantNumber_not_contains?: string | null;
    /** All values starting with the given string. */
    merchantNumber_starts_with?: string | null;
    /** All values not starting with the given string. */
    merchantNumber_not_starts_with?: string | null;
    /** All values ending with the given string. */
    merchantNumber_ends_with?: string | null;
    /** All values not ending with the given string. */
    merchantNumber_not_ends_with?: string | null;

    bid?: string | null;
    /** All values that are not equal to given value. */
    bid_not?: string | null;
    /** All values that are contained in given list. */
    bid_in?: string[] | null;
    /** All values that are not contained in given list. */
    bid_not_in?: string[] | null;
    /** All values less than the given value. */
    bid_lt?: string | null;
    /** All values less than or equal the given value. */
    bid_lte?: string | null;
    /** All values greater than the given value. */
    bid_gt?: string | null;
    /** All values greater than or equal the given value. */
    bid_gte?: string | null;
    /** All values containing the given string. */
    bid_contains?: string | null;
    /** All values not containing the given string. */
    bid_not_contains?: string | null;
    /** All values starting with the given string. */
    bid_starts_with?: string | null;
    /** All values not starting with the given string. */
    bid_not_starts_with?: string | null;
    /** All values ending with the given string. */
    bid_ends_with?: string | null;
    /** All values not ending with the given string. */
    bid_not_ends_with?: string | null;

    applicationStatus?: string | null;
    /** All values that are not equal to given value. */
    applicationStatus_not?: string | null;
    /** All values that are contained in given list. */
    applicationStatus_in?: string[] | null;
    /** All values that are not contained in given list. */
    applicationStatus_not_in?: string[] | null;
    /** All values less than the given value. */
    applicationStatus_lt?: string | null;
    /** All values less than or equal the given value. */
    applicationStatus_lte?: string | null;
    /** All values greater than the given value. */
    applicationStatus_gt?: string | null;
    /** All values greater than or equal the given value. */
    applicationStatus_gte?: string | null;
    /** All values containing the given string. */
    applicationStatus_contains?: string | null;
    /** All values not containing the given string. */
    applicationStatus_not_contains?: string | null;
    /** All values starting with the given string. */
    applicationStatus_starts_with?: string | null;
    /** All values not starting with the given string. */
    applicationStatus_not_starts_with?: string | null;
    /** All values ending with the given string. */
    applicationStatus_ends_with?: string | null;
    /** All values not ending with the given string. */
    applicationStatus_not_ends_with?: string | null;

    visaBP?: number | null;
    /** All values that are not equal to given value. */
    visaBP_not?: number | null;
    /** All values that are contained in given list. */
    visaBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    visaBP_not_in?: number[] | null;
    /** All values less than the given value. */
    visaBP_lt?: number | null;
    /** All values less than or equal the given value. */
    visaBP_lte?: number | null;
    /** All values greater than the given value. */
    visaBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    visaBP_gte?: number | null;

    visaCents?: number | null;
    /** All values that are not equal to given value. */
    visaCents_not?: number | null;
    /** All values that are contained in given list. */
    visaCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    visaCents_not_in?: number[] | null;
    /** All values less than the given value. */
    visaCents_lt?: number | null;
    /** All values less than or equal the given value. */
    visaCents_lte?: number | null;
    /** All values greater than the given value. */
    visaCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    visaCents_gte?: number | null;

    mastercardBP?: number | null;
    /** All values that are not equal to given value. */
    mastercardBP_not?: number | null;
    /** All values that are contained in given list. */
    mastercardBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    mastercardBP_not_in?: number[] | null;
    /** All values less than the given value. */
    mastercardBP_lt?: number | null;
    /** All values less than or equal the given value. */
    mastercardBP_lte?: number | null;
    /** All values greater than the given value. */
    mastercardBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    mastercardBP_gte?: number | null;

    mastercardCents?: number | null;
    /** All values that are not equal to given value. */
    mastercardCents_not?: number | null;
    /** All values that are contained in given list. */
    mastercardCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    mastercardCents_not_in?: number[] | null;
    /** All values less than the given value. */
    mastercardCents_lt?: number | null;
    /** All values less than or equal the given value. */
    mastercardCents_lte?: number | null;
    /** All values greater than the given value. */
    mastercardCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    mastercardCents_gte?: number | null;

    discoverBP?: number | null;
    /** All values that are not equal to given value. */
    discoverBP_not?: number | null;
    /** All values that are contained in given list. */
    discoverBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    discoverBP_not_in?: number[] | null;
    /** All values less than the given value. */
    discoverBP_lt?: number | null;
    /** All values less than or equal the given value. */
    discoverBP_lte?: number | null;
    /** All values greater than the given value. */
    discoverBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    discoverBP_gte?: number | null;

    discoverCents?: number | null;
    /** All values that are not equal to given value. */
    discoverCents_not?: number | null;
    /** All values that are contained in given list. */
    discoverCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    discoverCents_not_in?: number[] | null;
    /** All values less than the given value. */
    discoverCents_lt?: number | null;
    /** All values less than or equal the given value. */
    discoverCents_lte?: number | null;
    /** All values greater than the given value. */
    discoverCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    discoverCents_gte?: number | null;

    amexBP?: number | null;
    /** All values that are not equal to given value. */
    amexBP_not?: number | null;
    /** All values that are contained in given list. */
    amexBP_in?: number[] | null;
    /** All values that are not contained in given list. */
    amexBP_not_in?: number[] | null;
    /** All values less than the given value. */
    amexBP_lt?: number | null;
    /** All values less than or equal the given value. */
    amexBP_lte?: number | null;
    /** All values greater than the given value. */
    amexBP_gt?: number | null;
    /** All values greater than or equal the given value. */
    amexBP_gte?: number | null;

    amexCents?: number | null;
    /** All values that are not equal to given value. */
    amexCents_not?: number | null;
    /** All values that are contained in given list. */
    amexCents_in?: number[] | null;
    /** All values that are not contained in given list. */
    amexCents_not_in?: number[] | null;
    /** All values less than the given value. */
    amexCents_lt?: number | null;
    /** All values less than or equal the given value. */
    amexCents_lte?: number | null;
    /** All values greater than the given value. */
    amexCents_gt?: number | null;
    /** All values greater than or equal the given value. */
    amexCents_gte?: number | null;

    monthlyFees?: number | null;
    /** All values that are not equal to given value. */
    monthlyFees_not?: number | null;
    /** All values that are contained in given list. */
    monthlyFees_in?: number[] | null;
    /** All values that are not contained in given list. */
    monthlyFees_not_in?: number[] | null;
    /** All values less than the given value. */
    monthlyFees_lt?: number | null;
    /** All values less than or equal the given value. */
    monthlyFees_lte?: number | null;
    /** All values greater than the given value. */
    monthlyFees_gt?: number | null;
    /** All values greater than or equal the given value. */
    monthlyFees_gte?: number | null;

    monthlyFixedExpenses?: number | null;
    /** All values that are not equal to given value. */
    monthlyFixedExpenses_not?: number | null;
    /** All values that are contained in given list. */
    monthlyFixedExpenses_in?: number[] | null;
    /** All values that are not contained in given list. */
    monthlyFixedExpenses_not_in?: number[] | null;
    /** All values less than the given value. */
    monthlyFixedExpenses_lt?: number | null;
    /** All values less than or equal the given value. */
    monthlyFixedExpenses_lte?: number | null;
    /** All values greater than the given value. */
    monthlyFixedExpenses_gt?: number | null;
    /** All values greater than or equal the given value. */
    monthlyFixedExpenses_gte?: number | null;

    expressMerchantFunding?: boolean | null;
    /** All values that are not equal to given value. */
    expressMerchantFunding_not?: boolean | null;

    search?: string | null;
    /** All values that are not equal to given value. */
    search_not?: string | null;
    /** All values that are contained in given list. */
    search_in?: string[] | null;
    /** All values that are not contained in given list. */
    search_not_in?: string[] | null;
    /** All values less than the given value. */
    search_lt?: string | null;
    /** All values less than or equal the given value. */
    search_lte?: string | null;
    /** All values greater than the given value. */
    search_gt?: string | null;
    /** All values greater than or equal the given value. */
    search_gte?: string | null;
    /** All values containing the given string. */
    search_contains?: string | null;
    /** All values not containing the given string. */
    search_not_contains?: string | null;
    /** All values starting with the given string. */
    search_starts_with?: string | null;
    /** All values not starting with the given string. */
    search_not_starts_with?: string | null;
    /** All values ending with the given string. */
    search_ends_with?: string | null;
    /** All values not ending with the given string. */
    search_not_ends_with?: string | null;

    equipmentOnboardingNotes?: string | null;
    /** All values that are not equal to given value. */
    equipmentOnboardingNotes_not?: string | null;
    /** All values that are contained in given list. */
    equipmentOnboardingNotes_in?: string[] | null;
    /** All values that are not contained in given list. */
    equipmentOnboardingNotes_not_in?: string[] | null;
    /** All values less than the given value. */
    equipmentOnboardingNotes_lt?: string | null;
    /** All values less than or equal the given value. */
    equipmentOnboardingNotes_lte?: string | null;
    /** All values greater than the given value. */
    equipmentOnboardingNotes_gt?: string | null;
    /** All values greater than or equal the given value. */
    equipmentOnboardingNotes_gte?: string | null;
    /** All values containing the given string. */
    equipmentOnboardingNotes_contains?: string | null;
    /** All values not containing the given string. */
    equipmentOnboardingNotes_not_contains?: string | null;
    /** All values starting with the given string. */
    equipmentOnboardingNotes_starts_with?: string | null;
    /** All values not starting with the given string. */
    equipmentOnboardingNotes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    equipmentOnboardingNotes_ends_with?: string | null;
    /** All values not ending with the given string. */
    equipmentOnboardingNotes_not_ends_with?: string | null;

    pricingCommissionEstimateNotes?: string | null;
    /** All values that are not equal to given value. */
    pricingCommissionEstimateNotes_not?: string | null;
    /** All values that are contained in given list. */
    pricingCommissionEstimateNotes_in?: string[] | null;
    /** All values that are not contained in given list. */
    pricingCommissionEstimateNotes_not_in?: string[] | null;
    /** All values less than the given value. */
    pricingCommissionEstimateNotes_lt?: string | null;
    /** All values less than or equal the given value. */
    pricingCommissionEstimateNotes_lte?: string | null;
    /** All values greater than the given value. */
    pricingCommissionEstimateNotes_gt?: string | null;
    /** All values greater than or equal the given value. */
    pricingCommissionEstimateNotes_gte?: string | null;
    /** All values containing the given string. */
    pricingCommissionEstimateNotes_contains?: string | null;
    /** All values not containing the given string. */
    pricingCommissionEstimateNotes_not_contains?: string | null;
    /** All values starting with the given string. */
    pricingCommissionEstimateNotes_starts_with?: string | null;
    /** All values not starting with the given string. */
    pricingCommissionEstimateNotes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    pricingCommissionEstimateNotes_ends_with?: string | null;
    /** All values not ending with the given string. */
    pricingCommissionEstimateNotes_not_ends_with?: string | null;

    howTheyTakePaymentsNotes?: string | null;
    /** All values that are not equal to given value. */
    howTheyTakePaymentsNotes_not?: string | null;
    /** All values that are contained in given list. */
    howTheyTakePaymentsNotes_in?: string[] | null;
    /** All values that are not contained in given list. */
    howTheyTakePaymentsNotes_not_in?: string[] | null;
    /** All values less than the given value. */
    howTheyTakePaymentsNotes_lt?: string | null;
    /** All values less than or equal the given value. */
    howTheyTakePaymentsNotes_lte?: string | null;
    /** All values greater than the given value. */
    howTheyTakePaymentsNotes_gt?: string | null;
    /** All values greater than or equal the given value. */
    howTheyTakePaymentsNotes_gte?: string | null;
    /** All values containing the given string. */
    howTheyTakePaymentsNotes_contains?: string | null;
    /** All values not containing the given string. */
    howTheyTakePaymentsNotes_not_contains?: string | null;
    /** All values starting with the given string. */
    howTheyTakePaymentsNotes_starts_with?: string | null;
    /** All values not starting with the given string. */
    howTheyTakePaymentsNotes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    howTheyTakePaymentsNotes_ends_with?: string | null;
    /** All values not ending with the given string. */
    howTheyTakePaymentsNotes_not_ends_with?: string | null;

    pendingQuestionsNotes?: string | null;
    /** All values that are not equal to given value. */
    pendingQuestionsNotes_not?: string | null;
    /** All values that are contained in given list. */
    pendingQuestionsNotes_in?: string[] | null;
    /** All values that are not contained in given list. */
    pendingQuestionsNotes_not_in?: string[] | null;
    /** All values less than the given value. */
    pendingQuestionsNotes_lt?: string | null;
    /** All values less than or equal the given value. */
    pendingQuestionsNotes_lte?: string | null;
    /** All values greater than the given value. */
    pendingQuestionsNotes_gt?: string | null;
    /** All values greater than or equal the given value. */
    pendingQuestionsNotes_gte?: string | null;
    /** All values containing the given string. */
    pendingQuestionsNotes_contains?: string | null;
    /** All values not containing the given string. */
    pendingQuestionsNotes_not_contains?: string | null;
    /** All values starting with the given string. */
    pendingQuestionsNotes_starts_with?: string | null;
    /** All values not starting with the given string. */
    pendingQuestionsNotes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    pendingQuestionsNotes_ends_with?: string | null;
    /** All values not ending with the given string. */
    pendingQuestionsNotes_not_ends_with?: string | null;

    additionalContactInfoNotes?: string | null;
    /** All values that are not equal to given value. */
    additionalContactInfoNotes_not?: string | null;
    /** All values that are contained in given list. */
    additionalContactInfoNotes_in?: string[] | null;
    /** All values that are not contained in given list. */
    additionalContactInfoNotes_not_in?: string[] | null;
    /** All values less than the given value. */
    additionalContactInfoNotes_lt?: string | null;
    /** All values less than or equal the given value. */
    additionalContactInfoNotes_lte?: string | null;
    /** All values greater than the given value. */
    additionalContactInfoNotes_gt?: string | null;
    /** All values greater than or equal the given value. */
    additionalContactInfoNotes_gte?: string | null;
    /** All values containing the given string. */
    additionalContactInfoNotes_contains?: string | null;
    /** All values not containing the given string. */
    additionalContactInfoNotes_not_contains?: string | null;
    /** All values starting with the given string. */
    additionalContactInfoNotes_starts_with?: string | null;
    /** All values not starting with the given string. */
    additionalContactInfoNotes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    additionalContactInfoNotes_ends_with?: string | null;
    /** All values not ending with the given string. */
    additionalContactInfoNotes_not_ends_with?: string | null;

    frontingCommissionAmount?: string | null;
    /** All values that are not equal to given value. */
    frontingCommissionAmount_not?: string | null;
    /** All values that are contained in given list. */
    frontingCommissionAmount_in?: string[] | null;
    /** All values that are not contained in given list. */
    frontingCommissionAmount_not_in?: string[] | null;
    /** All values less than the given value. */
    frontingCommissionAmount_lt?: string | null;
    /** All values less than or equal the given value. */
    frontingCommissionAmount_lte?: string | null;
    /** All values greater than the given value. */
    frontingCommissionAmount_gt?: string | null;
    /** All values greater than or equal the given value. */
    frontingCommissionAmount_gte?: string | null;
    /** All values containing the given string. */
    frontingCommissionAmount_contains?: string | null;
    /** All values not containing the given string. */
    frontingCommissionAmount_not_contains?: string | null;
    /** All values starting with the given string. */
    frontingCommissionAmount_starts_with?: string | null;
    /** All values not starting with the given string. */
    frontingCommissionAmount_not_starts_with?: string | null;
    /** All values ending with the given string. */
    frontingCommissionAmount_ends_with?: string | null;
    /** All values not ending with the given string. */
    frontingCommissionAmount_not_ends_with?: string | null;

    enroller?: UserWhereInput | null;

    events_every?: EventWhereInput | null;

    events_some?: EventWhereInput | null;

    events_none?: EventWhereInput | null;

    application?: MerchantApplicationWhereInput | null;

    followup?: MerchantLeadFollowupWhereInput | null;

    owner?: UserWhereInput | null;
}

export interface EventWhereInput {
    /** Logical AND on all given filters. */
    AND?: EventWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: EventWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: EventWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    type?: string | null;
    /** All values that are not equal to given value. */
    type_not?: string | null;
    /** All values that are contained in given list. */
    type_in?: string[] | null;
    /** All values that are not contained in given list. */
    type_not_in?: string[] | null;
    /** All values less than the given value. */
    type_lt?: string | null;
    /** All values less than or equal the given value. */
    type_lte?: string | null;
    /** All values greater than the given value. */
    type_gt?: string | null;
    /** All values greater than or equal the given value. */
    type_gte?: string | null;
    /** All values containing the given string. */
    type_contains?: string | null;
    /** All values not containing the given string. */
    type_not_contains?: string | null;
    /** All values starting with the given string. */
    type_starts_with?: string | null;
    /** All values not starting with the given string. */
    type_not_starts_with?: string | null;
    /** All values ending with the given string. */
    type_ends_with?: string | null;
    /** All values not ending with the given string. */
    type_not_ends_with?: string | null;

    timestamp?: DateTime | null;
    /** All values that are not equal to given value. */
    timestamp_not?: DateTime | null;
    /** All values that are contained in given list. */
    timestamp_in?: DateTime[] | null;
    /** All values that are not contained in given list. */
    timestamp_not_in?: DateTime[] | null;
    /** All values less than the given value. */
    timestamp_lt?: DateTime | null;
    /** All values less than or equal the given value. */
    timestamp_lte?: DateTime | null;
    /** All values greater than the given value. */
    timestamp_gt?: DateTime | null;
    /** All values greater than or equal the given value. */
    timestamp_gte?: DateTime | null;
}

export interface MerchantApplicationWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantApplicationWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantApplicationWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantApplicationWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    lastUpdated?: DateTime | null;
    /** All values that are not equal to given value. */
    lastUpdated_not?: DateTime | null;
    /** All values that are contained in given list. */
    lastUpdated_in?: DateTime[] | null;
    /** All values that are not contained in given list. */
    lastUpdated_not_in?: DateTime[] | null;
    /** All values less than the given value. */
    lastUpdated_lt?: DateTime | null;
    /** All values less than or equal the given value. */
    lastUpdated_lte?: DateTime | null;
    /** All values greater than the given value. */
    lastUpdated_gt?: DateTime | null;
    /** All values greater than or equal the given value. */
    lastUpdated_gte?: DateTime | null;

    submitTimestamp?: DateTime | null;
    /** All values that are not equal to given value. */
    submitTimestamp_not?: DateTime | null;
    /** All values that are contained in given list. */
    submitTimestamp_in?: DateTime[] | null;
    /** All values that are not contained in given list. */
    submitTimestamp_not_in?: DateTime[] | null;
    /** All values less than the given value. */
    submitTimestamp_lt?: DateTime | null;
    /** All values less than or equal the given value. */
    submitTimestamp_lte?: DateTime | null;
    /** All values greater than the given value. */
    submitTimestamp_gt?: DateTime | null;
    /** All values greater than or equal the given value. */
    submitTimestamp_gte?: DateTime | null;

    dba?: string | null;
    /** All values that are not equal to given value. */
    dba_not?: string | null;
    /** All values that are contained in given list. */
    dba_in?: string[] | null;
    /** All values that are not contained in given list. */
    dba_not_in?: string[] | null;
    /** All values less than the given value. */
    dba_lt?: string | null;
    /** All values less than or equal the given value. */
    dba_lte?: string | null;
    /** All values greater than the given value. */
    dba_gt?: string | null;
    /** All values greater than or equal the given value. */
    dba_gte?: string | null;
    /** All values containing the given string. */
    dba_contains?: string | null;
    /** All values not containing the given string. */
    dba_not_contains?: string | null;
    /** All values starting with the given string. */
    dba_starts_with?: string | null;
    /** All values not starting with the given string. */
    dba_not_starts_with?: string | null;
    /** All values ending with the given string. */
    dba_ends_with?: string | null;
    /** All values not ending with the given string. */
    dba_not_ends_with?: string | null;

    legal_name?: string | null;
    /** All values that are not equal to given value. */
    legal_name_not?: string | null;
    /** All values that are contained in given list. */
    legal_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    legal_name_not_in?: string[] | null;
    /** All values less than the given value. */
    legal_name_lt?: string | null;
    /** All values less than or equal the given value. */
    legal_name_lte?: string | null;
    /** All values greater than the given value. */
    legal_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    legal_name_gte?: string | null;
    /** All values containing the given string. */
    legal_name_contains?: string | null;
    /** All values not containing the given string. */
    legal_name_not_contains?: string | null;
    /** All values starting with the given string. */
    legal_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    legal_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    legal_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    legal_name_not_ends_with?: string | null;

    contact_name?: string | null;
    /** All values that are not equal to given value. */
    contact_name_not?: string | null;
    /** All values that are contained in given list. */
    contact_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    contact_name_not_in?: string[] | null;
    /** All values less than the given value. */
    contact_name_lt?: string | null;
    /** All values less than or equal the given value. */
    contact_name_lte?: string | null;
    /** All values greater than the given value. */
    contact_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    contact_name_gte?: string | null;
    /** All values containing the given string. */
    contact_name_contains?: string | null;
    /** All values not containing the given string. */
    contact_name_not_contains?: string | null;
    /** All values starting with the given string. */
    contact_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    contact_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    contact_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    contact_name_not_ends_with?: string | null;

    business_street?: string | null;
    /** All values that are not equal to given value. */
    business_street_not?: string | null;
    /** All values that are contained in given list. */
    business_street_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_street_not_in?: string[] | null;
    /** All values less than the given value. */
    business_street_lt?: string | null;
    /** All values less than or equal the given value. */
    business_street_lte?: string | null;
    /** All values greater than the given value. */
    business_street_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_street_gte?: string | null;
    /** All values containing the given string. */
    business_street_contains?: string | null;
    /** All values not containing the given string. */
    business_street_not_contains?: string | null;
    /** All values starting with the given string. */
    business_street_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_street_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_street_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_street_not_ends_with?: string | null;

    business_city?: string | null;
    /** All values that are not equal to given value. */
    business_city_not?: string | null;
    /** All values that are contained in given list. */
    business_city_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_city_not_in?: string[] | null;
    /** All values less than the given value. */
    business_city_lt?: string | null;
    /** All values less than or equal the given value. */
    business_city_lte?: string | null;
    /** All values greater than the given value. */
    business_city_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_city_gte?: string | null;
    /** All values containing the given string. */
    business_city_contains?: string | null;
    /** All values not containing the given string. */
    business_city_not_contains?: string | null;
    /** All values starting with the given string. */
    business_city_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_city_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_city_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_city_not_ends_with?: string | null;

    business_state?: string | null;
    /** All values that are not equal to given value. */
    business_state_not?: string | null;
    /** All values that are contained in given list. */
    business_state_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_state_not_in?: string[] | null;
    /** All values less than the given value. */
    business_state_lt?: string | null;
    /** All values less than or equal the given value. */
    business_state_lte?: string | null;
    /** All values greater than the given value. */
    business_state_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_state_gte?: string | null;
    /** All values containing the given string. */
    business_state_contains?: string | null;
    /** All values not containing the given string. */
    business_state_not_contains?: string | null;
    /** All values starting with the given string. */
    business_state_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_state_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_state_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_state_not_ends_with?: string | null;

    business_zip?: string | null;
    /** All values that are not equal to given value. */
    business_zip_not?: string | null;
    /** All values that are contained in given list. */
    business_zip_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_zip_not_in?: string[] | null;
    /** All values less than the given value. */
    business_zip_lt?: string | null;
    /** All values less than or equal the given value. */
    business_zip_lte?: string | null;
    /** All values greater than the given value. */
    business_zip_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_zip_gte?: string | null;
    /** All values containing the given string. */
    business_zip_contains?: string | null;
    /** All values not containing the given string. */
    business_zip_not_contains?: string | null;
    /** All values starting with the given string. */
    business_zip_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_zip_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_zip_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_zip_not_ends_with?: string | null;

    business_phone?: string | null;
    /** All values that are not equal to given value. */
    business_phone_not?: string | null;
    /** All values that are contained in given list. */
    business_phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_phone_not_in?: string[] | null;
    /** All values less than the given value. */
    business_phone_lt?: string | null;
    /** All values less than or equal the given value. */
    business_phone_lte?: string | null;
    /** All values greater than the given value. */
    business_phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_phone_gte?: string | null;
    /** All values containing the given string. */
    business_phone_contains?: string | null;
    /** All values not containing the given string. */
    business_phone_not_contains?: string | null;
    /** All values starting with the given string. */
    business_phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_phone_not_ends_with?: string | null;

    email?: string | null;
    /** All values that are not equal to given value. */
    email_not?: string | null;
    /** All values that are contained in given list. */
    email_in?: string[] | null;
    /** All values that are not contained in given list. */
    email_not_in?: string[] | null;
    /** All values less than the given value. */
    email_lt?: string | null;
    /** All values less than or equal the given value. */
    email_lte?: string | null;
    /** All values greater than the given value. */
    email_gt?: string | null;
    /** All values greater than or equal the given value. */
    email_gte?: string | null;
    /** All values containing the given string. */
    email_contains?: string | null;
    /** All values not containing the given string. */
    email_not_contains?: string | null;
    /** All values starting with the given string. */
    email_starts_with?: string | null;
    /** All values not starting with the given string. */
    email_not_starts_with?: string | null;
    /** All values ending with the given string. */
    email_ends_with?: string | null;
    /** All values not ending with the given string. */
    email_not_ends_with?: string | null;

    fax?: string | null;
    /** All values that are not equal to given value. */
    fax_not?: string | null;
    /** All values that are contained in given list. */
    fax_in?: string[] | null;
    /** All values that are not contained in given list. */
    fax_not_in?: string[] | null;
    /** All values less than the given value. */
    fax_lt?: string | null;
    /** All values less than or equal the given value. */
    fax_lte?: string | null;
    /** All values greater than the given value. */
    fax_gt?: string | null;
    /** All values greater than or equal the given value. */
    fax_gte?: string | null;
    /** All values containing the given string. */
    fax_contains?: string | null;
    /** All values not containing the given string. */
    fax_not_contains?: string | null;
    /** All values starting with the given string. */
    fax_starts_with?: string | null;
    /** All values not starting with the given string. */
    fax_not_starts_with?: string | null;
    /** All values ending with the given string. */
    fax_ends_with?: string | null;
    /** All values not ending with the given string. */
    fax_not_ends_with?: string | null;

    website?: string | null;
    /** All values that are not equal to given value. */
    website_not?: string | null;
    /** All values that are contained in given list. */
    website_in?: string[] | null;
    /** All values that are not contained in given list. */
    website_not_in?: string[] | null;
    /** All values less than the given value. */
    website_lt?: string | null;
    /** All values less than or equal the given value. */
    website_lte?: string | null;
    /** All values greater than the given value. */
    website_gt?: string | null;
    /** All values greater than or equal the given value. */
    website_gte?: string | null;
    /** All values containing the given string. */
    website_contains?: string | null;
    /** All values not containing the given string. */
    website_not_contains?: string | null;
    /** All values starting with the given string. */
    website_starts_with?: string | null;
    /** All values not starting with the given string. */
    website_not_starts_with?: string | null;
    /** All values ending with the given string. */
    website_ends_with?: string | null;
    /** All values not ending with the given string. */
    website_not_ends_with?: string | null;

    business_type?: string | null;
    /** All values that are not equal to given value. */
    business_type_not?: string | null;
    /** All values that are contained in given list. */
    business_type_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_type_not_in?: string[] | null;
    /** All values less than the given value. */
    business_type_lt?: string | null;
    /** All values less than or equal the given value. */
    business_type_lte?: string | null;
    /** All values greater than the given value. */
    business_type_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_type_gte?: string | null;
    /** All values containing the given string. */
    business_type_contains?: string | null;
    /** All values not containing the given string. */
    business_type_not_contains?: string | null;
    /** All values starting with the given string. */
    business_type_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_type_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_type_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_type_not_ends_with?: string | null;

    business_start_date?: string | null;
    /** All values that are not equal to given value. */
    business_start_date_not?: string | null;
    /** All values that are contained in given list. */
    business_start_date_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_start_date_not_in?: string[] | null;
    /** All values less than the given value. */
    business_start_date_lt?: string | null;
    /** All values less than or equal the given value. */
    business_start_date_lte?: string | null;
    /** All values greater than the given value. */
    business_start_date_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_start_date_gte?: string | null;
    /** All values containing the given string. */
    business_start_date_contains?: string | null;
    /** All values not containing the given string. */
    business_start_date_not_contains?: string | null;
    /** All values starting with the given string. */
    business_start_date_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_start_date_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_start_date_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_start_date_not_ends_with?: string | null;

    business_state_formation?: string | null;
    /** All values that are not equal to given value. */
    business_state_formation_not?: string | null;
    /** All values that are contained in given list. */
    business_state_formation_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_state_formation_not_in?: string[] | null;
    /** All values less than the given value. */
    business_state_formation_lt?: string | null;
    /** All values less than or equal the given value. */
    business_state_formation_lte?: string | null;
    /** All values greater than the given value. */
    business_state_formation_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_state_formation_gte?: string | null;
    /** All values containing the given string. */
    business_state_formation_contains?: string | null;
    /** All values not containing the given string. */
    business_state_formation_not_contains?: string | null;
    /** All values starting with the given string. */
    business_state_formation_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_state_formation_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_state_formation_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_state_formation_not_ends_with?: string | null;

    business_tid?: string | null;
    /** All values that are not equal to given value. */
    business_tid_not?: string | null;
    /** All values that are contained in given list. */
    business_tid_in?: string[] | null;
    /** All values that are not contained in given list. */
    business_tid_not_in?: string[] | null;
    /** All values less than the given value. */
    business_tid_lt?: string | null;
    /** All values less than or equal the given value. */
    business_tid_lte?: string | null;
    /** All values greater than the given value. */
    business_tid_gt?: string | null;
    /** All values greater than or equal the given value. */
    business_tid_gte?: string | null;
    /** All values containing the given string. */
    business_tid_contains?: string | null;
    /** All values not containing the given string. */
    business_tid_not_contains?: string | null;
    /** All values starting with the given string. */
    business_tid_starts_with?: string | null;
    /** All values not starting with the given string. */
    business_tid_not_starts_with?: string | null;
    /** All values ending with the given string. */
    business_tid_ends_with?: string | null;
    /** All values not ending with the given string. */
    business_tid_not_ends_with?: string | null;

    name_on_check?: string | null;
    /** All values that are not equal to given value. */
    name_on_check_not?: string | null;
    /** All values that are contained in given list. */
    name_on_check_in?: string[] | null;
    /** All values that are not contained in given list. */
    name_on_check_not_in?: string[] | null;
    /** All values less than the given value. */
    name_on_check_lt?: string | null;
    /** All values less than or equal the given value. */
    name_on_check_lte?: string | null;
    /** All values greater than the given value. */
    name_on_check_gt?: string | null;
    /** All values greater than or equal the given value. */
    name_on_check_gte?: string | null;
    /** All values containing the given string. */
    name_on_check_contains?: string | null;
    /** All values not containing the given string. */
    name_on_check_not_contains?: string | null;
    /** All values starting with the given string. */
    name_on_check_starts_with?: string | null;
    /** All values not starting with the given string. */
    name_on_check_not_starts_with?: string | null;
    /** All values ending with the given string. */
    name_on_check_ends_with?: string | null;
    /** All values not ending with the given string. */
    name_on_check_not_ends_with?: string | null;

    bank_name?: string | null;
    /** All values that are not equal to given value. */
    bank_name_not?: string | null;
    /** All values that are contained in given list. */
    bank_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    bank_name_not_in?: string[] | null;
    /** All values less than the given value. */
    bank_name_lt?: string | null;
    /** All values less than or equal the given value. */
    bank_name_lte?: string | null;
    /** All values greater than the given value. */
    bank_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    bank_name_gte?: string | null;
    /** All values containing the given string. */
    bank_name_contains?: string | null;
    /** All values not containing the given string. */
    bank_name_not_contains?: string | null;
    /** All values starting with the given string. */
    bank_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    bank_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    bank_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    bank_name_not_ends_with?: string | null;

    routing_number?: string | null;
    /** All values that are not equal to given value. */
    routing_number_not?: string | null;
    /** All values that are contained in given list. */
    routing_number_in?: string[] | null;
    /** All values that are not contained in given list. */
    routing_number_not_in?: string[] | null;
    /** All values less than the given value. */
    routing_number_lt?: string | null;
    /** All values less than or equal the given value. */
    routing_number_lte?: string | null;
    /** All values greater than the given value. */
    routing_number_gt?: string | null;
    /** All values greater than or equal the given value. */
    routing_number_gte?: string | null;
    /** All values containing the given string. */
    routing_number_contains?: string | null;
    /** All values not containing the given string. */
    routing_number_not_contains?: string | null;
    /** All values starting with the given string. */
    routing_number_starts_with?: string | null;
    /** All values not starting with the given string. */
    routing_number_not_starts_with?: string | null;
    /** All values ending with the given string. */
    routing_number_ends_with?: string | null;
    /** All values not ending with the given string. */
    routing_number_not_ends_with?: string | null;

    account_number?: string | null;
    /** All values that are not equal to given value. */
    account_number_not?: string | null;
    /** All values that are contained in given list. */
    account_number_in?: string[] | null;
    /** All values that are not contained in given list. */
    account_number_not_in?: string[] | null;
    /** All values less than the given value. */
    account_number_lt?: string | null;
    /** All values less than or equal the given value. */
    account_number_lte?: string | null;
    /** All values greater than the given value. */
    account_number_gt?: string | null;
    /** All values greater than or equal the given value. */
    account_number_gte?: string | null;
    /** All values containing the given string. */
    account_number_contains?: string | null;
    /** All values not containing the given string. */
    account_number_not_contains?: string | null;
    /** All values starting with the given string. */
    account_number_starts_with?: string | null;
    /** All values not starting with the given string. */
    account_number_not_starts_with?: string | null;
    /** All values ending with the given string. */
    account_number_ends_with?: string | null;
    /** All values not ending with the given string. */
    account_number_not_ends_with?: string | null;

    card_present?: string | null;
    /** All values that are not equal to given value. */
    card_present_not?: string | null;
    /** All values that are contained in given list. */
    card_present_in?: string[] | null;
    /** All values that are not contained in given list. */
    card_present_not_in?: string[] | null;
    /** All values less than the given value. */
    card_present_lt?: string | null;
    /** All values less than or equal the given value. */
    card_present_lte?: string | null;
    /** All values greater than the given value. */
    card_present_gt?: string | null;
    /** All values greater than or equal the given value. */
    card_present_gte?: string | null;
    /** All values containing the given string. */
    card_present_contains?: string | null;
    /** All values not containing the given string. */
    card_present_not_contains?: string | null;
    /** All values starting with the given string. */
    card_present_starts_with?: string | null;
    /** All values not starting with the given string. */
    card_present_not_starts_with?: string | null;
    /** All values ending with the given string. */
    card_present_ends_with?: string | null;
    /** All values not ending with the given string. */
    card_present_not_ends_with?: string | null;

    card_not_present?: string | null;
    /** All values that are not equal to given value. */
    card_not_present_not?: string | null;
    /** All values that are contained in given list. */
    card_not_present_in?: string[] | null;
    /** All values that are not contained in given list. */
    card_not_present_not_in?: string[] | null;
    /** All values less than the given value. */
    card_not_present_lt?: string | null;
    /** All values less than or equal the given value. */
    card_not_present_lte?: string | null;
    /** All values greater than the given value. */
    card_not_present_gt?: string | null;
    /** All values greater than or equal the given value. */
    card_not_present_gte?: string | null;
    /** All values containing the given string. */
    card_not_present_contains?: string | null;
    /** All values not containing the given string. */
    card_not_present_not_contains?: string | null;
    /** All values starting with the given string. */
    card_not_present_starts_with?: string | null;
    /** All values not starting with the given string. */
    card_not_present_not_starts_with?: string | null;
    /** All values ending with the given string. */
    card_not_present_ends_with?: string | null;
    /** All values not ending with the given string. */
    card_not_present_not_ends_with?: string | null;

    products_services_sold?: string | null;
    /** All values that are not equal to given value. */
    products_services_sold_not?: string | null;
    /** All values that are contained in given list. */
    products_services_sold_in?: string[] | null;
    /** All values that are not contained in given list. */
    products_services_sold_not_in?: string[] | null;
    /** All values less than the given value. */
    products_services_sold_lt?: string | null;
    /** All values less than or equal the given value. */
    products_services_sold_lte?: string | null;
    /** All values greater than the given value. */
    products_services_sold_gt?: string | null;
    /** All values greater than or equal the given value. */
    products_services_sold_gte?: string | null;
    /** All values containing the given string. */
    products_services_sold_contains?: string | null;
    /** All values not containing the given string. */
    products_services_sold_not_contains?: string | null;
    /** All values starting with the given string. */
    products_services_sold_starts_with?: string | null;
    /** All values not starting with the given string. */
    products_services_sold_not_starts_with?: string | null;
    /** All values ending with the given string. */
    products_services_sold_ends_with?: string | null;
    /** All values not ending with the given string. */
    products_services_sold_not_ends_with?: string | null;

    return_policy?: string | null;
    /** All values that are not equal to given value. */
    return_policy_not?: string | null;
    /** All values that are contained in given list. */
    return_policy_in?: string[] | null;
    /** All values that are not contained in given list. */
    return_policy_not_in?: string[] | null;
    /** All values less than the given value. */
    return_policy_lt?: string | null;
    /** All values less than or equal the given value. */
    return_policy_lte?: string | null;
    /** All values greater than the given value. */
    return_policy_gt?: string | null;
    /** All values greater than or equal the given value. */
    return_policy_gte?: string | null;
    /** All values containing the given string. */
    return_policy_contains?: string | null;
    /** All values not containing the given string. */
    return_policy_not_contains?: string | null;
    /** All values starting with the given string. */
    return_policy_starts_with?: string | null;
    /** All values not starting with the given string. */
    return_policy_not_starts_with?: string | null;
    /** All values ending with the given string. */
    return_policy_ends_with?: string | null;
    /** All values not ending with the given string. */
    return_policy_not_ends_with?: string | null;

    average_ticket?: string | null;
    /** All values that are not equal to given value. */
    average_ticket_not?: string | null;
    /** All values that are contained in given list. */
    average_ticket_in?: string[] | null;
    /** All values that are not contained in given list. */
    average_ticket_not_in?: string[] | null;
    /** All values less than the given value. */
    average_ticket_lt?: string | null;
    /** All values less than or equal the given value. */
    average_ticket_lte?: string | null;
    /** All values greater than the given value. */
    average_ticket_gt?: string | null;
    /** All values greater than or equal the given value. */
    average_ticket_gte?: string | null;
    /** All values containing the given string. */
    average_ticket_contains?: string | null;
    /** All values not containing the given string. */
    average_ticket_not_contains?: string | null;
    /** All values starting with the given string. */
    average_ticket_starts_with?: string | null;
    /** All values not starting with the given string. */
    average_ticket_not_starts_with?: string | null;
    /** All values ending with the given string. */
    average_ticket_ends_with?: string | null;
    /** All values not ending with the given string. */
    average_ticket_not_ends_with?: string | null;

    annual_volume?: string | null;
    /** All values that are not equal to given value. */
    annual_volume_not?: string | null;
    /** All values that are contained in given list. */
    annual_volume_in?: string[] | null;
    /** All values that are not contained in given list. */
    annual_volume_not_in?: string[] | null;
    /** All values less than the given value. */
    annual_volume_lt?: string | null;
    /** All values less than or equal the given value. */
    annual_volume_lte?: string | null;
    /** All values greater than the given value. */
    annual_volume_gt?: string | null;
    /** All values greater than or equal the given value. */
    annual_volume_gte?: string | null;
    /** All values containing the given string. */
    annual_volume_contains?: string | null;
    /** All values not containing the given string. */
    annual_volume_not_contains?: string | null;
    /** All values starting with the given string. */
    annual_volume_starts_with?: string | null;
    /** All values not starting with the given string. */
    annual_volume_not_starts_with?: string | null;
    /** All values ending with the given string. */
    annual_volume_ends_with?: string | null;
    /** All values not ending with the given string. */
    annual_volume_not_ends_with?: string | null;

    process_ecommerce?: boolean | null;
    /** All values that are not equal to given value. */
    process_ecommerce_not?: boolean | null;

    statements_available?: boolean | null;
    /** All values that are not equal to given value. */
    statements_available_not?: boolean | null;

    accept_amex?: string | null;
    /** All values that are not equal to given value. */
    accept_amex_not?: string | null;
    /** All values that are contained in given list. */
    accept_amex_in?: string[] | null;
    /** All values that are not contained in given list. */
    accept_amex_not_in?: string[] | null;
    /** All values less than the given value. */
    accept_amex_lt?: string | null;
    /** All values less than or equal the given value. */
    accept_amex_lte?: string | null;
    /** All values greater than the given value. */
    accept_amex_gt?: string | null;
    /** All values greater than or equal the given value. */
    accept_amex_gte?: string | null;
    /** All values containing the given string. */
    accept_amex_contains?: string | null;
    /** All values not containing the given string. */
    accept_amex_not_contains?: string | null;
    /** All values starting with the given string. */
    accept_amex_starts_with?: string | null;
    /** All values not starting with the given string. */
    accept_amex_not_starts_with?: string | null;
    /** All values ending with the given string. */
    accept_amex_ends_with?: string | null;
    /** All values not ending with the given string. */
    accept_amex_not_ends_with?: string | null;

    amex_mid?: string | null;
    /** All values that are not equal to given value. */
    amex_mid_not?: string | null;
    /** All values that are contained in given list. */
    amex_mid_in?: string[] | null;
    /** All values that are not contained in given list. */
    amex_mid_not_in?: string[] | null;
    /** All values less than the given value. */
    amex_mid_lt?: string | null;
    /** All values less than or equal the given value. */
    amex_mid_lte?: string | null;
    /** All values greater than the given value. */
    amex_mid_gt?: string | null;
    /** All values greater than or equal the given value. */
    amex_mid_gte?: string | null;
    /** All values containing the given string. */
    amex_mid_contains?: string | null;
    /** All values not containing the given string. */
    amex_mid_not_contains?: string | null;
    /** All values starting with the given string. */
    amex_mid_starts_with?: string | null;
    /** All values not starting with the given string. */
    amex_mid_not_starts_with?: string | null;
    /** All values ending with the given string. */
    amex_mid_ends_with?: string | null;
    /** All values not ending with the given string. */
    amex_mid_not_ends_with?: string | null;

    title?: string | null;
    /** All values that are not equal to given value. */
    title_not?: string | null;
    /** All values that are contained in given list. */
    title_in?: string[] | null;
    /** All values that are not contained in given list. */
    title_not_in?: string[] | null;
    /** All values less than the given value. */
    title_lt?: string | null;
    /** All values less than or equal the given value. */
    title_lte?: string | null;
    /** All values greater than the given value. */
    title_gt?: string | null;
    /** All values greater than or equal the given value. */
    title_gte?: string | null;
    /** All values containing the given string. */
    title_contains?: string | null;
    /** All values not containing the given string. */
    title_not_contains?: string | null;
    /** All values starting with the given string. */
    title_starts_with?: string | null;
    /** All values not starting with the given string. */
    title_not_starts_with?: string | null;
    /** All values ending with the given string. */
    title_ends_with?: string | null;
    /** All values not ending with the given string. */
    title_not_ends_with?: string | null;

    first_name?: string | null;
    /** All values that are not equal to given value. */
    first_name_not?: string | null;
    /** All values that are contained in given list. */
    first_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    first_name_not_in?: string[] | null;
    /** All values less than the given value. */
    first_name_lt?: string | null;
    /** All values less than or equal the given value. */
    first_name_lte?: string | null;
    /** All values greater than the given value. */
    first_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    first_name_gte?: string | null;
    /** All values containing the given string. */
    first_name_contains?: string | null;
    /** All values not containing the given string. */
    first_name_not_contains?: string | null;
    /** All values starting with the given string. */
    first_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    first_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    first_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    first_name_not_ends_with?: string | null;

    last_name?: string | null;
    /** All values that are not equal to given value. */
    last_name_not?: string | null;
    /** All values that are contained in given list. */
    last_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    last_name_not_in?: string[] | null;
    /** All values less than the given value. */
    last_name_lt?: string | null;
    /** All values less than or equal the given value. */
    last_name_lte?: string | null;
    /** All values greater than the given value. */
    last_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    last_name_gte?: string | null;
    /** All values containing the given string. */
    last_name_contains?: string | null;
    /** All values not containing the given string. */
    last_name_not_contains?: string | null;
    /** All values starting with the given string. */
    last_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    last_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    last_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    last_name_not_ends_with?: string | null;

    ownership_percentage?: string | null;
    /** All values that are not equal to given value. */
    ownership_percentage_not?: string | null;
    /** All values that are contained in given list. */
    ownership_percentage_in?: string[] | null;
    /** All values that are not contained in given list. */
    ownership_percentage_not_in?: string[] | null;
    /** All values less than the given value. */
    ownership_percentage_lt?: string | null;
    /** All values less than or equal the given value. */
    ownership_percentage_lte?: string | null;
    /** All values greater than the given value. */
    ownership_percentage_gt?: string | null;
    /** All values greater than or equal the given value. */
    ownership_percentage_gte?: string | null;
    /** All values containing the given string. */
    ownership_percentage_contains?: string | null;
    /** All values not containing the given string. */
    ownership_percentage_not_contains?: string | null;
    /** All values starting with the given string. */
    ownership_percentage_starts_with?: string | null;
    /** All values not starting with the given string. */
    ownership_percentage_not_starts_with?: string | null;
    /** All values ending with the given string. */
    ownership_percentage_ends_with?: string | null;
    /** All values not ending with the given string. */
    ownership_percentage_not_ends_with?: string | null;

    total_owners?: string | null;
    /** All values that are not equal to given value. */
    total_owners_not?: string | null;
    /** All values that are contained in given list. */
    total_owners_in?: string[] | null;
    /** All values that are not contained in given list. */
    total_owners_not_in?: string[] | null;
    /** All values less than the given value. */
    total_owners_lt?: string | null;
    /** All values less than or equal the given value. */
    total_owners_lte?: string | null;
    /** All values greater than the given value. */
    total_owners_gt?: string | null;
    /** All values greater than or equal the given value. */
    total_owners_gte?: string | null;
    /** All values containing the given string. */
    total_owners_contains?: string | null;
    /** All values not containing the given string. */
    total_owners_not_contains?: string | null;
    /** All values starting with the given string. */
    total_owners_starts_with?: string | null;
    /** All values not starting with the given string. */
    total_owners_not_starts_with?: string | null;
    /** All values ending with the given string. */
    total_owners_ends_with?: string | null;
    /** All values not ending with the given string. */
    total_owners_not_ends_with?: string | null;

    owner_street?: string | null;
    /** All values that are not equal to given value. */
    owner_street_not?: string | null;
    /** All values that are contained in given list. */
    owner_street_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner_street_not_in?: string[] | null;
    /** All values less than the given value. */
    owner_street_lt?: string | null;
    /** All values less than or equal the given value. */
    owner_street_lte?: string | null;
    /** All values greater than the given value. */
    owner_street_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner_street_gte?: string | null;
    /** All values containing the given string. */
    owner_street_contains?: string | null;
    /** All values not containing the given string. */
    owner_street_not_contains?: string | null;
    /** All values starting with the given string. */
    owner_street_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner_street_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner_street_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner_street_not_ends_with?: string | null;

    owner_city?: string | null;
    /** All values that are not equal to given value. */
    owner_city_not?: string | null;
    /** All values that are contained in given list. */
    owner_city_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner_city_not_in?: string[] | null;
    /** All values less than the given value. */
    owner_city_lt?: string | null;
    /** All values less than or equal the given value. */
    owner_city_lte?: string | null;
    /** All values greater than the given value. */
    owner_city_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner_city_gte?: string | null;
    /** All values containing the given string. */
    owner_city_contains?: string | null;
    /** All values not containing the given string. */
    owner_city_not_contains?: string | null;
    /** All values starting with the given string. */
    owner_city_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner_city_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner_city_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner_city_not_ends_with?: string | null;

    owner_state?: string | null;
    /** All values that are not equal to given value. */
    owner_state_not?: string | null;
    /** All values that are contained in given list. */
    owner_state_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner_state_not_in?: string[] | null;
    /** All values less than the given value. */
    owner_state_lt?: string | null;
    /** All values less than or equal the given value. */
    owner_state_lte?: string | null;
    /** All values greater than the given value. */
    owner_state_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner_state_gte?: string | null;
    /** All values containing the given string. */
    owner_state_contains?: string | null;
    /** All values not containing the given string. */
    owner_state_not_contains?: string | null;
    /** All values starting with the given string. */
    owner_state_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner_state_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner_state_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner_state_not_ends_with?: string | null;

    owner_zip?: string | null;
    /** All values that are not equal to given value. */
    owner_zip_not?: string | null;
    /** All values that are contained in given list. */
    owner_zip_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner_zip_not_in?: string[] | null;
    /** All values less than the given value. */
    owner_zip_lt?: string | null;
    /** All values less than or equal the given value. */
    owner_zip_lte?: string | null;
    /** All values greater than the given value. */
    owner_zip_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner_zip_gte?: string | null;
    /** All values containing the given string. */
    owner_zip_contains?: string | null;
    /** All values not containing the given string. */
    owner_zip_not_contains?: string | null;
    /** All values starting with the given string. */
    owner_zip_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner_zip_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner_zip_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner_zip_not_ends_with?: string | null;

    owner_home?: string | null;
    /** All values that are not equal to given value. */
    owner_home_not?: string | null;
    /** All values that are contained in given list. */
    owner_home_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner_home_not_in?: string[] | null;
    /** All values less than the given value. */
    owner_home_lt?: string | null;
    /** All values less than or equal the given value. */
    owner_home_lte?: string | null;
    /** All values greater than the given value. */
    owner_home_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner_home_gte?: string | null;
    /** All values containing the given string. */
    owner_home_contains?: string | null;
    /** All values not containing the given string. */
    owner_home_not_contains?: string | null;
    /** All values starting with the given string. */
    owner_home_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner_home_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner_home_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner_home_not_ends_with?: string | null;

    ssn?: string | null;
    /** All values that are not equal to given value. */
    ssn_not?: string | null;
    /** All values that are contained in given list. */
    ssn_in?: string[] | null;
    /** All values that are not contained in given list. */
    ssn_not_in?: string[] | null;
    /** All values less than the given value. */
    ssn_lt?: string | null;
    /** All values less than or equal the given value. */
    ssn_lte?: string | null;
    /** All values greater than the given value. */
    ssn_gt?: string | null;
    /** All values greater than or equal the given value. */
    ssn_gte?: string | null;
    /** All values containing the given string. */
    ssn_contains?: string | null;
    /** All values not containing the given string. */
    ssn_not_contains?: string | null;
    /** All values starting with the given string. */
    ssn_starts_with?: string | null;
    /** All values not starting with the given string. */
    ssn_not_starts_with?: string | null;
    /** All values ending with the given string. */
    ssn_ends_with?: string | null;
    /** All values not ending with the given string. */
    ssn_not_ends_with?: string | null;

    dob?: string | null;
    /** All values that are not equal to given value. */
    dob_not?: string | null;
    /** All values that are contained in given list. */
    dob_in?: string[] | null;
    /** All values that are not contained in given list. */
    dob_not_in?: string[] | null;
    /** All values less than the given value. */
    dob_lt?: string | null;
    /** All values less than or equal the given value. */
    dob_lte?: string | null;
    /** All values greater than the given value. */
    dob_gt?: string | null;
    /** All values greater than or equal the given value. */
    dob_gte?: string | null;
    /** All values containing the given string. */
    dob_contains?: string | null;
    /** All values not containing the given string. */
    dob_not_contains?: string | null;
    /** All values starting with the given string. */
    dob_starts_with?: string | null;
    /** All values not starting with the given string. */
    dob_not_starts_with?: string | null;
    /** All values ending with the given string. */
    dob_ends_with?: string | null;
    /** All values not ending with the given string. */
    dob_not_ends_with?: string | null;

    owner2_first_name?: string | null;
    /** All values that are not equal to given value. */
    owner2_first_name_not?: string | null;
    /** All values that are contained in given list. */
    owner2_first_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_first_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_first_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_first_name_lte?: string | null;
    /** All values greater than the given value. */
    owner2_first_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_first_name_gte?: string | null;
    /** All values containing the given string. */
    owner2_first_name_contains?: string | null;
    /** All values not containing the given string. */
    owner2_first_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_first_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_first_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_first_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_first_name_not_ends_with?: string | null;

    owner2_last_name?: string | null;
    /** All values that are not equal to given value. */
    owner2_last_name_not?: string | null;
    /** All values that are contained in given list. */
    owner2_last_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_last_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_last_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_last_name_lte?: string | null;
    /** All values greater than the given value. */
    owner2_last_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_last_name_gte?: string | null;
    /** All values containing the given string. */
    owner2_last_name_contains?: string | null;
    /** All values not containing the given string. */
    owner2_last_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_last_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_last_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_last_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_last_name_not_ends_with?: string | null;

    owner2_title?: string | null;
    /** All values that are not equal to given value. */
    owner2_title_not?: string | null;
    /** All values that are contained in given list. */
    owner2_title_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_title_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_title_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_title_lte?: string | null;
    /** All values greater than the given value. */
    owner2_title_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_title_gte?: string | null;
    /** All values containing the given string. */
    owner2_title_contains?: string | null;
    /** All values not containing the given string. */
    owner2_title_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_title_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_title_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_title_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_title_not_ends_with?: string | null;

    owner2_ownership_percentage?: string | null;
    /** All values that are not equal to given value. */
    owner2_ownership_percentage_not?: string | null;
    /** All values that are contained in given list. */
    owner2_ownership_percentage_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_ownership_percentage_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_ownership_percentage_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_ownership_percentage_lte?: string | null;
    /** All values greater than the given value. */
    owner2_ownership_percentage_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_ownership_percentage_gte?: string | null;
    /** All values containing the given string. */
    owner2_ownership_percentage_contains?: string | null;
    /** All values not containing the given string. */
    owner2_ownership_percentage_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_ownership_percentage_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_ownership_percentage_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_ownership_percentage_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_ownership_percentage_not_ends_with?: string | null;

    owner2_phone?: string | null;
    /** All values that are not equal to given value. */
    owner2_phone_not?: string | null;
    /** All values that are contained in given list. */
    owner2_phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_phone_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_phone_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_phone_lte?: string | null;
    /** All values greater than the given value. */
    owner2_phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_phone_gte?: string | null;
    /** All values containing the given string. */
    owner2_phone_contains?: string | null;
    /** All values not containing the given string. */
    owner2_phone_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_phone_not_ends_with?: string | null;

    owner2_ssn?: string | null;
    /** All values that are not equal to given value. */
    owner2_ssn_not?: string | null;
    /** All values that are contained in given list. */
    owner2_ssn_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_ssn_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_ssn_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_ssn_lte?: string | null;
    /** All values greater than the given value. */
    owner2_ssn_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_ssn_gte?: string | null;
    /** All values containing the given string. */
    owner2_ssn_contains?: string | null;
    /** All values not containing the given string. */
    owner2_ssn_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_ssn_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_ssn_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_ssn_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_ssn_not_ends_with?: string | null;

    owner2_dob?: string | null;
    /** All values that are not equal to given value. */
    owner2_dob_not?: string | null;
    /** All values that are contained in given list. */
    owner2_dob_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_dob_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_dob_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_dob_lte?: string | null;
    /** All values greater than the given value. */
    owner2_dob_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_dob_gte?: string | null;
    /** All values containing the given string. */
    owner2_dob_contains?: string | null;
    /** All values not containing the given string. */
    owner2_dob_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_dob_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_dob_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_dob_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_dob_not_ends_with?: string | null;

    owner2_street?: string | null;
    /** All values that are not equal to given value. */
    owner2_street_not?: string | null;
    /** All values that are contained in given list. */
    owner2_street_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_street_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_street_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_street_lte?: string | null;
    /** All values greater than the given value. */
    owner2_street_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_street_gte?: string | null;
    /** All values containing the given string. */
    owner2_street_contains?: string | null;
    /** All values not containing the given string. */
    owner2_street_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_street_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_street_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_street_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_street_not_ends_with?: string | null;

    owner2_city?: string | null;
    /** All values that are not equal to given value. */
    owner2_city_not?: string | null;
    /** All values that are contained in given list. */
    owner2_city_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_city_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_city_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_city_lte?: string | null;
    /** All values greater than the given value. */
    owner2_city_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_city_gte?: string | null;
    /** All values containing the given string. */
    owner2_city_contains?: string | null;
    /** All values not containing the given string. */
    owner2_city_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_city_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_city_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_city_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_city_not_ends_with?: string | null;

    owner2_state?: string | null;
    /** All values that are not equal to given value. */
    owner2_state_not?: string | null;
    /** All values that are contained in given list. */
    owner2_state_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_state_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_state_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_state_lte?: string | null;
    /** All values greater than the given value. */
    owner2_state_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_state_gte?: string | null;
    /** All values containing the given string. */
    owner2_state_contains?: string | null;
    /** All values not containing the given string. */
    owner2_state_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_state_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_state_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_state_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_state_not_ends_with?: string | null;

    owner2_zip?: string | null;
    /** All values that are not equal to given value. */
    owner2_zip_not?: string | null;
    /** All values that are contained in given list. */
    owner2_zip_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner2_zip_not_in?: string[] | null;
    /** All values less than the given value. */
    owner2_zip_lt?: string | null;
    /** All values less than or equal the given value. */
    owner2_zip_lte?: string | null;
    /** All values greater than the given value. */
    owner2_zip_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner2_zip_gte?: string | null;
    /** All values containing the given string. */
    owner2_zip_contains?: string | null;
    /** All values not containing the given string. */
    owner2_zip_not_contains?: string | null;
    /** All values starting with the given string. */
    owner2_zip_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner2_zip_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner2_zip_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner2_zip_not_ends_with?: string | null;

    owner3_first_name?: string | null;
    /** All values that are not equal to given value. */
    owner3_first_name_not?: string | null;
    /** All values that are contained in given list. */
    owner3_first_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_first_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_first_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_first_name_lte?: string | null;
    /** All values greater than the given value. */
    owner3_first_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_first_name_gte?: string | null;
    /** All values containing the given string. */
    owner3_first_name_contains?: string | null;
    /** All values not containing the given string. */
    owner3_first_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_first_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_first_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_first_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_first_name_not_ends_with?: string | null;

    owner3_last_name?: string | null;
    /** All values that are not equal to given value. */
    owner3_last_name_not?: string | null;
    /** All values that are contained in given list. */
    owner3_last_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_last_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_last_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_last_name_lte?: string | null;
    /** All values greater than the given value. */
    owner3_last_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_last_name_gte?: string | null;
    /** All values containing the given string. */
    owner3_last_name_contains?: string | null;
    /** All values not containing the given string. */
    owner3_last_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_last_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_last_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_last_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_last_name_not_ends_with?: string | null;

    owner3_title?: string | null;
    /** All values that are not equal to given value. */
    owner3_title_not?: string | null;
    /** All values that are contained in given list. */
    owner3_title_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_title_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_title_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_title_lte?: string | null;
    /** All values greater than the given value. */
    owner3_title_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_title_gte?: string | null;
    /** All values containing the given string. */
    owner3_title_contains?: string | null;
    /** All values not containing the given string. */
    owner3_title_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_title_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_title_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_title_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_title_not_ends_with?: string | null;

    owner3_ownership_percentage?: string | null;
    /** All values that are not equal to given value. */
    owner3_ownership_percentage_not?: string | null;
    /** All values that are contained in given list. */
    owner3_ownership_percentage_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_ownership_percentage_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_ownership_percentage_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_ownership_percentage_lte?: string | null;
    /** All values greater than the given value. */
    owner3_ownership_percentage_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_ownership_percentage_gte?: string | null;
    /** All values containing the given string. */
    owner3_ownership_percentage_contains?: string | null;
    /** All values not containing the given string. */
    owner3_ownership_percentage_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_ownership_percentage_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_ownership_percentage_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_ownership_percentage_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_ownership_percentage_not_ends_with?: string | null;

    owner3_phone?: string | null;
    /** All values that are not equal to given value. */
    owner3_phone_not?: string | null;
    /** All values that are contained in given list. */
    owner3_phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_phone_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_phone_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_phone_lte?: string | null;
    /** All values greater than the given value. */
    owner3_phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_phone_gte?: string | null;
    /** All values containing the given string. */
    owner3_phone_contains?: string | null;
    /** All values not containing the given string. */
    owner3_phone_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_phone_not_ends_with?: string | null;

    owner3_ssn?: string | null;
    /** All values that are not equal to given value. */
    owner3_ssn_not?: string | null;
    /** All values that are contained in given list. */
    owner3_ssn_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_ssn_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_ssn_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_ssn_lte?: string | null;
    /** All values greater than the given value. */
    owner3_ssn_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_ssn_gte?: string | null;
    /** All values containing the given string. */
    owner3_ssn_contains?: string | null;
    /** All values not containing the given string. */
    owner3_ssn_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_ssn_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_ssn_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_ssn_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_ssn_not_ends_with?: string | null;

    owner3_dob?: string | null;
    /** All values that are not equal to given value. */
    owner3_dob_not?: string | null;
    /** All values that are contained in given list. */
    owner3_dob_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_dob_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_dob_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_dob_lte?: string | null;
    /** All values greater than the given value. */
    owner3_dob_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_dob_gte?: string | null;
    /** All values containing the given string. */
    owner3_dob_contains?: string | null;
    /** All values not containing the given string. */
    owner3_dob_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_dob_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_dob_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_dob_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_dob_not_ends_with?: string | null;

    owner3_street?: string | null;
    /** All values that are not equal to given value. */
    owner3_street_not?: string | null;
    /** All values that are contained in given list. */
    owner3_street_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_street_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_street_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_street_lte?: string | null;
    /** All values greater than the given value. */
    owner3_street_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_street_gte?: string | null;
    /** All values containing the given string. */
    owner3_street_contains?: string | null;
    /** All values not containing the given string. */
    owner3_street_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_street_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_street_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_street_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_street_not_ends_with?: string | null;

    owner3_city?: string | null;
    /** All values that are not equal to given value. */
    owner3_city_not?: string | null;
    /** All values that are contained in given list. */
    owner3_city_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_city_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_city_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_city_lte?: string | null;
    /** All values greater than the given value. */
    owner3_city_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_city_gte?: string | null;
    /** All values containing the given string. */
    owner3_city_contains?: string | null;
    /** All values not containing the given string. */
    owner3_city_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_city_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_city_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_city_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_city_not_ends_with?: string | null;

    owner3_state?: string | null;
    /** All values that are not equal to given value. */
    owner3_state_not?: string | null;
    /** All values that are contained in given list. */
    owner3_state_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_state_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_state_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_state_lte?: string | null;
    /** All values greater than the given value. */
    owner3_state_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_state_gte?: string | null;
    /** All values containing the given string. */
    owner3_state_contains?: string | null;
    /** All values not containing the given string. */
    owner3_state_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_state_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_state_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_state_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_state_not_ends_with?: string | null;

    owner3_zip?: string | null;
    /** All values that are not equal to given value. */
    owner3_zip_not?: string | null;
    /** All values that are contained in given list. */
    owner3_zip_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner3_zip_not_in?: string[] | null;
    /** All values less than the given value. */
    owner3_zip_lt?: string | null;
    /** All values less than or equal the given value. */
    owner3_zip_lte?: string | null;
    /** All values greater than the given value. */
    owner3_zip_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner3_zip_gte?: string | null;
    /** All values containing the given string. */
    owner3_zip_contains?: string | null;
    /** All values not containing the given string. */
    owner3_zip_not_contains?: string | null;
    /** All values starting with the given string. */
    owner3_zip_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner3_zip_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner3_zip_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner3_zip_not_ends_with?: string | null;

    owner4_first_name?: string | null;
    /** All values that are not equal to given value. */
    owner4_first_name_not?: string | null;
    /** All values that are contained in given list. */
    owner4_first_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_first_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_first_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_first_name_lte?: string | null;
    /** All values greater than the given value. */
    owner4_first_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_first_name_gte?: string | null;
    /** All values containing the given string. */
    owner4_first_name_contains?: string | null;
    /** All values not containing the given string. */
    owner4_first_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_first_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_first_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_first_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_first_name_not_ends_with?: string | null;

    owner4_last_name?: string | null;
    /** All values that are not equal to given value. */
    owner4_last_name_not?: string | null;
    /** All values that are contained in given list. */
    owner4_last_name_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_last_name_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_last_name_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_last_name_lte?: string | null;
    /** All values greater than the given value. */
    owner4_last_name_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_last_name_gte?: string | null;
    /** All values containing the given string. */
    owner4_last_name_contains?: string | null;
    /** All values not containing the given string. */
    owner4_last_name_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_last_name_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_last_name_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_last_name_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_last_name_not_ends_with?: string | null;

    owner4_title?: string | null;
    /** All values that are not equal to given value. */
    owner4_title_not?: string | null;
    /** All values that are contained in given list. */
    owner4_title_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_title_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_title_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_title_lte?: string | null;
    /** All values greater than the given value. */
    owner4_title_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_title_gte?: string | null;
    /** All values containing the given string. */
    owner4_title_contains?: string | null;
    /** All values not containing the given string. */
    owner4_title_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_title_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_title_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_title_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_title_not_ends_with?: string | null;

    owner4_ownership_percentage?: string | null;
    /** All values that are not equal to given value. */
    owner4_ownership_percentage_not?: string | null;
    /** All values that are contained in given list. */
    owner4_ownership_percentage_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_ownership_percentage_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_ownership_percentage_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_ownership_percentage_lte?: string | null;
    /** All values greater than the given value. */
    owner4_ownership_percentage_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_ownership_percentage_gte?: string | null;
    /** All values containing the given string. */
    owner4_ownership_percentage_contains?: string | null;
    /** All values not containing the given string. */
    owner4_ownership_percentage_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_ownership_percentage_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_ownership_percentage_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_ownership_percentage_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_ownership_percentage_not_ends_with?: string | null;

    owner4_phone?: string | null;
    /** All values that are not equal to given value. */
    owner4_phone_not?: string | null;
    /** All values that are contained in given list. */
    owner4_phone_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_phone_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_phone_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_phone_lte?: string | null;
    /** All values greater than the given value. */
    owner4_phone_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_phone_gte?: string | null;
    /** All values containing the given string. */
    owner4_phone_contains?: string | null;
    /** All values not containing the given string. */
    owner4_phone_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_phone_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_phone_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_phone_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_phone_not_ends_with?: string | null;

    owner4_ssn?: string | null;
    /** All values that are not equal to given value. */
    owner4_ssn_not?: string | null;
    /** All values that are contained in given list. */
    owner4_ssn_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_ssn_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_ssn_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_ssn_lte?: string | null;
    /** All values greater than the given value. */
    owner4_ssn_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_ssn_gte?: string | null;
    /** All values containing the given string. */
    owner4_ssn_contains?: string | null;
    /** All values not containing the given string. */
    owner4_ssn_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_ssn_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_ssn_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_ssn_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_ssn_not_ends_with?: string | null;

    owner4_dob?: string | null;
    /** All values that are not equal to given value. */
    owner4_dob_not?: string | null;
    /** All values that are contained in given list. */
    owner4_dob_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_dob_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_dob_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_dob_lte?: string | null;
    /** All values greater than the given value. */
    owner4_dob_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_dob_gte?: string | null;
    /** All values containing the given string. */
    owner4_dob_contains?: string | null;
    /** All values not containing the given string. */
    owner4_dob_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_dob_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_dob_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_dob_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_dob_not_ends_with?: string | null;

    owner4_street?: string | null;
    /** All values that are not equal to given value. */
    owner4_street_not?: string | null;
    /** All values that are contained in given list. */
    owner4_street_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_street_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_street_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_street_lte?: string | null;
    /** All values greater than the given value. */
    owner4_street_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_street_gte?: string | null;
    /** All values containing the given string. */
    owner4_street_contains?: string | null;
    /** All values not containing the given string. */
    owner4_street_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_street_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_street_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_street_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_street_not_ends_with?: string | null;

    owner4_city?: string | null;
    /** All values that are not equal to given value. */
    owner4_city_not?: string | null;
    /** All values that are contained in given list. */
    owner4_city_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_city_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_city_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_city_lte?: string | null;
    /** All values greater than the given value. */
    owner4_city_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_city_gte?: string | null;
    /** All values containing the given string. */
    owner4_city_contains?: string | null;
    /** All values not containing the given string. */
    owner4_city_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_city_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_city_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_city_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_city_not_ends_with?: string | null;

    owner4_state?: string | null;
    /** All values that are not equal to given value. */
    owner4_state_not?: string | null;
    /** All values that are contained in given list. */
    owner4_state_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_state_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_state_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_state_lte?: string | null;
    /** All values greater than the given value. */
    owner4_state_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_state_gte?: string | null;
    /** All values containing the given string. */
    owner4_state_contains?: string | null;
    /** All values not containing the given string. */
    owner4_state_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_state_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_state_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_state_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_state_not_ends_with?: string | null;

    owner4_zip?: string | null;
    /** All values that are not equal to given value. */
    owner4_zip_not?: string | null;
    /** All values that are contained in given list. */
    owner4_zip_in?: string[] | null;
    /** All values that are not contained in given list. */
    owner4_zip_not_in?: string[] | null;
    /** All values less than the given value. */
    owner4_zip_lt?: string | null;
    /** All values less than or equal the given value. */
    owner4_zip_lte?: string | null;
    /** All values greater than the given value. */
    owner4_zip_gt?: string | null;
    /** All values greater than or equal the given value. */
    owner4_zip_gte?: string | null;
    /** All values containing the given string. */
    owner4_zip_contains?: string | null;
    /** All values not containing the given string. */
    owner4_zip_not_contains?: string | null;
    /** All values starting with the given string. */
    owner4_zip_starts_with?: string | null;
    /** All values not starting with the given string. */
    owner4_zip_not_starts_with?: string | null;
    /** All values ending with the given string. */
    owner4_zip_ends_with?: string | null;
    /** All values not ending with the given string. */
    owner4_zip_not_ends_with?: string | null;

    location_type?: string | null;
    /** All values that are not equal to given value. */
    location_type_not?: string | null;
    /** All values that are contained in given list. */
    location_type_in?: string[] | null;
    /** All values that are not contained in given list. */
    location_type_not_in?: string[] | null;
    /** All values less than the given value. */
    location_type_lt?: string | null;
    /** All values less than or equal the given value. */
    location_type_lte?: string | null;
    /** All values greater than the given value. */
    location_type_gt?: string | null;
    /** All values greater than or equal the given value. */
    location_type_gte?: string | null;
    /** All values containing the given string. */
    location_type_contains?: string | null;
    /** All values not containing the given string. */
    location_type_not_contains?: string | null;
    /** All values starting with the given string. */
    location_type_starts_with?: string | null;
    /** All values not starting with the given string. */
    location_type_not_starts_with?: string | null;
    /** All values ending with the given string. */
    location_type_ends_with?: string | null;
    /** All values not ending with the given string. */
    location_type_not_ends_with?: string | null;

    signature?: string | null;
    /** All values that are not equal to given value. */
    signature_not?: string | null;
    /** All values that are contained in given list. */
    signature_in?: string[] | null;
    /** All values that are not contained in given list. */
    signature_not_in?: string[] | null;
    /** All values less than the given value. */
    signature_lt?: string | null;
    /** All values less than or equal the given value. */
    signature_lte?: string | null;
    /** All values greater than the given value. */
    signature_gt?: string | null;
    /** All values greater than or equal the given value. */
    signature_gte?: string | null;
    /** All values containing the given string. */
    signature_contains?: string | null;
    /** All values not containing the given string. */
    signature_not_contains?: string | null;
    /** All values starting with the given string. */
    signature_starts_with?: string | null;
    /** All values not starting with the given string. */
    signature_not_starts_with?: string | null;
    /** All values ending with the given string. */
    signature_ends_with?: string | null;
    /** All values not ending with the given string. */
    signature_not_ends_with?: string | null;

    signature_data?: string | null;
    /** All values that are not equal to given value. */
    signature_data_not?: string | null;
    /** All values that are contained in given list. */
    signature_data_in?: string[] | null;
    /** All values that are not contained in given list. */
    signature_data_not_in?: string[] | null;
    /** All values less than the given value. */
    signature_data_lt?: string | null;
    /** All values less than or equal the given value. */
    signature_data_lte?: string | null;
    /** All values greater than the given value. */
    signature_data_gt?: string | null;
    /** All values greater than or equal the given value. */
    signature_data_gte?: string | null;
    /** All values containing the given string. */
    signature_data_contains?: string | null;
    /** All values not containing the given string. */
    signature_data_not_contains?: string | null;
    /** All values starting with the given string. */
    signature_data_starts_with?: string | null;
    /** All values not starting with the given string. */
    signature_data_not_starts_with?: string | null;
    /** All values ending with the given string. */
    signature_data_ends_with?: string | null;
    /** All values not ending with the given string. */
    signature_data_not_ends_with?: string | null;

    pricing?: string | null;
    /** All values that are not equal to given value. */
    pricing_not?: string | null;
    /** All values that are contained in given list. */
    pricing_in?: string[] | null;
    /** All values that are not contained in given list. */
    pricing_not_in?: string[] | null;
    /** All values less than the given value. */
    pricing_lt?: string | null;
    /** All values less than or equal the given value. */
    pricing_lte?: string | null;
    /** All values greater than the given value. */
    pricing_gt?: string | null;
    /** All values greater than or equal the given value. */
    pricing_gte?: string | null;
    /** All values containing the given string. */
    pricing_contains?: string | null;
    /** All values not containing the given string. */
    pricing_not_contains?: string | null;
    /** All values starting with the given string. */
    pricing_starts_with?: string | null;
    /** All values not starting with the given string. */
    pricing_not_starts_with?: string | null;
    /** All values ending with the given string. */
    pricing_ends_with?: string | null;
    /** All values not ending with the given string. */
    pricing_not_ends_with?: string | null;

    commission?: number | null;
    /** All values that are not equal to given value. */
    commission_not?: number | null;
    /** All values that are contained in given list. */
    commission_in?: number[] | null;
    /** All values that are not contained in given list. */
    commission_not_in?: number[] | null;
    /** All values less than the given value. */
    commission_lt?: number | null;
    /** All values less than or equal the given value. */
    commission_lte?: number | null;
    /** All values greater than the given value. */
    commission_gt?: number | null;
    /** All values greater than or equal the given value. */
    commission_gte?: number | null;

    prepaidMonths?: number | null;
    /** All values that are not equal to given value. */
    prepaidMonths_not?: number | null;
    /** All values that are contained in given list. */
    prepaidMonths_in?: number[] | null;
    /** All values that are not contained in given list. */
    prepaidMonths_not_in?: number[] | null;
    /** All values less than the given value. */
    prepaidMonths_lt?: number | null;
    /** All values less than or equal the given value. */
    prepaidMonths_lte?: number | null;
    /** All values greater than the given value. */
    prepaidMonths_gt?: number | null;
    /** All values greater than or equal the given value. */
    prepaidMonths_gte?: number | null;

    showErrors?: boolean | null;
    /** All values that are not equal to given value. */
    showErrors_not?: boolean | null;

    submitted?: boolean | null;
    /** All values that are not equal to given value. */
    submitted_not?: boolean | null;

    hasBankInfo?: boolean | null;
    /** All values that are not equal to given value. */
    hasBankInfo_not?: boolean | null;

    notes?: string | null;
    /** All values that are not equal to given value. */
    notes_not?: string | null;
    /** All values that are contained in given list. */
    notes_in?: string[] | null;
    /** All values that are not contained in given list. */
    notes_not_in?: string[] | null;
    /** All values less than the given value. */
    notes_lt?: string | null;
    /** All values less than or equal the given value. */
    notes_lte?: string | null;
    /** All values greater than the given value. */
    notes_gt?: string | null;
    /** All values greater than or equal the given value. */
    notes_gte?: string | null;
    /** All values containing the given string. */
    notes_contains?: string | null;
    /** All values not containing the given string. */
    notes_not_contains?: string | null;
    /** All values starting with the given string. */
    notes_starts_with?: string | null;
    /** All values not starting with the given string. */
    notes_not_starts_with?: string | null;
    /** All values ending with the given string. */
    notes_ends_with?: string | null;
    /** All values not ending with the given string. */
    notes_not_ends_with?: string | null;

    agree?: boolean | null;
    /** All values that are not equal to given value. */
    agree_not?: boolean | null;
}

export interface MerchantLeadFollowupWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantLeadFollowupWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantLeadFollowupWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantLeadFollowupWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    date?: DateTime | null;
    /** All values that are not equal to given value. */
    date_not?: DateTime | null;
    /** All values that are contained in given list. */
    date_in?: DateTime[] | null;
    /** All values that are not contained in given list. */
    date_not_in?: DateTime[] | null;
    /** All values less than the given value. */
    date_lt?: DateTime | null;
    /** All values less than or equal the given value. */
    date_lte?: DateTime | null;
    /** All values greater than the given value. */
    date_gt?: DateTime | null;
    /** All values greater than or equal the given value. */
    date_gte?: DateTime | null;

    appointment?: boolean | null;
    /** All values that are not equal to given value. */
    appointment_not?: boolean | null;

    lead?: MerchantLeadWhereInput | null;

    user?: UserWhereInput | null;
}

export interface DraftMerchantWhereInput {
    /** Logical AND on all given filters. */
    AND?: DraftMerchantWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: DraftMerchantWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: DraftMerchantWhereInput[] | null;

    id?: string | null;
    /** All values that are not equal to given value. */
    id_not?: string | null;
    /** All values that are contained in given list. */
    id_in?: string[] | null;
    /** All values that are not contained in given list. */
    id_not_in?: string[] | null;
    /** All values less than the given value. */
    id_lt?: string | null;
    /** All values less than or equal the given value. */
    id_lte?: string | null;
    /** All values greater than the given value. */
    id_gt?: string | null;
    /** All values greater than or equal the given value. */
    id_gte?: string | null;
    /** All values containing the given string. */
    id_contains?: string | null;
    /** All values not containing the given string. */
    id_not_contains?: string | null;
    /** All values starting with the given string. */
    id_starts_with?: string | null;
    /** All values not starting with the given string. */
    id_not_starts_with?: string | null;
    /** All values ending with the given string. */
    id_ends_with?: string | null;
    /** All values not ending with the given string. */
    id_not_ends_with?: string | null;

    firstName?: string | null;
    /** All values that are not equal to given value. */
    firstName_not?: string | null;
    /** All values that are contained in given list. */
    firstName_in?: string[] | null;
    /** All values that are not contained in given list. */
    firstName_not_in?: string[] | null;
    /** All values less than the given value. */
    firstName_lt?: string | null;
    /** All values less than or equal the given value. */
    firstName_lte?: string | null;
    /** All values greater than the given value. */
    firstName_gt?: string | null;
    /** All values greater than or equal the given value. */
    firstName_gte?: string | null;
    /** All values containing the given string. */
    firstName_contains?: string | null;
    /** All values not containing the given string. */
    firstName_not_contains?: string | null;
    /** All values starting with the given string. */
    firstName_starts_with?: string | null;
    /** All values not starting with the given string. */
    firstName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    firstName_ends_with?: string | null;
    /** All values not ending with the given string. */
    firstName_not_ends_with?: string | null;

    lastName?: string | null;
    /** All values that are not equal to given value. */
    lastName_not?: string | null;
    /** All values that are contained in given list. */
    lastName_in?: string[] | null;
    /** All values that are not contained in given list. */
    lastName_not_in?: string[] | null;
    /** All values less than the given value. */
    lastName_lt?: string | null;
    /** All values less than or equal the given value. */
    lastName_lte?: string | null;
    /** All values greater than the given value. */
    lastName_gt?: string | null;
    /** All values greater than or equal the given value. */
    lastName_gte?: string | null;
    /** All values containing the given string. */
    lastName_contains?: string | null;
    /** All values not containing the given string. */
    lastName_not_contains?: string | null;
    /** All values starting with the given string. */
    lastName_starts_with?: string | null;
    /** All values not starting with the given string. */
    lastName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    lastName_ends_with?: string | null;
    /** All values not ending with the given string. */
    lastName_not_ends_with?: string | null;

    businessName?: string | null;
    /** All values that are not equal to given value. */
    businessName_not?: string | null;
    /** All values that are contained in given list. */
    businessName_in?: string[] | null;
    /** All values that are not contained in given list. */
    businessName_not_in?: string[] | null;
    /** All values less than the given value. */
    businessName_lt?: string | null;
    /** All values less than or equal the given value. */
    businessName_lte?: string | null;
    /** All values greater than the given value. */
    businessName_gt?: string | null;
    /** All values greater than or equal the given value. */
    businessName_gte?: string | null;
    /** All values containing the given string. */
    businessName_contains?: string | null;
    /** All values not containing the given string. */
    businessName_not_contains?: string | null;
    /** All values starting with the given string. */
    businessName_starts_with?: string | null;
    /** All values not starting with the given string. */
    businessName_not_starts_with?: string | null;
    /** All values ending with the given string. */
    businessName_ends_with?: string | null;
    /** All values not ending with the given string. */
    businessName_not_ends_with?: string | null;

    website?: string | null;
    /** All values that are not equal to given value. */
    website_not?: string | null;
    /** All values that are contained in given list. */
    website_in?: string[] | null;
    /** All values that are not contained in given list. */
    website_not_in?: string[] | null;
    /** All values less than the given value. */
    website_lt?: string | null;
    /** All values less than or equal the given value. */
    website_lte?: string | null;
    /** All values greater than the given value. */
    website_gt?: string | null;
    /** All values greater than or equal the given value. */
    website_gte?: string | null;
    /** All values containing the given string. */
    website_contains?: string | null;
    /** All values not containing the given string. */
    website_not_contains?: string | null;
    /** All values starting with the given string. */
    website_starts_with?: string | null;
    /** All values not starting with the given string. */
    website_not_starts_with?: string | null;
    /** All values ending with the given string. */
    website_ends_with?: string | null;
    /** All values not ending with the given string. */
    website_not_ends_with?: string | null;

    email?: string | null;
    /** All values that are not equal to given value. */
    email_not?: string | null;
    /** All values that are contained in given list. */
    email_in?: string[] | null;
    /** All values that are not contained in given list. */
    email_not_in?: string[] | null;
    /** All values less than the given value. */
    email_lt?: string | null;
    /** All values less than or equal the given value. */
    email_lte?: string | null;
    /** All values greater than the given value. */
    email_gt?: string | null;
    /** All values greater than or equal the given value. */
    email_gte?: string | null;
    /** All values containing the given string. */
    email_contains?: string | null;
    /** All values not containing the given string. */
    email_not_contains?: string | null;
    /** All values starting with the given string. */
    email_starts_with?: string | null;
    /** All values not starting with the given string. */
    email_not_starts_with?: string | null;
    /** All values ending with the given string. */
    email_ends_with?: string | null;
    /** All values not ending with the given string. */
    email_not_ends_with?: string | null;

    infoVideo?: string | null;
    /** All values that are not equal to given value. */
    infoVideo_not?: string | null;
    /** All values that are contained in given list. */
    infoVideo_in?: string[] | null;
    /** All values that are not contained in given list. */
    infoVideo_not_in?: string[] | null;
    /** All values less than the given value. */
    infoVideo_lt?: string | null;
    /** All values less than or equal the given value. */
    infoVideo_lte?: string | null;
    /** All values greater than the given value. */
    infoVideo_gt?: string | null;
    /** All values greater than or equal the given value. */
    infoVideo_gte?: string | null;
    /** All values containing the given string. */
    infoVideo_contains?: string | null;
    /** All values not containing the given string. */
    infoVideo_not_contains?: string | null;
    /** All values starting with the given string. */
    infoVideo_starts_with?: string | null;
    /** All values not starting with the given string. */
    infoVideo_not_starts_with?: string | null;
    /** All values ending with the given string. */
    infoVideo_ends_with?: string | null;
    /** All values not ending with the given string. */
    infoVideo_not_ends_with?: string | null;

    relationship?: string | null;
    /** All values that are not equal to given value. */
    relationship_not?: string | null;
    /** All values that are contained in given list. */
    relationship_in?: string[] | null;
    /** All values that are not contained in given list. */
    relationship_not_in?: string[] | null;
    /** All values less than the given value. */
    relationship_lt?: string | null;
    /** All values less than or equal the given value. */
    relationship_lte?: string | null;
    /** All values greater than the given value. */
    relationship_gt?: string | null;
    /** All values greater than or equal the given value. */
    relationship_gte?: string | null;
    /** All values containing the given string. */
    relationship_contains?: string | null;
    /** All values not containing the given string. */
    relationship_not_contains?: string | null;
    /** All values starting with the given string. */
    relationship_starts_with?: string | null;
    /** All values not starting with the given string. */
    relationship_not_starts_with?: string | null;
    /** All values ending with the given string. */
    relationship_ends_with?: string | null;
    /** All values not ending with the given string. */
    relationship_not_ends_with?: string | null;

    author?: UserWhereInput | null;
}

export interface AmbassadorLeadWhereUniqueInput {
    id?: string | null;

    slug?: string | null;
}

export interface DraftMerchantWhereUniqueInput {
    id?: string | null;
}

export interface MerchantLeadWhereUniqueInput {
    id?: string | null;

    slug?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;
}

export interface MerchantLeadFollowupWhereUniqueInput {
    id?: string | null;
}

export interface MerchantWhereUniqueInput {
    id?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;
}

export interface MerchantDailyEstimateWhereUniqueInput {
    id?: string | null;

    key?: string | null;
}

export interface MerchantMonthlyCommissionWhereUniqueInput {
    id?: string | null;

    key?: string | null;
}

export interface MerchantCommissionDepositWhereUniqueInput {
    id?: string | null;
}

export interface UserWhereUniqueInput {
    id?: string | null;

    phone?: string | null;

    email?: string | null;
}

export interface RoleWhereUniqueInput {
    id?: string | null;

    name?: UserRole | null;
}

export interface MerchantApplicationWhereUniqueInput {
    id?: string | null;
}

export interface EventWhereUniqueInput {
    id?: string | null;
}

export interface AmbassadorLeadCreateInput {
    status?: AmbassadorLeadStatus | null;

    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    slug: string;

    upline: UserCreateOneWithoutAmbassadorLeadsInput;

    events?: EventCreateManyInput | null;
}

export interface UserCreateOneWithoutAmbassadorLeadsInput {
    create?: UserCreateWithoutAmbassadorLeadsInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutAmbassadorLeadsInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface RoleCreateManyInput {
    create?: RoleCreateInput[] | null;

    connect?: RoleWhereUniqueInput[] | null;
}

export interface RoleCreateInput {
    name: UserRole;
}

export interface UserCreateOneWithoutDownlineInput {
    create?: UserCreateWithoutDownlineInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutDownlineInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface MerchantCreateManyWithoutEnrollerInput {
    create?: MerchantCreateWithoutEnrollerInput[] | null;

    connect?: MerchantWhereUniqueInput[] | null;
}

export interface MerchantCreateWithoutEnrollerInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner: UserCreateOneWithoutOwnedBusinessesInput;

    dailyEstimates?: MerchantDailyEstimateCreateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionCreateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositCreateManyWithoutMerchantInput | null;
}

export interface UserCreateOneWithoutOwnedBusinessesInput {
    create?: UserCreateWithoutOwnedBusinessesInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutOwnedBusinessesInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface UserCreateManyWithoutEnrollerInput {
    create?: UserCreateWithoutEnrollerInput[] | null;

    connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateWithoutEnrollerInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface AmbassadorLeadCreateManyWithoutUplineInput {
    create?: AmbassadorLeadCreateWithoutUplineInput[] | null;

    connect?: AmbassadorLeadWhereUniqueInput[] | null;
}

export interface AmbassadorLeadCreateWithoutUplineInput {
    status?: AmbassadorLeadStatus | null;

    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    slug: string;

    events?: EventCreateManyInput | null;
}

export interface EventCreateManyInput {
    create?: EventCreateInput[] | null;

    connect?: EventWhereUniqueInput[] | null;
}

export interface EventCreateInput {
    type: string;

    timestamp: DateTime;
}

export interface MerchantLeadCreateManyWithoutEnrollerInput {
    create?: MerchantLeadCreateWithoutEnrollerInput[] | null;

    connect?: MerchantLeadWhereUniqueInput[] | null;
}

export interface MerchantLeadCreateWithoutEnrollerInput {
    firstName: string;

    lastName: string;

    businessName: string;

    relationship: string;

    infoVideo: string;

    website?: string | null;

    email?: string | null;

    slug: string;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadCreatephonesInput | null;

    ambassadorComments?: MerchantLeadCreateambassadorCommentsInput | null;

    events?: EventCreateManyInput | null;

    application: MerchantApplicationCreateOneInput;

    followup?: MerchantLeadFollowupCreateOneWithoutLeadInput | null;

    owner?: UserCreateOneInput | null;
}

export interface MerchantLeadCreatephonesInput {
    set?: string[] | null;
}

export interface MerchantLeadCreateambassadorCommentsInput {
    set?: string[] | null;
}

export interface MerchantApplicationCreateOneInput {
    create?: MerchantApplicationCreateInput | null;

    connect?: MerchantApplicationWhereUniqueInput | null;
}

export interface MerchantApplicationCreateInput {
    lastUpdated: DateTime;

    submitTimestamp?: DateTime | null;

    dba?: string | null;

    legal_name?: string | null;

    contact_name?: string | null;

    business_street?: string | null;

    business_city?: string | null;

    business_state?: string | null;

    business_zip?: string | null;

    business_phone?: string | null;

    email?: string | null;

    fax?: string | null;

    website?: string | null;

    business_type?: string | null;

    business_start_date?: string | null;

    business_state_formation?: string | null;

    business_tid?: string | null;

    name_on_check?: string | null;

    bank_name?: string | null;

    routing_number?: string | null;

    account_number?: string | null;

    card_present?: string | null;

    card_not_present?: string | null;

    products_services_sold?: string | null;

    return_policy?: string | null;

    average_ticket?: string | null;

    annual_volume?: string | null;

    process_ecommerce?: boolean | null;

    statements_available?: boolean | null;

    accept_amex?: string | null;

    amex_mid?: string | null;

    title?: string | null;

    first_name?: string | null;

    last_name?: string | null;

    ownership_percentage?: string | null;

    total_owners?: string | null;

    owner_street?: string | null;

    owner_city?: string | null;

    owner_state?: string | null;

    owner_zip?: string | null;

    owner_home?: string | null;

    ssn?: string | null;

    dob?: string | null;

    owner2_first_name?: string | null;

    owner2_last_name?: string | null;

    owner2_title?: string | null;

    owner2_ownership_percentage?: string | null;

    owner2_phone?: string | null;

    owner2_ssn?: string | null;

    owner2_dob?: string | null;

    owner2_street?: string | null;

    owner2_city?: string | null;

    owner2_state?: string | null;

    owner2_zip?: string | null;

    owner3_first_name?: string | null;

    owner3_last_name?: string | null;

    owner3_title?: string | null;

    owner3_ownership_percentage?: string | null;

    owner3_phone?: string | null;

    owner3_ssn?: string | null;

    owner3_dob?: string | null;

    owner3_street?: string | null;

    owner3_city?: string | null;

    owner3_state?: string | null;

    owner3_zip?: string | null;

    owner4_first_name?: string | null;

    owner4_last_name?: string | null;

    owner4_title?: string | null;

    owner4_ownership_percentage?: string | null;

    owner4_phone?: string | null;

    owner4_ssn?: string | null;

    owner4_dob?: string | null;

    owner4_street?: string | null;

    owner4_city?: string | null;

    owner4_state?: string | null;

    owner4_zip?: string | null;

    location_type?: string | null;

    signature?: string | null;

    signature_data?: string | null;

    pricing?: string | null;

    commission?: number | null;

    prepaidMonths?: number | null;

    showErrors?: boolean | null;

    submitted?: boolean | null;

    hasBankInfo?: boolean | null;

    notes?: string | null;

    agree?: boolean | null;
}

export interface MerchantLeadFollowupCreateOneWithoutLeadInput {
    create?: MerchantLeadFollowupCreateWithoutLeadInput | null;

    connect?: MerchantLeadFollowupWhereUniqueInput | null;
}

export interface MerchantLeadFollowupCreateWithoutLeadInput {
    date: DateTime;

    appointment: boolean;

    user: UserCreateOneWithoutFollowupsInput;
}

export interface UserCreateOneWithoutFollowupsInput {
    create?: UserCreateWithoutFollowupsInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutFollowupsInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;
}

export interface DraftMerchantCreateManyWithoutAuthorInput {
    create?: DraftMerchantCreateWithoutAuthorInput[] | null;

    connect?: DraftMerchantWhereUniqueInput[] | null;
}

export interface DraftMerchantCreateWithoutAuthorInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    website?: string | null;

    email?: string | null;

    infoVideo?: string | null;

    relationship?: string | null;

    phones?: DraftMerchantCreatephonesInput | null;
}

export interface DraftMerchantCreatephonesInput {
    set?: string[] | null;
}

export interface MerchantCreateManyWithoutOwnerInput {
    create?: MerchantCreateWithoutOwnerInput[] | null;

    connect?: MerchantWhereUniqueInput[] | null;
}

export interface MerchantCreateWithoutOwnerInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    enroller: UserCreateOneWithoutMerchantsInput;

    dailyEstimates?: MerchantDailyEstimateCreateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionCreateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositCreateManyWithoutMerchantInput | null;
}

export interface UserCreateOneWithoutMerchantsInput {
    create?: UserCreateWithoutMerchantsInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutMerchantsInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface MerchantLeadFollowupCreateManyWithoutUserInput {
    create?: MerchantLeadFollowupCreateWithoutUserInput[] | null;

    connect?: MerchantLeadFollowupWhereUniqueInput[] | null;
}

export interface MerchantLeadFollowupCreateWithoutUserInput {
    date: DateTime;

    appointment: boolean;

    lead: MerchantLeadCreateOneWithoutFollowupInput;
}

export interface MerchantLeadCreateOneWithoutFollowupInput {
    create?: MerchantLeadCreateWithoutFollowupInput | null;

    connect?: MerchantLeadWhereUniqueInput | null;
}

export interface MerchantLeadCreateWithoutFollowupInput {
    firstName: string;

    lastName: string;

    businessName: string;

    relationship: string;

    infoVideo: string;

    website?: string | null;

    email?: string | null;

    slug: string;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadCreatephonesInput | null;

    ambassadorComments?: MerchantLeadCreateambassadorCommentsInput | null;

    enroller: UserCreateOneWithoutMerchantLeadsInput;

    events?: EventCreateManyInput | null;

    application: MerchantApplicationCreateOneInput;

    owner?: UserCreateOneInput | null;
}

export interface UserCreateOneWithoutMerchantLeadsInput {
    create?: UserCreateWithoutMerchantLeadsInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutMerchantLeadsInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface UserCreateOneInput {
    create?: UserCreateInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantCreateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface MerchantDailyEstimateCreateManyWithoutMerchantInput {
    create?: MerchantDailyEstimateCreateWithoutMerchantInput[] | null;

    connect?: MerchantDailyEstimateWhereUniqueInput[] | null;
}

export interface MerchantDailyEstimateCreateWithoutMerchantInput {
    key: string;

    dateString: string;

    merchantVariableAmt: number;

    merchantFixedAmt: number;
}

export interface MerchantMonthlyCommissionCreateManyWithoutMerchantInput {
    create?: MerchantMonthlyCommissionCreateWithoutMerchantInput[] | null;

    connect?: MerchantMonthlyCommissionWhereUniqueInput[] | null;
}

export interface MerchantMonthlyCommissionCreateWithoutMerchantInput {
    key: string;

    monthString: string;

    amount: number;
}

export interface MerchantCommissionDepositCreateManyWithoutMerchantInput {
    create?: MerchantCommissionDepositCreateWithoutMerchantInput[] | null;

    connect?: MerchantCommissionDepositWhereUniqueInput[] | null;
}

export interface MerchantCommissionDepositCreateWithoutMerchantInput {
    dateString: string;

    amount: number;
}

export interface DraftMerchantCreateInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    website?: string | null;

    email?: string | null;

    infoVideo?: string | null;

    relationship?: string | null;

    phones?: DraftMerchantCreatephonesInput | null;

    author: UserCreateOneWithoutDraftMerchantsInput;
}

export interface UserCreateOneWithoutDraftMerchantsInput {
    create?: UserCreateWithoutDraftMerchantsInput | null;

    connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutDraftMerchantsInput {
    phone: string;

    firstName: string;

    lastName: string;

    email: string;

    smsCode?: string | null;

    userRoles?: RoleCreateManyInput | null;

    enroller?: UserCreateOneWithoutDownlineInput | null;

    downline?: UserCreateManyWithoutEnrollerInput | null;

    merchants?: MerchantCreateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadCreateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadCreateManyWithoutEnrollerInput | null;

    ownedBusinesses?: MerchantCreateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupCreateManyWithoutUserInput | null;
}

export interface MerchantLeadCreateInput {
    firstName: string;

    lastName: string;

    businessName: string;

    relationship: string;

    infoVideo: string;

    website?: string | null;

    email?: string | null;

    slug: string;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadCreatephonesInput | null;

    ambassadorComments?: MerchantLeadCreateambassadorCommentsInput | null;

    enroller: UserCreateOneWithoutMerchantLeadsInput;

    events?: EventCreateManyInput | null;

    application: MerchantApplicationCreateOneInput;

    followup?: MerchantLeadFollowupCreateOneWithoutLeadInput | null;

    owner?: UserCreateOneInput | null;
}

export interface MerchantLeadFollowupCreateInput {
    date: DateTime;

    appointment: boolean;

    lead: MerchantLeadCreateOneWithoutFollowupInput;

    user: UserCreateOneWithoutFollowupsInput;
}

export interface MerchantCreateInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner: UserCreateOneWithoutOwnedBusinessesInput;

    enroller: UserCreateOneWithoutMerchantsInput;

    dailyEstimates?: MerchantDailyEstimateCreateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionCreateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositCreateManyWithoutMerchantInput | null;
}

export interface MerchantDailyEstimateCreateInput {
    key: string;

    dateString: string;

    merchantVariableAmt: number;

    merchantFixedAmt: number;

    merchant: MerchantCreateOneWithoutDailyEstimatesInput;
}

export interface MerchantCreateOneWithoutDailyEstimatesInput {
    create?: MerchantCreateWithoutDailyEstimatesInput | null;

    connect?: MerchantWhereUniqueInput | null;
}

export interface MerchantCreateWithoutDailyEstimatesInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner: UserCreateOneWithoutOwnedBusinessesInput;

    enroller: UserCreateOneWithoutMerchantsInput;

    monthlyCommissions?: MerchantMonthlyCommissionCreateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositCreateManyWithoutMerchantInput | null;
}

export interface MerchantMonthlyCommissionCreateInput {
    key: string;

    monthString: string;

    amount: number;

    merchant: MerchantCreateOneWithoutMonthlyCommissionsInput;
}

export interface MerchantCreateOneWithoutMonthlyCommissionsInput {
    create?: MerchantCreateWithoutMonthlyCommissionsInput | null;

    connect?: MerchantWhereUniqueInput | null;
}

export interface MerchantCreateWithoutMonthlyCommissionsInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner: UserCreateOneWithoutOwnedBusinessesInput;

    enroller: UserCreateOneWithoutMerchantsInput;

    dailyEstimates?: MerchantDailyEstimateCreateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositCreateManyWithoutMerchantInput | null;
}

export interface MerchantCommissionDepositCreateInput {
    dateString: string;

    amount: number;

    merchant: MerchantCreateOneWithoutCommissionDepositsInput;
}

export interface MerchantCreateOneWithoutCommissionDepositsInput {
    create?: MerchantCreateWithoutCommissionDepositsInput | null;

    connect?: MerchantWhereUniqueInput | null;
}

export interface MerchantCreateWithoutCommissionDepositsInput {
    businessName: string;

    industry: string;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner: UserCreateOneWithoutOwnedBusinessesInput;

    enroller: UserCreateOneWithoutMerchantsInput;

    dailyEstimates?: MerchantDailyEstimateCreateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionCreateManyWithoutMerchantInput | null;
}

export interface AmbassadorLeadUpdateInput {
    status?: AmbassadorLeadStatus | null;

    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    slug?: string | null;

    upline?: UserUpdateOneWithoutAmbassadorLeadsInput | null;

    events?: EventUpdateManyInput | null;
}

export interface UserUpdateOneWithoutAmbassadorLeadsInput {
    create?: UserCreateWithoutAmbassadorLeadsInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutAmbassadorLeadsDataInput | null;

    upsert?: UserUpsertWithoutAmbassadorLeadsInput | null;
}

export interface UserUpdateWithoutAmbassadorLeadsDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface RoleUpdateManyInput {
    create?: RoleCreateInput[] | null;

    connect?: RoleWhereUniqueInput[] | null;

    disconnect?: RoleWhereUniqueInput[] | null;

    delete?: RoleWhereUniqueInput[] | null;

    update?: RoleUpdateWithWhereUniqueNestedInput[] | null;

    upsert?: RoleUpsertWithWhereUniqueNestedInput[] | null;
}

export interface RoleUpdateWithWhereUniqueNestedInput {
    where: RoleWhereUniqueInput;

    data: RoleUpdateDataInput;
}

export interface RoleUpdateDataInput {
    name?: UserRole | null;
}

export interface RoleUpsertWithWhereUniqueNestedInput {
    where: RoleWhereUniqueInput;

    update: RoleUpdateDataInput;

    create: RoleCreateInput;
}

export interface UserUpdateOneWithoutDownlineInput {
    create?: UserCreateWithoutDownlineInput | null;

    connect?: UserWhereUniqueInput | null;

    disconnect?: boolean | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutDownlineDataInput | null;

    upsert?: UserUpsertWithoutDownlineInput | null;
}

export interface UserUpdateWithoutDownlineDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface MerchantUpdateManyWithoutEnrollerInput {
    create?: MerchantCreateWithoutEnrollerInput[] | null;

    connect?: MerchantWhereUniqueInput[] | null;

    disconnect?: MerchantWhereUniqueInput[] | null;

    delete?: MerchantWhereUniqueInput[] | null;

    update?: MerchantUpdateWithWhereUniqueWithoutEnrollerInput[] | null;

    upsert?: MerchantUpsertWithWhereUniqueWithoutEnrollerInput[] | null;
}

export interface MerchantUpdateWithWhereUniqueWithoutEnrollerInput {
    where: MerchantWhereUniqueInput;

    data: MerchantUpdateWithoutEnrollerDataInput;
}

export interface MerchantUpdateWithoutEnrollerDataInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner?: UserUpdateOneWithoutOwnedBusinessesInput | null;

    dailyEstimates?: MerchantDailyEstimateUpdateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionUpdateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositUpdateManyWithoutMerchantInput | null;
}

export interface UserUpdateOneWithoutOwnedBusinessesInput {
    create?: UserCreateWithoutOwnedBusinessesInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutOwnedBusinessesDataInput | null;

    upsert?: UserUpsertWithoutOwnedBusinessesInput | null;
}

export interface UserUpdateWithoutOwnedBusinessesDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface UserUpdateManyWithoutEnrollerInput {
    create?: UserCreateWithoutEnrollerInput[] | null;

    connect?: UserWhereUniqueInput[] | null;

    disconnect?: UserWhereUniqueInput[] | null;

    delete?: UserWhereUniqueInput[] | null;

    update?: UserUpdateWithWhereUniqueWithoutEnrollerInput[] | null;

    upsert?: UserUpsertWithWhereUniqueWithoutEnrollerInput[] | null;
}

export interface UserUpdateWithWhereUniqueWithoutEnrollerInput {
    where: UserWhereUniqueInput;

    data: UserUpdateWithoutEnrollerDataInput;
}

export interface UserUpdateWithoutEnrollerDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface AmbassadorLeadUpdateManyWithoutUplineInput {
    create?: AmbassadorLeadCreateWithoutUplineInput[] | null;

    connect?: AmbassadorLeadWhereUniqueInput[] | null;

    disconnect?: AmbassadorLeadWhereUniqueInput[] | null;

    delete?: AmbassadorLeadWhereUniqueInput[] | null;

    update?: AmbassadorLeadUpdateWithWhereUniqueWithoutUplineInput[] | null;

    upsert?: AmbassadorLeadUpsertWithWhereUniqueWithoutUplineInput[] | null;
}

export interface AmbassadorLeadUpdateWithWhereUniqueWithoutUplineInput {
    where: AmbassadorLeadWhereUniqueInput;

    data: AmbassadorLeadUpdateWithoutUplineDataInput;
}

export interface AmbassadorLeadUpdateWithoutUplineDataInput {
    status?: AmbassadorLeadStatus | null;

    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    slug?: string | null;

    events?: EventUpdateManyInput | null;
}

export interface EventUpdateManyInput {
    create?: EventCreateInput[] | null;

    connect?: EventWhereUniqueInput[] | null;

    disconnect?: EventWhereUniqueInput[] | null;

    delete?: EventWhereUniqueInput[] | null;

    update?: EventUpdateWithWhereUniqueNestedInput[] | null;

    upsert?: EventUpsertWithWhereUniqueNestedInput[] | null;
}

export interface EventUpdateWithWhereUniqueNestedInput {
    where: EventWhereUniqueInput;

    data: EventUpdateDataInput;
}

export interface EventUpdateDataInput {
    type?: string | null;

    timestamp?: DateTime | null;
}

export interface EventUpsertWithWhereUniqueNestedInput {
    where: EventWhereUniqueInput;

    update: EventUpdateDataInput;

    create: EventCreateInput;
}

export interface AmbassadorLeadUpsertWithWhereUniqueWithoutUplineInput {
    where: AmbassadorLeadWhereUniqueInput;

    update: AmbassadorLeadUpdateWithoutUplineDataInput;

    create: AmbassadorLeadCreateWithoutUplineInput;
}

export interface MerchantLeadUpdateManyWithoutEnrollerInput {
    create?: MerchantLeadCreateWithoutEnrollerInput[] | null;

    connect?: MerchantLeadWhereUniqueInput[] | null;

    disconnect?: MerchantLeadWhereUniqueInput[] | null;

    delete?: MerchantLeadWhereUniqueInput[] | null;

    update?: MerchantLeadUpdateWithWhereUniqueWithoutEnrollerInput[] | null;

    upsert?: MerchantLeadUpsertWithWhereUniqueWithoutEnrollerInput[] | null;
}

export interface MerchantLeadUpdateWithWhereUniqueWithoutEnrollerInput {
    where: MerchantLeadWhereUniqueInput;

    data: MerchantLeadUpdateWithoutEnrollerDataInput;
}

export interface MerchantLeadUpdateWithoutEnrollerDataInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    relationship?: string | null;

    infoVideo?: string | null;

    website?: string | null;

    email?: string | null;

    slug?: string | null;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadUpdatephonesInput | null;

    ambassadorComments?: MerchantLeadUpdateambassadorCommentsInput | null;

    events?: EventUpdateManyInput | null;

    application?: MerchantApplicationUpdateOneInput | null;

    followup?: MerchantLeadFollowupUpdateOneWithoutLeadInput | null;

    owner?: UserUpdateOneInput | null;
}

export interface MerchantLeadUpdatephonesInput {
    set?: string[] | null;
}

export interface MerchantLeadUpdateambassadorCommentsInput {
    set?: string[] | null;
}

export interface MerchantApplicationUpdateOneInput {
    create?: MerchantApplicationCreateInput | null;

    connect?: MerchantApplicationWhereUniqueInput | null;

    delete?: boolean | null;

    update?: MerchantApplicationUpdateDataInput | null;

    upsert?: MerchantApplicationUpsertNestedInput | null;
}

export interface MerchantApplicationUpdateDataInput {
    lastUpdated?: DateTime | null;

    submitTimestamp?: DateTime | null;

    dba?: string | null;

    legal_name?: string | null;

    contact_name?: string | null;

    business_street?: string | null;

    business_city?: string | null;

    business_state?: string | null;

    business_zip?: string | null;

    business_phone?: string | null;

    email?: string | null;

    fax?: string | null;

    website?: string | null;

    business_type?: string | null;

    business_start_date?: string | null;

    business_state_formation?: string | null;

    business_tid?: string | null;

    name_on_check?: string | null;

    bank_name?: string | null;

    routing_number?: string | null;

    account_number?: string | null;

    card_present?: string | null;

    card_not_present?: string | null;

    products_services_sold?: string | null;

    return_policy?: string | null;

    average_ticket?: string | null;

    annual_volume?: string | null;

    process_ecommerce?: boolean | null;

    statements_available?: boolean | null;

    accept_amex?: string | null;

    amex_mid?: string | null;

    title?: string | null;

    first_name?: string | null;

    last_name?: string | null;

    ownership_percentage?: string | null;

    total_owners?: string | null;

    owner_street?: string | null;

    owner_city?: string | null;

    owner_state?: string | null;

    owner_zip?: string | null;

    owner_home?: string | null;

    ssn?: string | null;

    dob?: string | null;

    owner2_first_name?: string | null;

    owner2_last_name?: string | null;

    owner2_title?: string | null;

    owner2_ownership_percentage?: string | null;

    owner2_phone?: string | null;

    owner2_ssn?: string | null;

    owner2_dob?: string | null;

    owner2_street?: string | null;

    owner2_city?: string | null;

    owner2_state?: string | null;

    owner2_zip?: string | null;

    owner3_first_name?: string | null;

    owner3_last_name?: string | null;

    owner3_title?: string | null;

    owner3_ownership_percentage?: string | null;

    owner3_phone?: string | null;

    owner3_ssn?: string | null;

    owner3_dob?: string | null;

    owner3_street?: string | null;

    owner3_city?: string | null;

    owner3_state?: string | null;

    owner3_zip?: string | null;

    owner4_first_name?: string | null;

    owner4_last_name?: string | null;

    owner4_title?: string | null;

    owner4_ownership_percentage?: string | null;

    owner4_phone?: string | null;

    owner4_ssn?: string | null;

    owner4_dob?: string | null;

    owner4_street?: string | null;

    owner4_city?: string | null;

    owner4_state?: string | null;

    owner4_zip?: string | null;

    location_type?: string | null;

    signature?: string | null;

    signature_data?: string | null;

    pricing?: string | null;

    commission?: number | null;

    prepaidMonths?: number | null;

    showErrors?: boolean | null;

    submitted?: boolean | null;

    hasBankInfo?: boolean | null;

    notes?: string | null;

    agree?: boolean | null;
}

export interface MerchantApplicationUpsertNestedInput {
    update: MerchantApplicationUpdateDataInput;

    create: MerchantApplicationCreateInput;
}

export interface MerchantLeadFollowupUpdateOneWithoutLeadInput {
    create?: MerchantLeadFollowupCreateWithoutLeadInput | null;

    connect?: MerchantLeadFollowupWhereUniqueInput | null;

    disconnect?: boolean | null;

    delete?: boolean | null;

    update?: MerchantLeadFollowupUpdateWithoutLeadDataInput | null;

    upsert?: MerchantLeadFollowupUpsertWithoutLeadInput | null;
}

export interface MerchantLeadFollowupUpdateWithoutLeadDataInput {
    date?: DateTime | null;

    appointment?: boolean | null;

    user?: UserUpdateOneWithoutFollowupsInput | null;
}

export interface UserUpdateOneWithoutFollowupsInput {
    create?: UserCreateWithoutFollowupsInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutFollowupsDataInput | null;

    upsert?: UserUpsertWithoutFollowupsInput | null;
}

export interface UserUpdateWithoutFollowupsDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;
}

export interface DraftMerchantUpdateManyWithoutAuthorInput {
    create?: DraftMerchantCreateWithoutAuthorInput[] | null;

    connect?: DraftMerchantWhereUniqueInput[] | null;

    disconnect?: DraftMerchantWhereUniqueInput[] | null;

    delete?: DraftMerchantWhereUniqueInput[] | null;

    update?: DraftMerchantUpdateWithWhereUniqueWithoutAuthorInput[] | null;

    upsert?: DraftMerchantUpsertWithWhereUniqueWithoutAuthorInput[] | null;
}

export interface DraftMerchantUpdateWithWhereUniqueWithoutAuthorInput {
    where: DraftMerchantWhereUniqueInput;

    data: DraftMerchantUpdateWithoutAuthorDataInput;
}

export interface DraftMerchantUpdateWithoutAuthorDataInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    website?: string | null;

    email?: string | null;

    infoVideo?: string | null;

    relationship?: string | null;

    phones?: DraftMerchantUpdatephonesInput | null;
}

export interface DraftMerchantUpdatephonesInput {
    set?: string[] | null;
}

export interface DraftMerchantUpsertWithWhereUniqueWithoutAuthorInput {
    where: DraftMerchantWhereUniqueInput;

    update: DraftMerchantUpdateWithoutAuthorDataInput;

    create: DraftMerchantCreateWithoutAuthorInput;
}

export interface MerchantUpdateManyWithoutOwnerInput {
    create?: MerchantCreateWithoutOwnerInput[] | null;

    connect?: MerchantWhereUniqueInput[] | null;

    disconnect?: MerchantWhereUniqueInput[] | null;

    delete?: MerchantWhereUniqueInput[] | null;

    update?: MerchantUpdateWithWhereUniqueWithoutOwnerInput[] | null;

    upsert?: MerchantUpsertWithWhereUniqueWithoutOwnerInput[] | null;
}

export interface MerchantUpdateWithWhereUniqueWithoutOwnerInput {
    where: MerchantWhereUniqueInput;

    data: MerchantUpdateWithoutOwnerDataInput;
}

export interface MerchantUpdateWithoutOwnerDataInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    enroller?: UserUpdateOneWithoutMerchantsInput | null;

    dailyEstimates?: MerchantDailyEstimateUpdateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionUpdateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositUpdateManyWithoutMerchantInput | null;
}

export interface UserUpdateOneWithoutMerchantsInput {
    create?: UserCreateWithoutMerchantsInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutMerchantsDataInput | null;

    upsert?: UserUpsertWithoutMerchantsInput | null;
}

export interface UserUpdateWithoutMerchantsDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface MerchantLeadFollowupUpdateManyWithoutUserInput {
    create?: MerchantLeadFollowupCreateWithoutUserInput[] | null;

    connect?: MerchantLeadFollowupWhereUniqueInput[] | null;

    disconnect?: MerchantLeadFollowupWhereUniqueInput[] | null;

    delete?: MerchantLeadFollowupWhereUniqueInput[] | null;

    update?: MerchantLeadFollowupUpdateWithWhereUniqueWithoutUserInput[] | null;

    upsert?: MerchantLeadFollowupUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface MerchantLeadFollowupUpdateWithWhereUniqueWithoutUserInput {
    where: MerchantLeadFollowupWhereUniqueInput;

    data: MerchantLeadFollowupUpdateWithoutUserDataInput;
}

export interface MerchantLeadFollowupUpdateWithoutUserDataInput {
    date?: DateTime | null;

    appointment?: boolean | null;

    lead?: MerchantLeadUpdateOneWithoutFollowupInput | null;
}

export interface MerchantLeadUpdateOneWithoutFollowupInput {
    create?: MerchantLeadCreateWithoutFollowupInput | null;

    connect?: MerchantLeadWhereUniqueInput | null;

    delete?: boolean | null;

    update?: MerchantLeadUpdateWithoutFollowupDataInput | null;

    upsert?: MerchantLeadUpsertWithoutFollowupInput | null;
}

export interface MerchantLeadUpdateWithoutFollowupDataInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    relationship?: string | null;

    infoVideo?: string | null;

    website?: string | null;

    email?: string | null;

    slug?: string | null;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadUpdatephonesInput | null;

    ambassadorComments?: MerchantLeadUpdateambassadorCommentsInput | null;

    enroller?: UserUpdateOneWithoutMerchantLeadsInput | null;

    events?: EventUpdateManyInput | null;

    application?: MerchantApplicationUpdateOneInput | null;

    owner?: UserUpdateOneInput | null;
}

export interface UserUpdateOneWithoutMerchantLeadsInput {
    create?: UserCreateWithoutMerchantLeadsInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutMerchantLeadsDataInput | null;

    upsert?: UserUpsertWithoutMerchantLeadsInput | null;
}

export interface UserUpdateWithoutMerchantLeadsDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface UserUpsertWithoutMerchantLeadsInput {
    update: UserUpdateWithoutMerchantLeadsDataInput;

    create: UserCreateWithoutMerchantLeadsInput;
}

export interface UserUpdateOneInput {
    create?: UserCreateInput | null;

    connect?: UserWhereUniqueInput | null;

    disconnect?: boolean | null;

    delete?: boolean | null;

    update?: UserUpdateDataInput | null;

    upsert?: UserUpsertNestedInput | null;
}

export interface UserUpdateDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface UserUpsertNestedInput {
    update: UserUpdateDataInput;

    create: UserCreateInput;
}

export interface MerchantLeadUpsertWithoutFollowupInput {
    update: MerchantLeadUpdateWithoutFollowupDataInput;

    create: MerchantLeadCreateWithoutFollowupInput;
}

export interface MerchantLeadFollowupUpsertWithWhereUniqueWithoutUserInput {
    where: MerchantLeadFollowupWhereUniqueInput;

    update: MerchantLeadFollowupUpdateWithoutUserDataInput;

    create: MerchantLeadFollowupCreateWithoutUserInput;
}

export interface UserUpsertWithoutMerchantsInput {
    update: UserUpdateWithoutMerchantsDataInput;

    create: UserCreateWithoutMerchantsInput;
}

export interface MerchantDailyEstimateUpdateManyWithoutMerchantInput {
    create?: MerchantDailyEstimateCreateWithoutMerchantInput[] | null;

    connect?: MerchantDailyEstimateWhereUniqueInput[] | null;

    disconnect?: MerchantDailyEstimateWhereUniqueInput[] | null;

    delete?: MerchantDailyEstimateWhereUniqueInput[] | null;

    update?:
        | MerchantDailyEstimateUpdateWithWhereUniqueWithoutMerchantInput[]
        | null;

    upsert?:
        | MerchantDailyEstimateUpsertWithWhereUniqueWithoutMerchantInput[]
        | null;
}

export interface MerchantDailyEstimateUpdateWithWhereUniqueWithoutMerchantInput {
    where: MerchantDailyEstimateWhereUniqueInput;

    data: MerchantDailyEstimateUpdateWithoutMerchantDataInput;
}

export interface MerchantDailyEstimateUpdateWithoutMerchantDataInput {
    key?: string | null;

    dateString?: string | null;

    merchantVariableAmt?: number | null;

    merchantFixedAmt?: number | null;
}

export interface MerchantDailyEstimateUpsertWithWhereUniqueWithoutMerchantInput {
    where: MerchantDailyEstimateWhereUniqueInput;

    update: MerchantDailyEstimateUpdateWithoutMerchantDataInput;

    create: MerchantDailyEstimateCreateWithoutMerchantInput;
}

export interface MerchantMonthlyCommissionUpdateManyWithoutMerchantInput {
    create?: MerchantMonthlyCommissionCreateWithoutMerchantInput[] | null;

    connect?: MerchantMonthlyCommissionWhereUniqueInput[] | null;

    disconnect?: MerchantMonthlyCommissionWhereUniqueInput[] | null;

    delete?: MerchantMonthlyCommissionWhereUniqueInput[] | null;

    update?:
        | MerchantMonthlyCommissionUpdateWithWhereUniqueWithoutMerchantInput[]
        | null;

    upsert?:
        | MerchantMonthlyCommissionUpsertWithWhereUniqueWithoutMerchantInput[]
        | null;
}

export interface MerchantMonthlyCommissionUpdateWithWhereUniqueWithoutMerchantInput {
    where: MerchantMonthlyCommissionWhereUniqueInput;

    data: MerchantMonthlyCommissionUpdateWithoutMerchantDataInput;
}

export interface MerchantMonthlyCommissionUpdateWithoutMerchantDataInput {
    key?: string | null;

    monthString?: string | null;

    amount?: number | null;
}

export interface MerchantMonthlyCommissionUpsertWithWhereUniqueWithoutMerchantInput {
    where: MerchantMonthlyCommissionWhereUniqueInput;

    update: MerchantMonthlyCommissionUpdateWithoutMerchantDataInput;

    create: MerchantMonthlyCommissionCreateWithoutMerchantInput;
}

export interface MerchantCommissionDepositUpdateManyWithoutMerchantInput {
    create?: MerchantCommissionDepositCreateWithoutMerchantInput[] | null;

    connect?: MerchantCommissionDepositWhereUniqueInput[] | null;

    disconnect?: MerchantCommissionDepositWhereUniqueInput[] | null;

    delete?: MerchantCommissionDepositWhereUniqueInput[] | null;

    update?:
        | MerchantCommissionDepositUpdateWithWhereUniqueWithoutMerchantInput[]
        | null;

    upsert?:
        | MerchantCommissionDepositUpsertWithWhereUniqueWithoutMerchantInput[]
        | null;
}

export interface MerchantCommissionDepositUpdateWithWhereUniqueWithoutMerchantInput {
    where: MerchantCommissionDepositWhereUniqueInput;

    data: MerchantCommissionDepositUpdateWithoutMerchantDataInput;
}

export interface MerchantCommissionDepositUpdateWithoutMerchantDataInput {
    dateString?: string | null;

    amount?: number | null;
}

export interface MerchantCommissionDepositUpsertWithWhereUniqueWithoutMerchantInput {
    where: MerchantCommissionDepositWhereUniqueInput;

    update: MerchantCommissionDepositUpdateWithoutMerchantDataInput;

    create: MerchantCommissionDepositCreateWithoutMerchantInput;
}

export interface MerchantUpsertWithWhereUniqueWithoutOwnerInput {
    where: MerchantWhereUniqueInput;

    update: MerchantUpdateWithoutOwnerDataInput;

    create: MerchantCreateWithoutOwnerInput;
}

export interface UserUpsertWithoutFollowupsInput {
    update: UserUpdateWithoutFollowupsDataInput;

    create: UserCreateWithoutFollowupsInput;
}

export interface MerchantLeadFollowupUpsertWithoutLeadInput {
    update: MerchantLeadFollowupUpdateWithoutLeadDataInput;

    create: MerchantLeadFollowupCreateWithoutLeadInput;
}

export interface MerchantLeadUpsertWithWhereUniqueWithoutEnrollerInput {
    where: MerchantLeadWhereUniqueInput;

    update: MerchantLeadUpdateWithoutEnrollerDataInput;

    create: MerchantLeadCreateWithoutEnrollerInput;
}

export interface UserUpsertWithWhereUniqueWithoutEnrollerInput {
    where: UserWhereUniqueInput;

    update: UserUpdateWithoutEnrollerDataInput;

    create: UserCreateWithoutEnrollerInput;
}

export interface UserUpsertWithoutOwnedBusinessesInput {
    update: UserUpdateWithoutOwnedBusinessesDataInput;

    create: UserCreateWithoutOwnedBusinessesInput;
}

export interface MerchantUpsertWithWhereUniqueWithoutEnrollerInput {
    where: MerchantWhereUniqueInput;

    update: MerchantUpdateWithoutEnrollerDataInput;

    create: MerchantCreateWithoutEnrollerInput;
}

export interface UserUpsertWithoutDownlineInput {
    update: UserUpdateWithoutDownlineDataInput;

    create: UserCreateWithoutDownlineInput;
}

export interface UserUpsertWithoutAmbassadorLeadsInput {
    update: UserUpdateWithoutAmbassadorLeadsDataInput;

    create: UserCreateWithoutAmbassadorLeadsInput;
}

export interface DraftMerchantUpdateInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    website?: string | null;

    email?: string | null;

    infoVideo?: string | null;

    relationship?: string | null;

    phones?: DraftMerchantUpdatephonesInput | null;

    author?: UserUpdateOneWithoutDraftMerchantsInput | null;
}

export interface UserUpdateOneWithoutDraftMerchantsInput {
    create?: UserCreateWithoutDraftMerchantsInput | null;

    connect?: UserWhereUniqueInput | null;

    delete?: boolean | null;

    update?: UserUpdateWithoutDraftMerchantsDataInput | null;

    upsert?: UserUpsertWithoutDraftMerchantsInput | null;
}

export interface UserUpdateWithoutDraftMerchantsDataInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface UserUpsertWithoutDraftMerchantsInput {
    update: UserUpdateWithoutDraftMerchantsDataInput;

    create: UserCreateWithoutDraftMerchantsInput;
}

export interface MerchantLeadUpdateInput {
    firstName?: string | null;

    lastName?: string | null;

    businessName?: string | null;

    relationship?: string | null;

    infoVideo?: string | null;

    website?: string | null;

    email?: string | null;

    slug?: string | null;

    status?: MerchantLeadStatus | null;

    notes?: string | null;

    leadScore?: number | null;

    merchantNumber?: string | null;

    bid?: string | null;

    applicationStatus?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    search?: string | null;

    equipmentOnboardingNotes?: string | null;

    pricingCommissionEstimateNotes?: string | null;

    howTheyTakePaymentsNotes?: string | null;

    pendingQuestionsNotes?: string | null;

    additionalContactInfoNotes?: string | null;

    frontingCommissionAmount?: string | null;

    phones?: MerchantLeadUpdatephonesInput | null;

    ambassadorComments?: MerchantLeadUpdateambassadorCommentsInput | null;

    enroller?: UserUpdateOneWithoutMerchantLeadsInput | null;

    events?: EventUpdateManyInput | null;

    application?: MerchantApplicationUpdateOneInput | null;

    followup?: MerchantLeadFollowupUpdateOneWithoutLeadInput | null;

    owner?: UserUpdateOneInput | null;
}

export interface MerchantLeadFollowupUpdateInput {
    date?: DateTime | null;

    appointment?: boolean | null;

    lead?: MerchantLeadUpdateOneWithoutFollowupInput | null;

    user?: UserUpdateOneWithoutFollowupsInput | null;
}

export interface MerchantUpdateInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner?: UserUpdateOneWithoutOwnedBusinessesInput | null;

    enroller?: UserUpdateOneWithoutMerchantsInput | null;

    dailyEstimates?: MerchantDailyEstimateUpdateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionUpdateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositUpdateManyWithoutMerchantInput | null;
}

export interface MerchantDailyEstimateUpdateInput {
    key?: string | null;

    dateString?: string | null;

    merchantVariableAmt?: number | null;

    merchantFixedAmt?: number | null;

    merchant?: MerchantUpdateOneWithoutDailyEstimatesInput | null;
}

export interface MerchantUpdateOneWithoutDailyEstimatesInput {
    create?: MerchantCreateWithoutDailyEstimatesInput | null;

    connect?: MerchantWhereUniqueInput | null;

    delete?: boolean | null;

    update?: MerchantUpdateWithoutDailyEstimatesDataInput | null;

    upsert?: MerchantUpsertWithoutDailyEstimatesInput | null;
}

export interface MerchantUpdateWithoutDailyEstimatesDataInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner?: UserUpdateOneWithoutOwnedBusinessesInput | null;

    enroller?: UserUpdateOneWithoutMerchantsInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionUpdateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositUpdateManyWithoutMerchantInput | null;
}

export interface MerchantUpsertWithoutDailyEstimatesInput {
    update: MerchantUpdateWithoutDailyEstimatesDataInput;

    create: MerchantCreateWithoutDailyEstimatesInput;
}

export interface MerchantMonthlyCommissionUpdateInput {
    key?: string | null;

    monthString?: string | null;

    amount?: number | null;

    merchant?: MerchantUpdateOneWithoutMonthlyCommissionsInput | null;
}

export interface MerchantUpdateOneWithoutMonthlyCommissionsInput {
    create?: MerchantCreateWithoutMonthlyCommissionsInput | null;

    connect?: MerchantWhereUniqueInput | null;

    delete?: boolean | null;

    update?: MerchantUpdateWithoutMonthlyCommissionsDataInput | null;

    upsert?: MerchantUpsertWithoutMonthlyCommissionsInput | null;
}

export interface MerchantUpdateWithoutMonthlyCommissionsDataInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner?: UserUpdateOneWithoutOwnedBusinessesInput | null;

    enroller?: UserUpdateOneWithoutMerchantsInput | null;

    dailyEstimates?: MerchantDailyEstimateUpdateManyWithoutMerchantInput | null;

    commissionDeposits?: MerchantCommissionDepositUpdateManyWithoutMerchantInput | null;
}

export interface MerchantUpsertWithoutMonthlyCommissionsInput {
    update: MerchantUpdateWithoutMonthlyCommissionsDataInput;

    create: MerchantCreateWithoutMonthlyCommissionsInput;
}

export interface MerchantCommissionDepositUpdateInput {
    dateString?: string | null;

    amount?: number | null;

    merchant?: MerchantUpdateOneWithoutCommissionDepositsInput | null;
}

export interface MerchantUpdateOneWithoutCommissionDepositsInput {
    create?: MerchantCreateWithoutCommissionDepositsInput | null;

    connect?: MerchantWhereUniqueInput | null;

    delete?: boolean | null;

    update?: MerchantUpdateWithoutCommissionDepositsDataInput | null;

    upsert?: MerchantUpsertWithoutCommissionDepositsInput | null;
}

export interface MerchantUpdateWithoutCommissionDepositsDataInput {
    businessName?: string | null;

    industry?: string | null;

    merchantNumber?: string | null;

    bid?: string | null;

    visaBP?: number | null;

    visaCents?: number | null;

    mastercardBP?: number | null;

    mastercardCents?: number | null;

    discoverBP?: number | null;

    discoverCents?: number | null;

    amexBP?: number | null;

    amexCents?: number | null;

    monthlyFees?: number | null;

    monthlyFixedExpenses?: number | null;

    expressMerchantFunding?: boolean | null;

    owner?: UserUpdateOneWithoutOwnedBusinessesInput | null;

    enroller?: UserUpdateOneWithoutMerchantsInput | null;

    dailyEstimates?: MerchantDailyEstimateUpdateManyWithoutMerchantInput | null;

    monthlyCommissions?: MerchantMonthlyCommissionUpdateManyWithoutMerchantInput | null;
}

export interface MerchantUpsertWithoutCommissionDepositsInput {
    update: MerchantUpdateWithoutCommissionDepositsDataInput;

    create: MerchantCreateWithoutCommissionDepositsInput;
}

export interface UserUpdateInput {
    phone?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    smsCode?: string | null;

    userRoles?: RoleUpdateManyInput | null;

    enroller?: UserUpdateOneWithoutDownlineInput | null;

    downline?: UserUpdateManyWithoutEnrollerInput | null;

    merchants?: MerchantUpdateManyWithoutEnrollerInput | null;

    ambassadorLeads?: AmbassadorLeadUpdateManyWithoutUplineInput | null;

    merchantLeads?: MerchantLeadUpdateManyWithoutEnrollerInput | null;

    draftMerchants?: DraftMerchantUpdateManyWithoutAuthorInput | null;

    ownedBusinesses?: MerchantUpdateManyWithoutOwnerInput | null;

    followups?: MerchantLeadFollowupUpdateManyWithoutUserInput | null;
}

export interface RoleUpdateInput {
    name?: UserRole | null;
}

export interface MerchantApplicationUpdateInput {
    lastUpdated?: DateTime | null;

    submitTimestamp?: DateTime | null;

    dba?: string | null;

    legal_name?: string | null;

    contact_name?: string | null;

    business_street?: string | null;

    business_city?: string | null;

    business_state?: string | null;

    business_zip?: string | null;

    business_phone?: string | null;

    email?: string | null;

    fax?: string | null;

    website?: string | null;

    business_type?: string | null;

    business_start_date?: string | null;

    business_state_formation?: string | null;

    business_tid?: string | null;

    name_on_check?: string | null;

    bank_name?: string | null;

    routing_number?: string | null;

    account_number?: string | null;

    card_present?: string | null;

    card_not_present?: string | null;

    products_services_sold?: string | null;

    return_policy?: string | null;

    average_ticket?: string | null;

    annual_volume?: string | null;

    process_ecommerce?: boolean | null;

    statements_available?: boolean | null;

    accept_amex?: string | null;

    amex_mid?: string | null;

    title?: string | null;

    first_name?: string | null;

    last_name?: string | null;

    ownership_percentage?: string | null;

    total_owners?: string | null;

    owner_street?: string | null;

    owner_city?: string | null;

    owner_state?: string | null;

    owner_zip?: string | null;

    owner_home?: string | null;

    ssn?: string | null;

    dob?: string | null;

    owner2_first_name?: string | null;

    owner2_last_name?: string | null;

    owner2_title?: string | null;

    owner2_ownership_percentage?: string | null;

    owner2_phone?: string | null;

    owner2_ssn?: string | null;

    owner2_dob?: string | null;

    owner2_street?: string | null;

    owner2_city?: string | null;

    owner2_state?: string | null;

    owner2_zip?: string | null;

    owner3_first_name?: string | null;

    owner3_last_name?: string | null;

    owner3_title?: string | null;

    owner3_ownership_percentage?: string | null;

    owner3_phone?: string | null;

    owner3_ssn?: string | null;

    owner3_dob?: string | null;

    owner3_street?: string | null;

    owner3_city?: string | null;

    owner3_state?: string | null;

    owner3_zip?: string | null;

    owner4_first_name?: string | null;

    owner4_last_name?: string | null;

    owner4_title?: string | null;

    owner4_ownership_percentage?: string | null;

    owner4_phone?: string | null;

    owner4_ssn?: string | null;

    owner4_dob?: string | null;

    owner4_street?: string | null;

    owner4_city?: string | null;

    owner4_state?: string | null;

    owner4_zip?: string | null;

    location_type?: string | null;

    signature?: string | null;

    signature_data?: string | null;

    pricing?: string | null;

    commission?: number | null;

    prepaidMonths?: number | null;

    showErrors?: boolean | null;

    submitted?: boolean | null;

    hasBankInfo?: boolean | null;

    notes?: string | null;

    agree?: boolean | null;
}

export interface EventUpdateInput {
    type?: string | null;

    timestamp?: DateTime | null;
}

export interface AmbassadorLeadSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: AmbassadorLeadSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: AmbassadorLeadSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: AmbassadorLeadSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: AmbassadorLeadWhereInput | null;
}

export interface DraftMerchantSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: DraftMerchantSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: DraftMerchantSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: DraftMerchantSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: DraftMerchantWhereInput | null;
}

export interface MerchantLeadSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantLeadSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantLeadSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantLeadSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantLeadWhereInput | null;
}

export interface MerchantLeadFollowupSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantLeadFollowupSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantLeadFollowupSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantLeadFollowupSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantLeadFollowupWhereInput | null;
}

export interface MerchantSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantWhereInput | null;
}

export interface MerchantDailyEstimateSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantDailyEstimateSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantDailyEstimateSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantDailyEstimateSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantDailyEstimateWhereInput | null;
}

export interface MerchantMonthlyCommissionSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantMonthlyCommissionSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantMonthlyCommissionSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantMonthlyCommissionSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantMonthlyCommissionWhereInput | null;
}

export interface MerchantCommissionDepositSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantCommissionDepositSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantCommissionDepositSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantCommissionDepositSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantCommissionDepositWhereInput | null;
}

export interface UserSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: UserSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: UserSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: UserSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: UserWhereInput | null;
}

export interface RoleSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: RoleSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: RoleSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: RoleSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: RoleWhereInput | null;
}

export interface MerchantApplicationSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: MerchantApplicationSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: MerchantApplicationSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: MerchantApplicationSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: MerchantApplicationWhereInput | null;
}

export interface EventSubscriptionWhereInput {
    /** Logical AND on all given filters. */
    AND?: EventSubscriptionWhereInput[] | null;
    /** Logical OR on all given filters. */
    OR?: EventSubscriptionWhereInput[] | null;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: EventSubscriptionWhereInput[] | null;
    /** The subscription event gets dispatched when it's listed in mutation_in */
    mutation_in?: MutationType[] | null;
    /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
    updatedFields_contains?: string | null;
    /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
    updatedFields_contains_every?: string[] | null;
    /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
    updatedFields_contains_some?: string[] | null;

    node?: EventWhereInput | null;
}

export enum AmbassadorLeadStatus {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Denied = 'DENIED',
}

export enum UserRole {
    Any = 'ANY',
    User = 'USER',
    Ambassador = 'AMBASSADOR',
    Merchant = 'MERCHANT',
    Admin = 'ADMIN',
    Dev = 'DEV',
}

export enum MerchantLeadStatus {
    Working = 'WORKING',
    Applied = 'APPLIED',
    Boarding = 'BOARDING',
    Boarded = 'BOARDED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Denied = 'DENIED',
    Invalid = 'INVALID',
    Niatt = 'NIATT',
    Submitted = 'SUBMITTED',
    Marinating = 'MARINATING',
    NeedContact = 'NEED_CONTACT',
    NeedStatement = 'NEED_STATEMENT',
    NeedSignature = 'NEED_SIGNATURE',
    NeedApproval = 'NEED_APPROVAL',
    NeedBoarding = 'NEED_BOARDING',
    NeedVolume = 'NEED_VOLUME',
    Active = 'ACTIVE',
}

export enum AmbassadorLeadOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    FirstNameAsc = 'firstName_ASC',
    FirstNameDesc = 'firstName_DESC',
    LastNameAsc = 'lastName_ASC',
    LastNameDesc = 'lastName_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    SlugAsc = 'slug_ASC',
    SlugDesc = 'slug_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum RoleOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum UserOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    FirstNameAsc = 'firstName_ASC',
    FirstNameDesc = 'firstName_DESC',
    LastNameAsc = 'lastName_ASC',
    LastNameDesc = 'lastName_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    SmsCodeAsc = 'smsCode_ASC',
    SmsCodeDesc = 'smsCode_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    BusinessNameAsc = 'businessName_ASC',
    BusinessNameDesc = 'businessName_DESC',
    IndustryAsc = 'industry_ASC',
    IndustryDesc = 'industry_DESC',
    MerchantNumberAsc = 'merchantNumber_ASC',
    MerchantNumberDesc = 'merchantNumber_DESC',
    BidAsc = 'bid_ASC',
    BidDesc = 'bid_DESC',
    VisaBpAsc = 'visaBP_ASC',
    VisaBpDesc = 'visaBP_DESC',
    VisaCentsAsc = 'visaCents_ASC',
    VisaCentsDesc = 'visaCents_DESC',
    MastercardBpAsc = 'mastercardBP_ASC',
    MastercardBpDesc = 'mastercardBP_DESC',
    MastercardCentsAsc = 'mastercardCents_ASC',
    MastercardCentsDesc = 'mastercardCents_DESC',
    DiscoverBpAsc = 'discoverBP_ASC',
    DiscoverBpDesc = 'discoverBP_DESC',
    DiscoverCentsAsc = 'discoverCents_ASC',
    DiscoverCentsDesc = 'discoverCents_DESC',
    AmexBpAsc = 'amexBP_ASC',
    AmexBpDesc = 'amexBP_DESC',
    AmexCentsAsc = 'amexCents_ASC',
    AmexCentsDesc = 'amexCents_DESC',
    MonthlyFeesAsc = 'monthlyFees_ASC',
    MonthlyFeesDesc = 'monthlyFees_DESC',
    MonthlyFixedExpensesAsc = 'monthlyFixedExpenses_ASC',
    MonthlyFixedExpensesDesc = 'monthlyFixedExpenses_DESC',
    ExpressMerchantFundingAsc = 'expressMerchantFunding_ASC',
    ExpressMerchantFundingDesc = 'expressMerchantFunding_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantDailyEstimateOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    KeyAsc = 'key_ASC',
    KeyDesc = 'key_DESC',
    DateStringAsc = 'dateString_ASC',
    DateStringDesc = 'dateString_DESC',
    MerchantVariableAmtAsc = 'merchantVariableAmt_ASC',
    MerchantVariableAmtDesc = 'merchantVariableAmt_DESC',
    MerchantFixedAmtAsc = 'merchantFixedAmt_ASC',
    MerchantFixedAmtDesc = 'merchantFixedAmt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantMonthlyCommissionOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    KeyAsc = 'key_ASC',
    KeyDesc = 'key_DESC',
    MonthStringAsc = 'monthString_ASC',
    MonthStringDesc = 'monthString_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantCommissionDepositOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    DateStringAsc = 'dateString_ASC',
    DateStringDesc = 'dateString_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantLeadOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    FirstNameAsc = 'firstName_ASC',
    FirstNameDesc = 'firstName_DESC',
    LastNameAsc = 'lastName_ASC',
    LastNameDesc = 'lastName_DESC',
    BusinessNameAsc = 'businessName_ASC',
    BusinessNameDesc = 'businessName_DESC',
    RelationshipAsc = 'relationship_ASC',
    RelationshipDesc = 'relationship_DESC',
    InfoVideoAsc = 'infoVideo_ASC',
    InfoVideoDesc = 'infoVideo_DESC',
    WebsiteAsc = 'website_ASC',
    WebsiteDesc = 'website_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    SlugAsc = 'slug_ASC',
    SlugDesc = 'slug_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    NotesAsc = 'notes_ASC',
    NotesDesc = 'notes_DESC',
    LeadScoreAsc = 'leadScore_ASC',
    LeadScoreDesc = 'leadScore_DESC',
    MerchantNumberAsc = 'merchantNumber_ASC',
    MerchantNumberDesc = 'merchantNumber_DESC',
    BidAsc = 'bid_ASC',
    BidDesc = 'bid_DESC',
    ApplicationStatusAsc = 'applicationStatus_ASC',
    ApplicationStatusDesc = 'applicationStatus_DESC',
    VisaBpAsc = 'visaBP_ASC',
    VisaBpDesc = 'visaBP_DESC',
    VisaCentsAsc = 'visaCents_ASC',
    VisaCentsDesc = 'visaCents_DESC',
    MastercardBpAsc = 'mastercardBP_ASC',
    MastercardBpDesc = 'mastercardBP_DESC',
    MastercardCentsAsc = 'mastercardCents_ASC',
    MastercardCentsDesc = 'mastercardCents_DESC',
    DiscoverBpAsc = 'discoverBP_ASC',
    DiscoverBpDesc = 'discoverBP_DESC',
    DiscoverCentsAsc = 'discoverCents_ASC',
    DiscoverCentsDesc = 'discoverCents_DESC',
    AmexBpAsc = 'amexBP_ASC',
    AmexBpDesc = 'amexBP_DESC',
    AmexCentsAsc = 'amexCents_ASC',
    AmexCentsDesc = 'amexCents_DESC',
    MonthlyFeesAsc = 'monthlyFees_ASC',
    MonthlyFeesDesc = 'monthlyFees_DESC',
    MonthlyFixedExpensesAsc = 'monthlyFixedExpenses_ASC',
    MonthlyFixedExpensesDesc = 'monthlyFixedExpenses_DESC',
    ExpressMerchantFundingAsc = 'expressMerchantFunding_ASC',
    ExpressMerchantFundingDesc = 'expressMerchantFunding_DESC',
    SearchAsc = 'search_ASC',
    SearchDesc = 'search_DESC',
    EquipmentOnboardingNotesAsc = 'equipmentOnboardingNotes_ASC',
    EquipmentOnboardingNotesDesc = 'equipmentOnboardingNotes_DESC',
    PricingCommissionEstimateNotesAsc = 'pricingCommissionEstimateNotes_ASC',
    PricingCommissionEstimateNotesDesc = 'pricingCommissionEstimateNotes_DESC',
    HowTheyTakePaymentsNotesAsc = 'howTheyTakePaymentsNotes_ASC',
    HowTheyTakePaymentsNotesDesc = 'howTheyTakePaymentsNotes_DESC',
    PendingQuestionsNotesAsc = 'pendingQuestionsNotes_ASC',
    PendingQuestionsNotesDesc = 'pendingQuestionsNotes_DESC',
    AdditionalContactInfoNotesAsc = 'additionalContactInfoNotes_ASC',
    AdditionalContactInfoNotesDesc = 'additionalContactInfoNotes_DESC',
    FrontingCommissionAmountAsc = 'frontingCommissionAmount_ASC',
    FrontingCommissionAmountDesc = 'frontingCommissionAmount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
}

export enum EventOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    TimestampAsc = 'timestamp_ASC',
    TimestampDesc = 'timestamp_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum DraftMerchantOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    FirstNameAsc = 'firstName_ASC',
    FirstNameDesc = 'firstName_DESC',
    LastNameAsc = 'lastName_ASC',
    LastNameDesc = 'lastName_DESC',
    BusinessNameAsc = 'businessName_ASC',
    BusinessNameDesc = 'businessName_DESC',
    WebsiteAsc = 'website_ASC',
    WebsiteDesc = 'website_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    InfoVideoAsc = 'infoVideo_ASC',
    InfoVideoDesc = 'infoVideo_DESC',
    RelationshipAsc = 'relationship_ASC',
    RelationshipDesc = 'relationship_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantLeadFollowupOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    AppointmentAsc = 'appointment_ASC',
    AppointmentDesc = 'appointment_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MerchantApplicationOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LastUpdatedAsc = 'lastUpdated_ASC',
    LastUpdatedDesc = 'lastUpdated_DESC',
    SubmitTimestampAsc = 'submitTimestamp_ASC',
    SubmitTimestampDesc = 'submitTimestamp_DESC',
    DbaAsc = 'dba_ASC',
    DbaDesc = 'dba_DESC',
    LegalNameAsc = 'legal_name_ASC',
    LegalNameDesc = 'legal_name_DESC',
    ContactNameAsc = 'contact_name_ASC',
    ContactNameDesc = 'contact_name_DESC',
    BusinessStreetAsc = 'business_street_ASC',
    BusinessStreetDesc = 'business_street_DESC',
    BusinessCityAsc = 'business_city_ASC',
    BusinessCityDesc = 'business_city_DESC',
    BusinessStateAsc = 'business_state_ASC',
    BusinessStateDesc = 'business_state_DESC',
    BusinessZipAsc = 'business_zip_ASC',
    BusinessZipDesc = 'business_zip_DESC',
    BusinessPhoneAsc = 'business_phone_ASC',
    BusinessPhoneDesc = 'business_phone_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    FaxAsc = 'fax_ASC',
    FaxDesc = 'fax_DESC',
    WebsiteAsc = 'website_ASC',
    WebsiteDesc = 'website_DESC',
    BusinessTypeAsc = 'business_type_ASC',
    BusinessTypeDesc = 'business_type_DESC',
    BusinessStartDateAsc = 'business_start_date_ASC',
    BusinessStartDateDesc = 'business_start_date_DESC',
    BusinessStateFormationAsc = 'business_state_formation_ASC',
    BusinessStateFormationDesc = 'business_state_formation_DESC',
    BusinessTidAsc = 'business_tid_ASC',
    BusinessTidDesc = 'business_tid_DESC',
    NameOnCheckAsc = 'name_on_check_ASC',
    NameOnCheckDesc = 'name_on_check_DESC',
    BankNameAsc = 'bank_name_ASC',
    BankNameDesc = 'bank_name_DESC',
    RoutingNumberAsc = 'routing_number_ASC',
    RoutingNumberDesc = 'routing_number_DESC',
    AccountNumberAsc = 'account_number_ASC',
    AccountNumberDesc = 'account_number_DESC',
    CardPresentAsc = 'card_present_ASC',
    CardPresentDesc = 'card_present_DESC',
    CardNotPresentAsc = 'card_not_present_ASC',
    CardNotPresentDesc = 'card_not_present_DESC',
    ProductsServicesSoldAsc = 'products_services_sold_ASC',
    ProductsServicesSoldDesc = 'products_services_sold_DESC',
    ReturnPolicyAsc = 'return_policy_ASC',
    ReturnPolicyDesc = 'return_policy_DESC',
    AverageTicketAsc = 'average_ticket_ASC',
    AverageTicketDesc = 'average_ticket_DESC',
    AnnualVolumeAsc = 'annual_volume_ASC',
    AnnualVolumeDesc = 'annual_volume_DESC',
    ProcessEcommerceAsc = 'process_ecommerce_ASC',
    ProcessEcommerceDesc = 'process_ecommerce_DESC',
    StatementsAvailableAsc = 'statements_available_ASC',
    StatementsAvailableDesc = 'statements_available_DESC',
    AcceptAmexAsc = 'accept_amex_ASC',
    AcceptAmexDesc = 'accept_amex_DESC',
    AmexMidAsc = 'amex_mid_ASC',
    AmexMidDesc = 'amex_mid_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    FirstNameAsc = 'first_name_ASC',
    FirstNameDesc = 'first_name_DESC',
    LastNameAsc = 'last_name_ASC',
    LastNameDesc = 'last_name_DESC',
    OwnershipPercentageAsc = 'ownership_percentage_ASC',
    OwnershipPercentageDesc = 'ownership_percentage_DESC',
    TotalOwnersAsc = 'total_owners_ASC',
    TotalOwnersDesc = 'total_owners_DESC',
    OwnerStreetAsc = 'owner_street_ASC',
    OwnerStreetDesc = 'owner_street_DESC',
    OwnerCityAsc = 'owner_city_ASC',
    OwnerCityDesc = 'owner_city_DESC',
    OwnerStateAsc = 'owner_state_ASC',
    OwnerStateDesc = 'owner_state_DESC',
    OwnerZipAsc = 'owner_zip_ASC',
    OwnerZipDesc = 'owner_zip_DESC',
    OwnerHomeAsc = 'owner_home_ASC',
    OwnerHomeDesc = 'owner_home_DESC',
    SsnAsc = 'ssn_ASC',
    SsnDesc = 'ssn_DESC',
    DobAsc = 'dob_ASC',
    DobDesc = 'dob_DESC',
    Owner2FirstNameAsc = 'owner2_first_name_ASC',
    Owner2FirstNameDesc = 'owner2_first_name_DESC',
    Owner2LastNameAsc = 'owner2_last_name_ASC',
    Owner2LastNameDesc = 'owner2_last_name_DESC',
    Owner2TitleAsc = 'owner2_title_ASC',
    Owner2TitleDesc = 'owner2_title_DESC',
    Owner2OwnershipPercentageAsc = 'owner2_ownership_percentage_ASC',
    Owner2OwnershipPercentageDesc = 'owner2_ownership_percentage_DESC',
    Owner2PhoneAsc = 'owner2_phone_ASC',
    Owner2PhoneDesc = 'owner2_phone_DESC',
    Owner2SsnAsc = 'owner2_ssn_ASC',
    Owner2SsnDesc = 'owner2_ssn_DESC',
    Owner2DobAsc = 'owner2_dob_ASC',
    Owner2DobDesc = 'owner2_dob_DESC',
    Owner2StreetAsc = 'owner2_street_ASC',
    Owner2StreetDesc = 'owner2_street_DESC',
    Owner2CityAsc = 'owner2_city_ASC',
    Owner2CityDesc = 'owner2_city_DESC',
    Owner2StateAsc = 'owner2_state_ASC',
    Owner2StateDesc = 'owner2_state_DESC',
    Owner2ZipAsc = 'owner2_zip_ASC',
    Owner2ZipDesc = 'owner2_zip_DESC',
    Owner3FirstNameAsc = 'owner3_first_name_ASC',
    Owner3FirstNameDesc = 'owner3_first_name_DESC',
    Owner3LastNameAsc = 'owner3_last_name_ASC',
    Owner3LastNameDesc = 'owner3_last_name_DESC',
    Owner3TitleAsc = 'owner3_title_ASC',
    Owner3TitleDesc = 'owner3_title_DESC',
    Owner3OwnershipPercentageAsc = 'owner3_ownership_percentage_ASC',
    Owner3OwnershipPercentageDesc = 'owner3_ownership_percentage_DESC',
    Owner3PhoneAsc = 'owner3_phone_ASC',
    Owner3PhoneDesc = 'owner3_phone_DESC',
    Owner3SsnAsc = 'owner3_ssn_ASC',
    Owner3SsnDesc = 'owner3_ssn_DESC',
    Owner3DobAsc = 'owner3_dob_ASC',
    Owner3DobDesc = 'owner3_dob_DESC',
    Owner3StreetAsc = 'owner3_street_ASC',
    Owner3StreetDesc = 'owner3_street_DESC',
    Owner3CityAsc = 'owner3_city_ASC',
    Owner3CityDesc = 'owner3_city_DESC',
    Owner3StateAsc = 'owner3_state_ASC',
    Owner3StateDesc = 'owner3_state_DESC',
    Owner3ZipAsc = 'owner3_zip_ASC',
    Owner3ZipDesc = 'owner3_zip_DESC',
    Owner4FirstNameAsc = 'owner4_first_name_ASC',
    Owner4FirstNameDesc = 'owner4_first_name_DESC',
    Owner4LastNameAsc = 'owner4_last_name_ASC',
    Owner4LastNameDesc = 'owner4_last_name_DESC',
    Owner4TitleAsc = 'owner4_title_ASC',
    Owner4TitleDesc = 'owner4_title_DESC',
    Owner4OwnershipPercentageAsc = 'owner4_ownership_percentage_ASC',
    Owner4OwnershipPercentageDesc = 'owner4_ownership_percentage_DESC',
    Owner4PhoneAsc = 'owner4_phone_ASC',
    Owner4PhoneDesc = 'owner4_phone_DESC',
    Owner4SsnAsc = 'owner4_ssn_ASC',
    Owner4SsnDesc = 'owner4_ssn_DESC',
    Owner4DobAsc = 'owner4_dob_ASC',
    Owner4DobDesc = 'owner4_dob_DESC',
    Owner4StreetAsc = 'owner4_street_ASC',
    Owner4StreetDesc = 'owner4_street_DESC',
    Owner4CityAsc = 'owner4_city_ASC',
    Owner4CityDesc = 'owner4_city_DESC',
    Owner4StateAsc = 'owner4_state_ASC',
    Owner4StateDesc = 'owner4_state_DESC',
    Owner4ZipAsc = 'owner4_zip_ASC',
    Owner4ZipDesc = 'owner4_zip_DESC',
    LocationTypeAsc = 'location_type_ASC',
    LocationTypeDesc = 'location_type_DESC',
    SignatureAsc = 'signature_ASC',
    SignatureDesc = 'signature_DESC',
    SignatureDataAsc = 'signature_data_ASC',
    SignatureDataDesc = 'signature_data_DESC',
    PricingAsc = 'pricing_ASC',
    PricingDesc = 'pricing_DESC',
    CommissionAsc = 'commission_ASC',
    CommissionDesc = 'commission_DESC',
    PrepaidMonthsAsc = 'prepaidMonths_ASC',
    PrepaidMonthsDesc = 'prepaidMonths_DESC',
    ShowErrorsAsc = 'showErrors_ASC',
    ShowErrorsDesc = 'showErrors_DESC',
    SubmittedAsc = 'submitted_ASC',
    SubmittedDesc = 'submitted_DESC',
    HasBankInfoAsc = 'hasBankInfo_ASC',
    HasBankInfoDesc = 'hasBankInfo_DESC',
    NotesAsc = 'notes_ASC',
    NotesDesc = 'notes_DESC',
    AgreeAsc = 'agree_ASC',
    AgreeDesc = 'agree_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
}

export enum MutationType {
    Created = 'CREATED',
    Updated = 'UPDATED',
    Deleted = 'DELETED',
}

export type DateTime = any;

/** The `Long` scalar type represents non-fractional signed whole numeric values.Long can represent values between -(2^63) and 2^63 - 1. */
export type Long = any;

// ====================================================
// Documents
// ====================================================

export type AmbassadorInfoCreateEventVariables = {
    id: string;
    type: string;
    time: DateTime;
};

export type AmbassadorInfoCreateEventMutation = {
    __typename?: 'Mutation';

    updateAmbassadorLead: AmbassadorInfoCreateEventUpdateAmbassadorLead | null;
};

export type AmbassadorInfoCreateEventUpdateAmbassadorLead = {
    __typename?: 'AmbassadorLead';

    id: string;
};

export type AmbassadorInfoLeadVariables = {
    slug: string;
};

export type AmbassadorInfoLeadQuery = {
    __typename?: 'Query';

    lead: AmbassadorInfoLeadLead | null;
};

export type AmbassadorInfoLeadLead = {
    __typename?: 'AmbassadorLead';

    id: string;

    status: AmbassadorLeadStatus;

    firstName: string;

    lastName: string;

    email: string;

    phone: string;

    upline: AmbassadorInfoLeadUpline;
};

export type AmbassadorInfoLeadUpline = {
    __typename?: 'User';

    id: string;

    firstName: string;

    lastName: string;
};

export type AmbassadorSignUpVariables = {
    id: string;
};

export type AmbassadorSignUpMutation = {
    __typename?: 'Mutation';

    ambassadorSignUp: AmbassadorSignUpAmbassadorSignUp | null;
};

export type AmbassadorSignUpAmbassadorSignUp = {
    __typename?: 'User';

    id: string;

    phone: string;
};

export type CrmAdminUsersVariables = {};

export type CrmAdminUsersQuery = {
    __typename?: 'Query';

    users: CrmAdminUsersUsers[];
};

export type CrmAdminUsersUsers = {
    __typename?: 'User';

    id: string;

    firstName: string;
};

export type CrmDeleteFollowupVariables = {
    id: string;
};

export type CrmDeleteFollowupMutation = {
    __typename?: 'Mutation';

    deleteMerchantLeadFollowup: CrmDeleteFollowupDeleteMerchantLeadFollowup | null;
};

export type CrmDeleteFollowupDeleteMerchantLeadFollowup = {
    __typename?: 'MerchantLeadFollowup';

    id: string;
};

export type CrmLeadsVariables = {
    where: MerchantLeadWhereInput;
};

export type CrmLeadsQuery = {
    __typename?: 'Query';

    merchantLeads: CrmLeadsMerchantLeads[];
};

export type CrmLeadsMerchantLeads = CrmFieldsFragment;

export type CrmStatusCountVariables = {
    where: MerchantLeadWhereInput;
};

export type CrmStatusCountQuery = {
    __typename?: 'Query';

    merchantLeadsConnection: CrmStatusCountMerchantLeadsConnection;
};

export type CrmStatusCountMerchantLeadsConnection = {
    __typename?: 'MerchantLeadConnection';

    aggregate: CrmStatusCountAggregate;
};

export type CrmStatusCountAggregate = {
    __typename?: 'AggregateMerchantLead';

    count: number;
};

export type CrmUpdateMerchantLeadVariables = {
    id: string;
    data: MerchantLeadUpdateInput;
};

export type CrmUpdateMerchantLeadMutation = {
    __typename?: 'Mutation';

    updateMerchantLead: CrmUpdateMerchantLeadUpdateMerchantLead | null;
};

export type CrmUpdateMerchantLeadUpdateMerchantLead = CrmFieldsFragment;

export type DownlineVariables = {
    curMonth: string;
    prevMonth: string;
};

export type DownlineQuery = {
    __typename?: 'Query';

    currentUser: DownlineCurrentUser | null;
};

export type DownlineCurrentUser = {
    __typename?: 'User';

    id: string;

    merchants: DownlineMerchants[] | null;

    merchantLeads: DownlineMerchantLeads[] | null;
};

export type DownlineMerchants = {
    __typename?: 'Merchant';

    id: string;

    businessName: string;

    curMonth: DownlineCurMonth[] | null;

    prevMonth: DownlinePrevMonth[] | null;
};

export type DownlineCurMonth = EstimateFieldsFragment;

export type DownlinePrevMonth = EstimateFieldsFragment;

export type DownlineMerchantLeads = {
    __typename?: 'MerchantLead';

    id: string;

    status: MerchantLeadStatus;

    firstName: string;

    lastName: string;

    businessName: string;

    events: DownlineEvents[] | null;

    applicationStatus: string | null;

    application: DownlineApplication;
};

export type DownlineEvents = {
    __typename?: 'Event';

    id: string;

    type: string;
};

export type DownlineApplication = {
    __typename?: 'MerchantApplication';

    id: string;

    submitTimestamp: DateTime | null;
};

export type GetTokenVariables = {
    phone: string;
    code: string;
};

export type GetTokenMutation = {
    __typename?: 'Mutation';

    getToken: string | null;
};

export type LoginCurrentUserVariables = {};

export type LoginCurrentUserQuery = {
    __typename?: 'Query';

    currentUser: LoginCurrentUserCurrentUser | null;
};

export type LoginCurrentUserCurrentUser = {
    __typename?: 'User';

    id: string;

    phone: string;

    firstName: string;

    lastName: string;

    roles: UserRole[];

    ownedBusinesses: LoginCurrentUserOwnedBusinesses[] | null;
};

export type LoginCurrentUserOwnedBusinesses = {
    __typename?: 'Merchant';

    id: string;
};

export type SendCodeVariables = {
    phone: string;
};

export type SendCodeMutation = {
    __typename?: 'Mutation';

    sendSMSCode: string;
};

export type MerchantInfoCreateEventVariables = {
    id: string;
    type: string;
    time: DateTime;
};

export type MerchantInfoCreateEventMutation = {
    __typename?: 'Mutation';

    updateMerchantLead: MerchantInfoCreateEventUpdateMerchantLead | null;
};

export type MerchantInfoCreateEventUpdateMerchantLead = {
    __typename?: 'MerchantLead';

    id: string;
};

export type MerchantInfoLeadVariables = {
    slug: string;
};

export type MerchantInfoLeadQuery = {
    __typename?: 'Query';

    lead: MerchantInfoLeadLead | null;
};

export type MerchantInfoLeadLead = {
    __typename?: 'MerchantLead';

    id: string;

    firstName: string;

    lastName: string;

    businessName: string;

    email: string | null;

    phones: string[];

    infoVideo: string;

    enroller: MerchantInfoLeadEnroller;

    application: MerchantInfoLeadApplication;
};

export type MerchantInfoLeadEnroller = {
    __typename?: 'User';

    firstName: string;

    lastName: string;
};

export type MerchantInfoLeadApplication = {
    __typename?: 'MerchantApplication';

    id: string;
};

export type PendingReferralsVariables = {};

export type PendingReferralsQuery = {
    __typename?: 'Query';

    currentUser: PendingReferralsCurrentUser | null;
};

export type PendingReferralsCurrentUser = {
    __typename?: 'User';

    id: string;

    draftMerchants: PendingReferralsDraftMerchants[] | null;

    merchantLeads: PendingReferralsMerchantLeads[] | null;

    ambassadorLeads: PendingReferralsAmbassadorLeads[] | null;
};

export type PendingReferralsDraftMerchants = {
    __typename?: 'DraftMerchant';

    id: string;

    firstName: string | null;

    lastName: string | null;

    businessName: string | null;
};

export type PendingReferralsMerchantLeads = {
    __typename?: 'MerchantLead';

    id: string;

    firstName: string;

    lastName: string;

    businessName: string;

    events: PendingReferralsEvents[] | null;

    applicationStatus: string | null;

    application: PendingReferralsApplication;
};

export type PendingReferralsEvents = {
    __typename?: 'Event';

    id: string;

    type: string;
};

export type PendingReferralsApplication = {
    __typename?: 'MerchantApplication';

    id: string;

    submitTimestamp: DateTime | null;
};

export type PendingReferralsAmbassadorLeads = {
    __typename?: 'AmbassadorLead';

    id: string;

    firstName: string;

    lastName: string;

    events: PendingReferrals_Events[] | null;
};

export type PendingReferrals_Events = {
    __typename?: 'Event';

    id: string;

    type: string;
};

export type ReferAmbassadorVariables = {
    lead: AmbassadorLeadCreateInput;
};

export type ReferAmbassadorMutation = {
    __typename?: 'Mutation';

    createAmbassadorLead: ReferAmbassadorCreateAmbassadorLead;
};

export type ReferAmbassadorCreateAmbassadorLead = {
    __typename?: 'AmbassadorLead';

    id: string;
};

export type DeleteDraftMerchantVariables = {
    id: string;
};

export type DeleteDraftMerchantMutation = {
    __typename?: 'Mutation';

    deleteDraftMerchant: DeleteDraftMerchantDeleteDraftMerchant | null;
};

export type DeleteDraftMerchantDeleteDraftMerchant = {
    __typename?: 'DraftMerchant';

    id: string;
};

export type GetDraftMerchantsVariables = {};

export type GetDraftMerchantsQuery = {
    __typename?: 'Query';

    currentUser: GetDraftMerchantsCurrentUser | null;
};

export type GetDraftMerchantsCurrentUser = {
    __typename?: 'User';

    id: string;

    firstName: string;

    lastName: string;

    draftMerchants: GetDraftMerchantsDraftMerchants[] | null;
};

export type GetDraftMerchantsDraftMerchants = DraftMerchantFieldsFragment;

export type SaveDraftMerchantVariables = {
    draft: DraftMerchantCreateInput;
};

export type SaveDraftMerchantMutation = {
    __typename?: 'Mutation';

    createDraftMerchant: SaveDraftMerchantCreateDraftMerchant;
};

export type SaveDraftMerchantCreateDraftMerchant = DraftMerchantFieldsFragment;

export type SubmitMerchantLeadVariables = {
    lead: MerchantLeadCreateInput;
};

export type SubmitMerchantLeadMutation = {
    __typename?: 'Mutation';

    createMerchantLead: SubmitMerchantLeadCreateMerchantLead;
};

export type SubmitMerchantLeadCreateMerchantLead = {
    __typename?: 'MerchantLead';

    id: string;
};

export type UpdateDraftMerchantVariables = {
    id: string;
    draft: DraftMerchantUpdateInput;
};

export type UpdateDraftMerchantMutation = {
    __typename?: 'Mutation';

    updateDraftMerchant: UpdateDraftMerchantUpdateDraftMerchant | null;
};

export type UpdateDraftMerchantUpdateDraftMerchant = DraftMerchantFieldsFragment;

export type CurrentUserMonthlyCommissionsVariables = {
    month: string;
};

export type CurrentUserMonthlyCommissionsQuery = {
    __typename?: 'Query';

    currentUser: CurrentUserMonthlyCommissionsCurrentUser | null;
};

export type CurrentUserMonthlyCommissionsCurrentUser = {
    __typename?: 'User';

    id: string;

    monthlyCommissionCalculations: CurrentUserMonthlyCommissionsMonthlyCommissionCalculations[];
};

export type CurrentUserMonthlyCommissionsMonthlyCommissionCalculations = {
    __typename?: 'MonthlyCommissionCalculation';

    id: string;

    merchantId: string;

    businessName: string;

    level: number;

    amount: number;

    legName: string;

    legBusinessName: string;
};

export type CurrentUserOwnedBusinessesVariables = {};

export type CurrentUserOwnedBusinessesQuery = {
    __typename?: 'Query';

    currentUser: CurrentUserOwnedBusinessesCurrentUser | null;
};

export type CurrentUserOwnedBusinessesCurrentUser = {
    __typename?: 'User';

    id: string;

    ownedBusinesses: CurrentUserOwnedBusinessesOwnedBusinesses[] | null;
};

export type CurrentUserOwnedBusinessesOwnedBusinesses = {
    __typename?: 'Merchant';

    id: string;

    businessName: string;

    monthlyCommissions: CurrentUserOwnedBusinessesMonthlyCommissions[] | null;

    commissionDeposits: CurrentUserOwnedBusinessesCommissionDeposits[] | null;
};

export type CurrentUserOwnedBusinessesMonthlyCommissions = {
    __typename?: 'MerchantMonthlyCommission';

    id: string;

    monthString: string;

    amount: number;
};

export type CurrentUserOwnedBusinessesCommissionDeposits = {
    __typename?: 'MerchantCommissionDeposit';

    id: string;

    dateString: string;

    amount: number;
};

export type CreateCommissionDepositVariables = {
    data: MerchantCommissionDepositCreateInput;
};

export type CreateCommissionDepositMutation = {
    __typename?: 'Mutation';

    createMerchantCommissionDeposit: CreateCommissionDepositCreateMerchantCommissionDeposit;
};

export type CreateCommissionDepositCreateMerchantCommissionDeposit = {
    __typename?: 'MerchantCommissionDeposit';

    id: string;

    dateString: string;

    amount: number;
};

export type CreateMonthlyCommissionVariables = {
    data: MerchantMonthlyCommissionCreateInput;
};

export type CreateMonthlyCommissionMutation = {
    __typename?: 'Mutation';

    createMerchantMonthlyCommission: CreateMonthlyCommissionCreateMerchantMonthlyCommission;
};

export type CreateMonthlyCommissionCreateMerchantMonthlyCommission = {
    __typename?: 'MerchantMonthlyCommission';

    id: string;

    amount: number;

    key: string;

    monthString: string;
};

export type DeleteCommissionDepositVariables = {
    id: string;
};

export type DeleteCommissionDepositMutation = {
    __typename?: 'Mutation';

    deleteMerchantCommissionDeposit: DeleteCommissionDepositDeleteMerchantCommissionDeposit | null;
};

export type DeleteCommissionDepositDeleteMerchantCommissionDeposit = {
    __typename?: 'MerchantCommissionDeposit';

    id: string;
};

export type UpdateCommissionDepositVariables = {
    id: string;
    data: MerchantCommissionDepositUpdateInput;
};

export type UpdateCommissionDepositMutation = {
    __typename?: 'Mutation';

    updateMerchantCommissionDeposit: UpdateCommissionDepositUpdateMerchantCommissionDeposit | null;
};

export type UpdateCommissionDepositUpdateMerchantCommissionDeposit = {
    __typename?: 'MerchantCommissionDeposit';

    id: string;

    dateString: string;

    amount: number;
};

export type UpdateMonthlyCommissionVariables = {
    id: string;
    amount: number;
};

export type UpdateMonthlyCommissionMutation = {
    __typename?: 'Mutation';

    updateMerchantMonthlyCommission: UpdateMonthlyCommissionUpdateMerchantMonthlyCommission | null;
};

export type UpdateMonthlyCommissionUpdateMerchantMonthlyCommission = {
    __typename?: 'MerchantMonthlyCommission';

    id: string;

    amount: number;
};

export type ViewMerchantPageVariables = {
    id: string;
};

export type ViewMerchantPageQuery = {
    __typename?: 'Query';

    merchant: ViewMerchantPageMerchant | null;
};

export type ViewMerchantPageMerchant = {
    __typename?: 'Merchant';

    id: string;

    businessName: string;

    industry: string;

    merchantNumber: string | null;

    owner: ViewMerchantPageOwner;

    monthlyCommissions: ViewMerchantPageMonthlyCommissions[] | null;

    commissionDeposits: ViewMerchantPageCommissionDeposits[] | null;
};

export type ViewMerchantPageOwner = {
    __typename?: 'User';

    id: string;

    firstName: string;

    lastName: string;

    phone: string;

    email: string;

    impersonationToken: string | null;
};

export type ViewMerchantPageMonthlyCommissions = {
    __typename?: 'MerchantMonthlyCommission';

    id: string;

    monthString: string;

    amount: number;
};

export type ViewMerchantPageCommissionDeposits = {
    __typename?: 'MerchantCommissionDeposit';

    id: string;

    dateString: string;

    amount: number;
};

export type MakeMerchantVariables = {
    id: string;
    owner: UserCreateOneWithoutOwnedBusinessesInput;
};

export type MakeMerchantMutation = {
    __typename?: 'Mutation';

    convertMerchantLead: MakeMerchantConvertMerchantLead | null;
};

export type MakeMerchantConvertMerchantLead = {
    __typename?: 'Merchant';

    id: string;
};

export type ViewMerchantVariables = {
    id: string;
};

export type ViewMerchantQuery = {
    __typename?: 'Query';

    lead: ViewMerchantLead | null;
};

export type ViewMerchantLead = ViewMerchantLeadFieldsFragment;

export type ViewMerchantLeadCreateEventVariables = {
    id: string;
    type: string;
    time: DateTime;
};

export type ViewMerchantLeadCreateEventMutation = {
    __typename?: 'Mutation';

    updateMerchantLead: ViewMerchantLeadCreateEventUpdateMerchantLead | null;
};

export type ViewMerchantLeadCreateEventUpdateMerchantLead = {
    __typename?: 'MerchantLead';

    id: string;
};

export type ViewMerchantUpdateApplicationVariables = {
    id: string;
    data: MerchantApplicationUpdateInput;
};

export type ViewMerchantUpdateApplicationMutation = {
    __typename?: 'Mutation';

    updateMerchantApplication: ViewMerchantUpdateApplicationUpdateMerchantApplication | null;
};

export type ViewMerchantUpdateApplicationUpdateMerchantApplication = AppFieldsFragment;

export type ViewMerchantUpdateLeadVariables = {
    id: string;
    data: MerchantLeadUpdateInput;
};

export type ViewMerchantUpdateLeadMutation = {
    __typename?: 'Mutation';

    updateMerchantLead: ViewMerchantUpdateLeadUpdateMerchantLead | null;
};

export type ViewMerchantUpdateLeadUpdateMerchantLead = ViewMerchantLeadFieldsFragment;

export type CrmFieldsFragment = {
    __typename?: 'MerchantLead';

    id: string;

    createdAt: DateTime;

    status: MerchantLeadStatus;

    firstName: string;

    lastName: string;

    email: string | null;

    website: string | null;

    businessName: string;

    phones: string[];

    notes: string | null;

    leadScore: number;

    applicationStatus: string | null;

    ambassadorComments: string[];

    equipmentOnboardingNotes: string | null;

    pricingCommissionEstimateNotes: string | null;

    howTheyTakePaymentsNotes: string | null;

    pendingQuestionsNotes: string | null;

    additionalContactInfoNotes: string | null;

    frontingCommissionAmount: string | null;

    owner: CrmFieldsOwner | null;

    application: CrmFieldsApplication;

    enroller: CrmFieldsEnroller;

    events: CrmFieldsEvents[] | null;

    followup: CrmFieldsFollowup | null;
};

export type CrmFieldsOwner = {
    __typename?: 'User';

    id: string;

    firstName: string;
};

export type CrmFieldsApplication = {
    __typename?: 'MerchantApplication';

    id: string;

    signature: string | null;

    percentComplete: number | null;

    lastUpdated: DateTime;
};

export type CrmFieldsEnroller = {
    __typename?: 'User';

    id: string;

    firstName: string;

    lastName: string;
};

export type CrmFieldsEvents = {
    __typename?: 'Event';

    id: string;

    timestamp: DateTime;

    type: string;
};

export type CrmFieldsFollowup = {
    __typename?: 'MerchantLeadFollowup';

    id: string;

    date: DateTime;

    user: CrmFieldsUser;

    appointment: boolean;
};

export type CrmFieldsUser = {
    __typename?: 'User';

    firstName: string;

    lastName: string;
};

export type EstimateFieldsFragment = {
    __typename?: 'MerchantDailyEstimate';

    merchantVariableAmt: number;

    merchantFixedAmt: number;
};

export type DraftMerchantFieldsFragment = {
    __typename?: 'DraftMerchant';

    id: string;

    firstName: string | null;

    lastName: string | null;

    phones: string[];

    email: string | null;

    infoVideo: string | null;

    businessName: string | null;

    relationship: string | null;

    website: string | null;
};

export type AppFieldsFragment = {
    __typename?: 'MerchantApplication';

    id: string;

    percentComplete: number | null;

    pricing: string | null;

    commission: number | null;

    showErrors: boolean | null;

    notes: string | null;

    lastUpdated: DateTime;

    submitTimestamp: DateTime | null;
};

export type ViewMerchantLeadFieldsFragment = {
    __typename?: 'MerchantLead';

    id: string;

    firstName: string;

    lastName: string;

    businessName: string;

    phones: string[];

    email: string | null;

    website: string | null;

    referralLink: string | null;

    images: string[];

    events: ViewMerchantLeadFieldsEvents[] | null;

    application: ViewMerchantLeadFieldsApplication;

    enroller: ViewMerchantLeadFieldsEnroller;

    visaBP: number | null;

    visaCents: number | null;

    mastercardBP: number | null;

    mastercardCents: number | null;

    discoverBP: number | null;

    discoverCents: number | null;

    amexBP: number | null;

    amexCents: number | null;

    monthlyFees: number | null;

    monthlyFixedExpenses: number | null;

    expressMerchantFunding: boolean | null;

    ambassadorComments: string[];
};

export type ViewMerchantLeadFieldsEvents = {
    __typename?: 'Event';

    id: string;

    type: string;

    timestamp: DateTime;
};

export type ViewMerchantLeadFieldsApplication = AppFieldsFragment;

export type ViewMerchantLeadFieldsEnroller = {
    __typename?: 'User';

    id: string;
};

import * as ReactApollo from 'react-apollo';
import * as React from 'react';

import gql from 'graphql-tag';

// ====================================================
// Fragments
// ====================================================

export const CrmFieldsFragmentDoc = gql`
    fragment CrmFields on MerchantLead {
        id
        createdAt
        status
        firstName
        lastName
        email
        website
        businessName
        phones
        notes
        leadScore
        applicationStatus
        ambassadorComments
        equipmentOnboardingNotes
        pricingCommissionEstimateNotes
        howTheyTakePaymentsNotes
        pendingQuestionsNotes
        additionalContactInfoNotes
        frontingCommissionAmount
        owner {
            id
            firstName
        }
        application {
            id
            signature
            percentComplete
            lastUpdated
        }
        enroller {
            id
            firstName
            lastName
        }
        events(orderBy: timestamp_DESC, first: 1) {
            id
            timestamp
            type
        }
        followup {
            id
            date
            user {
                firstName
                lastName
            }
            appointment
        }
    }
`;

export const EstimateFieldsFragmentDoc = gql`
    fragment EstimateFields on MerchantDailyEstimate {
        merchantVariableAmt
        merchantFixedAmt
    }
`;

export const DraftMerchantFieldsFragmentDoc = gql`
    fragment DraftMerchantFields on DraftMerchant {
        id
        firstName
        lastName
        phones
        email
        infoVideo
        businessName
        relationship
        website
    }
`;

export const AppFieldsFragmentDoc = gql`
    fragment AppFields on MerchantApplication {
        id
        percentComplete
        pricing
        commission
        showErrors
        notes
        lastUpdated
        submitTimestamp
    }
`;

export const ViewMerchantLeadFieldsFragmentDoc = gql`
    fragment ViewMerchantLeadFields on MerchantLead {
        id
        firstName
        lastName
        businessName
        phones
        email
        website
        referralLink
        images
        events {
            id
            type
            timestamp
        }
        application {
            ...AppFields
        }
        enroller {
            id
        }
        visaBP
        visaCents
        mastercardBP
        mastercardCents
        discoverBP
        discoverCents
        amexBP
        amexCents
        monthlyFees
        monthlyFixedExpenses
        expressMerchantFunding
        ambassadorComments
    }

    ${AppFieldsFragmentDoc}
`;

// ====================================================
// Components
// ====================================================

export const AmbassadorInfoCreateEventDocument = gql`
    mutation AmbassadorInfoCreateEvent(
        $id: ID!
        $type: String!
        $time: DateTime!
    ) {
        updateAmbassadorLead(
            where: { id: $id }
            data: { events: { create: { type: $type, timestamp: $time } } }
        ) {
            id
        }
    }
`;
export class AmbassadorInfoCreateEventComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            AmbassadorInfoCreateEventMutation,
            AmbassadorInfoCreateEventVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                AmbassadorInfoCreateEventMutation,
                AmbassadorInfoCreateEventVariables
            >
                mutation={AmbassadorInfoCreateEventDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type AmbassadorInfoCreateEventProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        AmbassadorInfoCreateEventMutation,
        AmbassadorInfoCreateEventVariables
    >
> &
    TChildProps;
export type AmbassadorInfoCreateEventMutationFn = ReactApollo.MutationFn<
    AmbassadorInfoCreateEventMutation,
    AmbassadorInfoCreateEventVariables
>;
export function AmbassadorInfoCreateEventHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              AmbassadorInfoCreateEventMutation,
              AmbassadorInfoCreateEventVariables,
              AmbassadorInfoCreateEventProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        AmbassadorInfoCreateEventMutation,
        AmbassadorInfoCreateEventVariables,
        AmbassadorInfoCreateEventProps<TChildProps>
    >(AmbassadorInfoCreateEventDocument, operationOptions);
}
export const AmbassadorInfoLeadDocument = gql`
    query AmbassadorInfoLead($slug: String!) {
        lead: ambassadorLead(where: { slug: $slug }) {
            id
            status
            firstName
            lastName
            email
            phone
            upline {
                id
                firstName
                lastName
            }
        }
    }
`;
export class AmbassadorInfoLeadComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<
            AmbassadorInfoLeadQuery,
            AmbassadorInfoLeadVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Query<
                AmbassadorInfoLeadQuery,
                AmbassadorInfoLeadVariables
            >
                query={AmbassadorInfoLeadDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type AmbassadorInfoLeadProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<AmbassadorInfoLeadQuery, AmbassadorInfoLeadVariables>
> &
    TChildProps;
export function AmbassadorInfoLeadHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              AmbassadorInfoLeadQuery,
              AmbassadorInfoLeadVariables,
              AmbassadorInfoLeadProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        AmbassadorInfoLeadQuery,
        AmbassadorInfoLeadVariables,
        AmbassadorInfoLeadProps<TChildProps>
    >(AmbassadorInfoLeadDocument, operationOptions);
}
export const AmbassadorSignUpDocument = gql`
    mutation AmbassadorSignUp($id: ID!) {
        ambassadorSignUp(id: $id) {
            id
            phone
        }
    }
`;
export class AmbassadorSignUpComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            AmbassadorSignUpMutation,
            AmbassadorSignUpVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                AmbassadorSignUpMutation,
                AmbassadorSignUpVariables
            >
                mutation={AmbassadorSignUpDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type AmbassadorSignUpProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<AmbassadorSignUpMutation, AmbassadorSignUpVariables>
> &
    TChildProps;
export type AmbassadorSignUpMutationFn = ReactApollo.MutationFn<
    AmbassadorSignUpMutation,
    AmbassadorSignUpVariables
>;
export function AmbassadorSignUpHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              AmbassadorSignUpMutation,
              AmbassadorSignUpVariables,
              AmbassadorSignUpProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        AmbassadorSignUpMutation,
        AmbassadorSignUpVariables,
        AmbassadorSignUpProps<TChildProps>
    >(AmbassadorSignUpDocument, operationOptions);
}
export const CrmAdminUsersDocument = gql`
    query CRMAdminUsers {
        users(
            where: { userRoles_some: { name: ADMIN } }
            orderBy: firstName_ASC
        ) {
            id
            firstName
        }
    }
`;
export class CrmAdminUsersComponent extends React.Component<
    Partial<ReactApollo.QueryProps<CrmAdminUsersQuery, CrmAdminUsersVariables>>
> {
    render() {
        return (
            <ReactApollo.Query<CrmAdminUsersQuery, CrmAdminUsersVariables>
                query={CrmAdminUsersDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CrmAdminUsersProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<CrmAdminUsersQuery, CrmAdminUsersVariables>
> &
    TChildProps;
export function CrmAdminUsersHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CrmAdminUsersQuery,
              CrmAdminUsersVariables,
              CrmAdminUsersProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CrmAdminUsersQuery,
        CrmAdminUsersVariables,
        CrmAdminUsersProps<TChildProps>
    >(CrmAdminUsersDocument, operationOptions);
}
export const CrmDeleteFollowupDocument = gql`
    mutation CRMDeleteFollowup($id: ID!) {
        deleteMerchantLeadFollowup(where: { id: $id }) {
            id
        }
    }
`;
export class CrmDeleteFollowupComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            CrmDeleteFollowupMutation,
            CrmDeleteFollowupVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                CrmDeleteFollowupMutation,
                CrmDeleteFollowupVariables
            >
                mutation={CrmDeleteFollowupDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CrmDeleteFollowupProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        CrmDeleteFollowupMutation,
        CrmDeleteFollowupVariables
    >
> &
    TChildProps;
export type CrmDeleteFollowupMutationFn = ReactApollo.MutationFn<
    CrmDeleteFollowupMutation,
    CrmDeleteFollowupVariables
>;
export function CrmDeleteFollowupHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CrmDeleteFollowupMutation,
              CrmDeleteFollowupVariables,
              CrmDeleteFollowupProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CrmDeleteFollowupMutation,
        CrmDeleteFollowupVariables,
        CrmDeleteFollowupProps<TChildProps>
    >(CrmDeleteFollowupDocument, operationOptions);
}
export const CrmLeadsDocument = gql`
    query CRMLeads($where: MerchantLeadWhereInput!) {
        merchantLeads(where: $where, orderBy: createdAt_DESC) {
            ...CrmFields
        }
    }

    ${CrmFieldsFragmentDoc}
`;
export class CrmLeadsComponent extends React.Component<
    Partial<ReactApollo.QueryProps<CrmLeadsQuery, CrmLeadsVariables>>
> {
    render() {
        return (
            <ReactApollo.Query<CrmLeadsQuery, CrmLeadsVariables>
                query={CrmLeadsDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CrmLeadsProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<CrmLeadsQuery, CrmLeadsVariables>
> &
    TChildProps;
export function CrmLeadsHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CrmLeadsQuery,
              CrmLeadsVariables,
              CrmLeadsProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CrmLeadsQuery,
        CrmLeadsVariables,
        CrmLeadsProps<TChildProps>
    >(CrmLeadsDocument, operationOptions);
}
export const CrmStatusCountDocument = gql`
    query CRMStatusCount($where: MerchantLeadWhereInput!) {
        merchantLeadsConnection(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
export class CrmStatusCountComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<CrmStatusCountQuery, CrmStatusCountVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Query<CrmStatusCountQuery, CrmStatusCountVariables>
                query={CrmStatusCountDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CrmStatusCountProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<CrmStatusCountQuery, CrmStatusCountVariables>
> &
    TChildProps;
export function CrmStatusCountHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CrmStatusCountQuery,
              CrmStatusCountVariables,
              CrmStatusCountProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CrmStatusCountQuery,
        CrmStatusCountVariables,
        CrmStatusCountProps<TChildProps>
    >(CrmStatusCountDocument, operationOptions);
}
export const CrmUpdateMerchantLeadDocument = gql`
    mutation CrmUpdateMerchantLead($id: ID!, $data: MerchantLeadUpdateInput!) {
        updateMerchantLead(where: { id: $id }, data: $data) {
            ...CrmFields
        }
    }

    ${CrmFieldsFragmentDoc}
`;
export class CrmUpdateMerchantLeadComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            CrmUpdateMerchantLeadMutation,
            CrmUpdateMerchantLeadVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                CrmUpdateMerchantLeadMutation,
                CrmUpdateMerchantLeadVariables
            >
                mutation={CrmUpdateMerchantLeadDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CrmUpdateMerchantLeadProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        CrmUpdateMerchantLeadMutation,
        CrmUpdateMerchantLeadVariables
    >
> &
    TChildProps;
export type CrmUpdateMerchantLeadMutationFn = ReactApollo.MutationFn<
    CrmUpdateMerchantLeadMutation,
    CrmUpdateMerchantLeadVariables
>;
export function CrmUpdateMerchantLeadHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CrmUpdateMerchantLeadMutation,
              CrmUpdateMerchantLeadVariables,
              CrmUpdateMerchantLeadProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CrmUpdateMerchantLeadMutation,
        CrmUpdateMerchantLeadVariables,
        CrmUpdateMerchantLeadProps<TChildProps>
    >(CrmUpdateMerchantLeadDocument, operationOptions);
}
export const DownlineDocument = gql`
    query Downline($curMonth: String!, $prevMonth: String!) {
        currentUser {
            id
            merchants {
                id
                businessName
                curMonth: dailyEstimates(
                    where: { dateString_starts_with: $curMonth }
                ) {
                    ...EstimateFields
                }
                prevMonth: dailyEstimates(
                    where: { dateString_starts_with: $prevMonth }
                ) {
                    ...EstimateFields
                }
            }
            merchantLeads {
                id
                status
                firstName
                lastName
                businessName
                events {
                    id
                    type
                }
                applicationStatus
                application {
                    id
                    submitTimestamp
                }
            }
        }
    }

    ${EstimateFieldsFragmentDoc}
`;
export class DownlineComponent extends React.Component<
    Partial<ReactApollo.QueryProps<DownlineQuery, DownlineVariables>>
> {
    render() {
        return (
            <ReactApollo.Query<DownlineQuery, DownlineVariables>
                query={DownlineDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type DownlineProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<DownlineQuery, DownlineVariables>
> &
    TChildProps;
export function DownlineHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              DownlineQuery,
              DownlineVariables,
              DownlineProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        DownlineQuery,
        DownlineVariables,
        DownlineProps<TChildProps>
    >(DownlineDocument, operationOptions);
}
export const GetTokenDocument = gql`
    mutation GetToken($phone: String!, $code: String!) {
        getToken(phone: $phone, code: $code)
    }
`;
export class GetTokenComponent extends React.Component<
    Partial<ReactApollo.MutationProps<GetTokenMutation, GetTokenVariables>>
> {
    render() {
        return (
            <ReactApollo.Mutation<GetTokenMutation, GetTokenVariables>
                mutation={GetTokenDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type GetTokenProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<GetTokenMutation, GetTokenVariables>
> &
    TChildProps;
export type GetTokenMutationFn = ReactApollo.MutationFn<
    GetTokenMutation,
    GetTokenVariables
>;
export function GetTokenHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              GetTokenMutation,
              GetTokenVariables,
              GetTokenProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        GetTokenMutation,
        GetTokenVariables,
        GetTokenProps<TChildProps>
    >(GetTokenDocument, operationOptions);
}
export const LoginCurrentUserDocument = gql`
    query LoginCurrentUser {
        currentUser {
            id
            phone
            firstName
            lastName
            roles
            ownedBusinesses {
                id
            }
        }
    }
`;
export class LoginCurrentUserComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<LoginCurrentUserQuery, LoginCurrentUserVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Query<LoginCurrentUserQuery, LoginCurrentUserVariables>
                query={LoginCurrentUserDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type LoginCurrentUserProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<LoginCurrentUserQuery, LoginCurrentUserVariables>
> &
    TChildProps;
export function LoginCurrentUserHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              LoginCurrentUserQuery,
              LoginCurrentUserVariables,
              LoginCurrentUserProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        LoginCurrentUserQuery,
        LoginCurrentUserVariables,
        LoginCurrentUserProps<TChildProps>
    >(LoginCurrentUserDocument, operationOptions);
}
export const SendCodeDocument = gql`
    mutation SendCode($phone: String!) {
        sendSMSCode(phone: $phone)
    }
`;
export class SendCodeComponent extends React.Component<
    Partial<ReactApollo.MutationProps<SendCodeMutation, SendCodeVariables>>
> {
    render() {
        return (
            <ReactApollo.Mutation<SendCodeMutation, SendCodeVariables>
                mutation={SendCodeDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type SendCodeProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<SendCodeMutation, SendCodeVariables>
> &
    TChildProps;
export type SendCodeMutationFn = ReactApollo.MutationFn<
    SendCodeMutation,
    SendCodeVariables
>;
export function SendCodeHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              SendCodeMutation,
              SendCodeVariables,
              SendCodeProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        SendCodeMutation,
        SendCodeVariables,
        SendCodeProps<TChildProps>
    >(SendCodeDocument, operationOptions);
}
export const MerchantInfoCreateEventDocument = gql`
    mutation MerchantInfoCreateEvent(
        $id: ID!
        $type: String!
        $time: DateTime!
    ) {
        updateMerchantLead(
            where: { id: $id }
            data: { events: { create: { type: $type, timestamp: $time } } }
        ) {
            id
        }
    }
`;
export class MerchantInfoCreateEventComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            MerchantInfoCreateEventMutation,
            MerchantInfoCreateEventVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                MerchantInfoCreateEventMutation,
                MerchantInfoCreateEventVariables
            >
                mutation={MerchantInfoCreateEventDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type MerchantInfoCreateEventProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        MerchantInfoCreateEventMutation,
        MerchantInfoCreateEventVariables
    >
> &
    TChildProps;
export type MerchantInfoCreateEventMutationFn = ReactApollo.MutationFn<
    MerchantInfoCreateEventMutation,
    MerchantInfoCreateEventVariables
>;
export function MerchantInfoCreateEventHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              MerchantInfoCreateEventMutation,
              MerchantInfoCreateEventVariables,
              MerchantInfoCreateEventProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        MerchantInfoCreateEventMutation,
        MerchantInfoCreateEventVariables,
        MerchantInfoCreateEventProps<TChildProps>
    >(MerchantInfoCreateEventDocument, operationOptions);
}
export const MerchantInfoLeadDocument = gql`
    query MerchantInfoLead($slug: String!) {
        lead: merchantLead(where: { slug: $slug }) {
            id
            firstName
            lastName
            businessName
            email
            phones
            infoVideo
            enroller {
                firstName
                lastName
            }
            application {
                id
            }
        }
    }
`;
export class MerchantInfoLeadComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<MerchantInfoLeadQuery, MerchantInfoLeadVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Query<MerchantInfoLeadQuery, MerchantInfoLeadVariables>
                query={MerchantInfoLeadDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type MerchantInfoLeadProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<MerchantInfoLeadQuery, MerchantInfoLeadVariables>
> &
    TChildProps;
export function MerchantInfoLeadHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              MerchantInfoLeadQuery,
              MerchantInfoLeadVariables,
              MerchantInfoLeadProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        MerchantInfoLeadQuery,
        MerchantInfoLeadVariables,
        MerchantInfoLeadProps<TChildProps>
    >(MerchantInfoLeadDocument, operationOptions);
}
export const PendingReferralsDocument = gql`
    query PendingReferrals {
        currentUser {
            id
            draftMerchants {
                id
                firstName
                lastName
                businessName
            }
            merchantLeads(where: { status_not: BOARDED }) {
                id
                firstName
                lastName
                businessName
                events {
                    id
                    type
                }
                applicationStatus
                application {
                    id
                    submitTimestamp
                }
            }
            ambassadorLeads(where: { status_not: ACCEPTED }) {
                id
                firstName
                lastName
                events {
                    id
                    type
                }
            }
        }
    }
`;
export class PendingReferralsComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<PendingReferralsQuery, PendingReferralsVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Query<PendingReferralsQuery, PendingReferralsVariables>
                query={PendingReferralsDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type PendingReferralsProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<PendingReferralsQuery, PendingReferralsVariables>
> &
    TChildProps;
export function PendingReferralsHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              PendingReferralsQuery,
              PendingReferralsVariables,
              PendingReferralsProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        PendingReferralsQuery,
        PendingReferralsVariables,
        PendingReferralsProps<TChildProps>
    >(PendingReferralsDocument, operationOptions);
}
export const ReferAmbassadorDocument = gql`
    mutation ReferAmbassador($lead: AmbassadorLeadCreateInput!) {
        createAmbassadorLead(data: $lead) {
            id
        }
    }
`;
export class ReferAmbassadorComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            ReferAmbassadorMutation,
            ReferAmbassadorVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                ReferAmbassadorMutation,
                ReferAmbassadorVariables
            >
                mutation={ReferAmbassadorDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ReferAmbassadorProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<ReferAmbassadorMutation, ReferAmbassadorVariables>
> &
    TChildProps;
export type ReferAmbassadorMutationFn = ReactApollo.MutationFn<
    ReferAmbassadorMutation,
    ReferAmbassadorVariables
>;
export function ReferAmbassadorHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ReferAmbassadorMutation,
              ReferAmbassadorVariables,
              ReferAmbassadorProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ReferAmbassadorMutation,
        ReferAmbassadorVariables,
        ReferAmbassadorProps<TChildProps>
    >(ReferAmbassadorDocument, operationOptions);
}
export const DeleteDraftMerchantDocument = gql`
    mutation DeleteDraftMerchant($id: ID!) {
        deleteDraftMerchant(where: { id: $id }) {
            id
        }
    }
`;
export class DeleteDraftMerchantComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            DeleteDraftMerchantMutation,
            DeleteDraftMerchantVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                DeleteDraftMerchantMutation,
                DeleteDraftMerchantVariables
            >
                mutation={DeleteDraftMerchantDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type DeleteDraftMerchantProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        DeleteDraftMerchantMutation,
        DeleteDraftMerchantVariables
    >
> &
    TChildProps;
export type DeleteDraftMerchantMutationFn = ReactApollo.MutationFn<
    DeleteDraftMerchantMutation,
    DeleteDraftMerchantVariables
>;
export function DeleteDraftMerchantHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              DeleteDraftMerchantMutation,
              DeleteDraftMerchantVariables,
              DeleteDraftMerchantProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        DeleteDraftMerchantMutation,
        DeleteDraftMerchantVariables,
        DeleteDraftMerchantProps<TChildProps>
    >(DeleteDraftMerchantDocument, operationOptions);
}
export const GetDraftMerchantsDocument = gql`
    query GetDraftMerchants {
        currentUser {
            id
            firstName
            lastName
            draftMerchants {
                ...DraftMerchantFields
            }
        }
    }

    ${DraftMerchantFieldsFragmentDoc}
`;
export class GetDraftMerchantsComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<
            GetDraftMerchantsQuery,
            GetDraftMerchantsVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Query<
                GetDraftMerchantsQuery,
                GetDraftMerchantsVariables
            >
                query={GetDraftMerchantsDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type GetDraftMerchantsProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<GetDraftMerchantsQuery, GetDraftMerchantsVariables>
> &
    TChildProps;
export function GetDraftMerchantsHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              GetDraftMerchantsQuery,
              GetDraftMerchantsVariables,
              GetDraftMerchantsProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        GetDraftMerchantsQuery,
        GetDraftMerchantsVariables,
        GetDraftMerchantsProps<TChildProps>
    >(GetDraftMerchantsDocument, operationOptions);
}
export const SaveDraftMerchantDocument = gql`
    mutation SaveDraftMerchant($draft: DraftMerchantCreateInput!) {
        createDraftMerchant(data: $draft) {
            ...DraftMerchantFields
        }
    }

    ${DraftMerchantFieldsFragmentDoc}
`;
export class SaveDraftMerchantComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            SaveDraftMerchantMutation,
            SaveDraftMerchantVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                SaveDraftMerchantMutation,
                SaveDraftMerchantVariables
            >
                mutation={SaveDraftMerchantDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type SaveDraftMerchantProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        SaveDraftMerchantMutation,
        SaveDraftMerchantVariables
    >
> &
    TChildProps;
export type SaveDraftMerchantMutationFn = ReactApollo.MutationFn<
    SaveDraftMerchantMutation,
    SaveDraftMerchantVariables
>;
export function SaveDraftMerchantHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              SaveDraftMerchantMutation,
              SaveDraftMerchantVariables,
              SaveDraftMerchantProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        SaveDraftMerchantMutation,
        SaveDraftMerchantVariables,
        SaveDraftMerchantProps<TChildProps>
    >(SaveDraftMerchantDocument, operationOptions);
}
export const SubmitMerchantLeadDocument = gql`
    mutation SubmitMerchantLead($lead: MerchantLeadCreateInput!) {
        createMerchantLead(data: $lead) {
            id
        }
    }
`;
export class SubmitMerchantLeadComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            SubmitMerchantLeadMutation,
            SubmitMerchantLeadVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                SubmitMerchantLeadMutation,
                SubmitMerchantLeadVariables
            >
                mutation={SubmitMerchantLeadDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type SubmitMerchantLeadProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        SubmitMerchantLeadMutation,
        SubmitMerchantLeadVariables
    >
> &
    TChildProps;
export type SubmitMerchantLeadMutationFn = ReactApollo.MutationFn<
    SubmitMerchantLeadMutation,
    SubmitMerchantLeadVariables
>;
export function SubmitMerchantLeadHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              SubmitMerchantLeadMutation,
              SubmitMerchantLeadVariables,
              SubmitMerchantLeadProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        SubmitMerchantLeadMutation,
        SubmitMerchantLeadVariables,
        SubmitMerchantLeadProps<TChildProps>
    >(SubmitMerchantLeadDocument, operationOptions);
}
export const UpdateDraftMerchantDocument = gql`
    mutation UpdateDraftMerchant($id: ID!, $draft: DraftMerchantUpdateInput!) {
        updateDraftMerchant(data: $draft, where: { id: $id }) {
            ...DraftMerchantFields
        }
    }

    ${DraftMerchantFieldsFragmentDoc}
`;
export class UpdateDraftMerchantComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            UpdateDraftMerchantMutation,
            UpdateDraftMerchantVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                UpdateDraftMerchantMutation,
                UpdateDraftMerchantVariables
            >
                mutation={UpdateDraftMerchantDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type UpdateDraftMerchantProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        UpdateDraftMerchantMutation,
        UpdateDraftMerchantVariables
    >
> &
    TChildProps;
export type UpdateDraftMerchantMutationFn = ReactApollo.MutationFn<
    UpdateDraftMerchantMutation,
    UpdateDraftMerchantVariables
>;
export function UpdateDraftMerchantHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              UpdateDraftMerchantMutation,
              UpdateDraftMerchantVariables,
              UpdateDraftMerchantProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        UpdateDraftMerchantMutation,
        UpdateDraftMerchantVariables,
        UpdateDraftMerchantProps<TChildProps>
    >(UpdateDraftMerchantDocument, operationOptions);
}
export const CurrentUserMonthlyCommissionsDocument = gql`
    query CurrentUserMonthlyCommissions($month: String!) {
        currentUser {
            id
            monthlyCommissionCalculations(monthString: $month) {
                id
                merchantId
                businessName
                level
                amount
                legName
                legBusinessName
            }
        }
    }
`;
export class CurrentUserMonthlyCommissionsComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<
            CurrentUserMonthlyCommissionsQuery,
            CurrentUserMonthlyCommissionsVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Query<
                CurrentUserMonthlyCommissionsQuery,
                CurrentUserMonthlyCommissionsVariables
            >
                query={CurrentUserMonthlyCommissionsDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CurrentUserMonthlyCommissionsProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<
        CurrentUserMonthlyCommissionsQuery,
        CurrentUserMonthlyCommissionsVariables
    >
> &
    TChildProps;
export function CurrentUserMonthlyCommissionsHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CurrentUserMonthlyCommissionsQuery,
              CurrentUserMonthlyCommissionsVariables,
              CurrentUserMonthlyCommissionsProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CurrentUserMonthlyCommissionsQuery,
        CurrentUserMonthlyCommissionsVariables,
        CurrentUserMonthlyCommissionsProps<TChildProps>
    >(CurrentUserMonthlyCommissionsDocument, operationOptions);
}
export const CurrentUserOwnedBusinessesDocument = gql`
    query CurrentUserOwnedBusinesses {
        currentUser {
            id
            ownedBusinesses {
                id
                businessName
                monthlyCommissions(orderBy: monthString_ASC) {
                    id
                    monthString
                    amount
                }
                commissionDeposits(orderBy: dateString_ASC) {
                    id
                    dateString
                    amount
                }
            }
        }
    }
`;
export class CurrentUserOwnedBusinessesComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<
            CurrentUserOwnedBusinessesQuery,
            CurrentUserOwnedBusinessesVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Query<
                CurrentUserOwnedBusinessesQuery,
                CurrentUserOwnedBusinessesVariables
            >
                query={CurrentUserOwnedBusinessesDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CurrentUserOwnedBusinessesProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<
        CurrentUserOwnedBusinessesQuery,
        CurrentUserOwnedBusinessesVariables
    >
> &
    TChildProps;
export function CurrentUserOwnedBusinessesHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CurrentUserOwnedBusinessesQuery,
              CurrentUserOwnedBusinessesVariables,
              CurrentUserOwnedBusinessesProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CurrentUserOwnedBusinessesQuery,
        CurrentUserOwnedBusinessesVariables,
        CurrentUserOwnedBusinessesProps<TChildProps>
    >(CurrentUserOwnedBusinessesDocument, operationOptions);
}
export const CreateCommissionDepositDocument = gql`
    mutation CreateCommissionDeposit(
        $data: MerchantCommissionDepositCreateInput!
    ) {
        createMerchantCommissionDeposit(data: $data) {
            id
            dateString
            amount
        }
    }
`;
export class CreateCommissionDepositComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            CreateCommissionDepositMutation,
            CreateCommissionDepositVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                CreateCommissionDepositMutation,
                CreateCommissionDepositVariables
            >
                mutation={CreateCommissionDepositDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CreateCommissionDepositProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        CreateCommissionDepositMutation,
        CreateCommissionDepositVariables
    >
> &
    TChildProps;
export type CreateCommissionDepositMutationFn = ReactApollo.MutationFn<
    CreateCommissionDepositMutation,
    CreateCommissionDepositVariables
>;
export function CreateCommissionDepositHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CreateCommissionDepositMutation,
              CreateCommissionDepositVariables,
              CreateCommissionDepositProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CreateCommissionDepositMutation,
        CreateCommissionDepositVariables,
        CreateCommissionDepositProps<TChildProps>
    >(CreateCommissionDepositDocument, operationOptions);
}
export const CreateMonthlyCommissionDocument = gql`
    mutation CreateMonthlyCommission(
        $data: MerchantMonthlyCommissionCreateInput!
    ) {
        createMerchantMonthlyCommission(data: $data) {
            id
            amount
            key
            monthString
        }
    }
`;
export class CreateMonthlyCommissionComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            CreateMonthlyCommissionMutation,
            CreateMonthlyCommissionVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                CreateMonthlyCommissionMutation,
                CreateMonthlyCommissionVariables
            >
                mutation={CreateMonthlyCommissionDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type CreateMonthlyCommissionProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        CreateMonthlyCommissionMutation,
        CreateMonthlyCommissionVariables
    >
> &
    TChildProps;
export type CreateMonthlyCommissionMutationFn = ReactApollo.MutationFn<
    CreateMonthlyCommissionMutation,
    CreateMonthlyCommissionVariables
>;
export function CreateMonthlyCommissionHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              CreateMonthlyCommissionMutation,
              CreateMonthlyCommissionVariables,
              CreateMonthlyCommissionProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        CreateMonthlyCommissionMutation,
        CreateMonthlyCommissionVariables,
        CreateMonthlyCommissionProps<TChildProps>
    >(CreateMonthlyCommissionDocument, operationOptions);
}
export const DeleteCommissionDepositDocument = gql`
    mutation DeleteCommissionDeposit($id: ID!) {
        deleteMerchantCommissionDeposit(where: { id: $id }) {
            id
        }
    }
`;
export class DeleteCommissionDepositComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            DeleteCommissionDepositMutation,
            DeleteCommissionDepositVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                DeleteCommissionDepositMutation,
                DeleteCommissionDepositVariables
            >
                mutation={DeleteCommissionDepositDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type DeleteCommissionDepositProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        DeleteCommissionDepositMutation,
        DeleteCommissionDepositVariables
    >
> &
    TChildProps;
export type DeleteCommissionDepositMutationFn = ReactApollo.MutationFn<
    DeleteCommissionDepositMutation,
    DeleteCommissionDepositVariables
>;
export function DeleteCommissionDepositHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              DeleteCommissionDepositMutation,
              DeleteCommissionDepositVariables,
              DeleteCommissionDepositProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        DeleteCommissionDepositMutation,
        DeleteCommissionDepositVariables,
        DeleteCommissionDepositProps<TChildProps>
    >(DeleteCommissionDepositDocument, operationOptions);
}
export const UpdateCommissionDepositDocument = gql`
    mutation UpdateCommissionDeposit(
        $id: ID!
        $data: MerchantCommissionDepositUpdateInput!
    ) {
        updateMerchantCommissionDeposit(where: { id: $id }, data: $data) {
            id
            dateString
            amount
        }
    }
`;
export class UpdateCommissionDepositComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            UpdateCommissionDepositMutation,
            UpdateCommissionDepositVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                UpdateCommissionDepositMutation,
                UpdateCommissionDepositVariables
            >
                mutation={UpdateCommissionDepositDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type UpdateCommissionDepositProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        UpdateCommissionDepositMutation,
        UpdateCommissionDepositVariables
    >
> &
    TChildProps;
export type UpdateCommissionDepositMutationFn = ReactApollo.MutationFn<
    UpdateCommissionDepositMutation,
    UpdateCommissionDepositVariables
>;
export function UpdateCommissionDepositHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              UpdateCommissionDepositMutation,
              UpdateCommissionDepositVariables,
              UpdateCommissionDepositProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        UpdateCommissionDepositMutation,
        UpdateCommissionDepositVariables,
        UpdateCommissionDepositProps<TChildProps>
    >(UpdateCommissionDepositDocument, operationOptions);
}
export const UpdateMonthlyCommissionDocument = gql`
    mutation UpdateMonthlyCommission($id: ID!, $amount: Float!) {
        updateMerchantMonthlyCommission(
            where: { id: $id }
            data: { amount: $amount }
        ) {
            id
            amount
        }
    }
`;
export class UpdateMonthlyCommissionComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            UpdateMonthlyCommissionMutation,
            UpdateMonthlyCommissionVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                UpdateMonthlyCommissionMutation,
                UpdateMonthlyCommissionVariables
            >
                mutation={UpdateMonthlyCommissionDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type UpdateMonthlyCommissionProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        UpdateMonthlyCommissionMutation,
        UpdateMonthlyCommissionVariables
    >
> &
    TChildProps;
export type UpdateMonthlyCommissionMutationFn = ReactApollo.MutationFn<
    UpdateMonthlyCommissionMutation,
    UpdateMonthlyCommissionVariables
>;
export function UpdateMonthlyCommissionHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              UpdateMonthlyCommissionMutation,
              UpdateMonthlyCommissionVariables,
              UpdateMonthlyCommissionProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        UpdateMonthlyCommissionMutation,
        UpdateMonthlyCommissionVariables,
        UpdateMonthlyCommissionProps<TChildProps>
    >(UpdateMonthlyCommissionDocument, operationOptions);
}
export const ViewMerchantPageDocument = gql`
    query ViewMerchantPage($id: ID!) {
        merchant(where: { id: $id }) {
            id
            businessName
            industry
            merchantNumber
            owner {
                id
                firstName
                lastName
                phone
                email
                impersonationToken
            }
            monthlyCommissions(orderBy: monthString_DESC) {
                id
                monthString
                amount
            }
            commissionDeposits(orderBy: dateString_DESC) {
                id
                dateString
                amount
            }
        }
    }
`;
export class ViewMerchantPageComponent extends React.Component<
    Partial<
        ReactApollo.QueryProps<ViewMerchantPageQuery, ViewMerchantPageVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Query<ViewMerchantPageQuery, ViewMerchantPageVariables>
                query={ViewMerchantPageDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ViewMerchantPageProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<ViewMerchantPageQuery, ViewMerchantPageVariables>
> &
    TChildProps;
export function ViewMerchantPageHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ViewMerchantPageQuery,
              ViewMerchantPageVariables,
              ViewMerchantPageProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ViewMerchantPageQuery,
        ViewMerchantPageVariables,
        ViewMerchantPageProps<TChildProps>
    >(ViewMerchantPageDocument, operationOptions);
}
export const MakeMerchantDocument = gql`
    mutation MakeMerchant(
        $id: ID!
        $owner: UserCreateOneWithoutOwnedBusinessesInput!
    ) {
        convertMerchantLead(id: $id, owner: $owner) {
            id
        }
    }
`;
export class MakeMerchantComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<MakeMerchantMutation, MakeMerchantVariables>
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<MakeMerchantMutation, MakeMerchantVariables>
                mutation={MakeMerchantDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type MakeMerchantProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<MakeMerchantMutation, MakeMerchantVariables>
> &
    TChildProps;
export type MakeMerchantMutationFn = ReactApollo.MutationFn<
    MakeMerchantMutation,
    MakeMerchantVariables
>;
export function MakeMerchantHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              MakeMerchantMutation,
              MakeMerchantVariables,
              MakeMerchantProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        MakeMerchantMutation,
        MakeMerchantVariables,
        MakeMerchantProps<TChildProps>
    >(MakeMerchantDocument, operationOptions);
}
export const ViewMerchantDocument = gql`
    query ViewMerchant($id: ID!) {
        lead: merchantLead(where: { id: $id }) {
            ...ViewMerchantLeadFields
        }
    }

    ${ViewMerchantLeadFieldsFragmentDoc}
`;
export class ViewMerchantComponent extends React.Component<
    Partial<ReactApollo.QueryProps<ViewMerchantQuery, ViewMerchantVariables>>
> {
    render() {
        return (
            <ReactApollo.Query<ViewMerchantQuery, ViewMerchantVariables>
                query={ViewMerchantDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ViewMerchantProps<TChildProps = any> = Partial<
    ReactApollo.DataProps<ViewMerchantQuery, ViewMerchantVariables>
> &
    TChildProps;
export function ViewMerchantHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ViewMerchantQuery,
              ViewMerchantVariables,
              ViewMerchantProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ViewMerchantQuery,
        ViewMerchantVariables,
        ViewMerchantProps<TChildProps>
    >(ViewMerchantDocument, operationOptions);
}
export const ViewMerchantLeadCreateEventDocument = gql`
    mutation ViewMerchantLeadCreateEvent(
        $id: ID!
        $type: String!
        $time: DateTime!
    ) {
        updateMerchantLead(
            where: { id: $id }
            data: { events: { create: { type: $type, timestamp: $time } } }
        ) {
            id
        }
    }
`;
export class ViewMerchantLeadCreateEventComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            ViewMerchantLeadCreateEventMutation,
            ViewMerchantLeadCreateEventVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                ViewMerchantLeadCreateEventMutation,
                ViewMerchantLeadCreateEventVariables
            >
                mutation={ViewMerchantLeadCreateEventDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ViewMerchantLeadCreateEventProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        ViewMerchantLeadCreateEventMutation,
        ViewMerchantLeadCreateEventVariables
    >
> &
    TChildProps;
export type ViewMerchantLeadCreateEventMutationFn = ReactApollo.MutationFn<
    ViewMerchantLeadCreateEventMutation,
    ViewMerchantLeadCreateEventVariables
>;
export function ViewMerchantLeadCreateEventHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ViewMerchantLeadCreateEventMutation,
              ViewMerchantLeadCreateEventVariables,
              ViewMerchantLeadCreateEventProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ViewMerchantLeadCreateEventMutation,
        ViewMerchantLeadCreateEventVariables,
        ViewMerchantLeadCreateEventProps<TChildProps>
    >(ViewMerchantLeadCreateEventDocument, operationOptions);
}
export const ViewMerchantUpdateApplicationDocument = gql`
    mutation ViewMerchantUpdateApplication(
        $id: ID!
        $data: MerchantApplicationUpdateInput!
    ) {
        updateMerchantApplication(where: { id: $id }, data: $data) {
            ...AppFields
        }
    }

    ${AppFieldsFragmentDoc}
`;
export class ViewMerchantUpdateApplicationComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            ViewMerchantUpdateApplicationMutation,
            ViewMerchantUpdateApplicationVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                ViewMerchantUpdateApplicationMutation,
                ViewMerchantUpdateApplicationVariables
            >
                mutation={ViewMerchantUpdateApplicationDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ViewMerchantUpdateApplicationProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        ViewMerchantUpdateApplicationMutation,
        ViewMerchantUpdateApplicationVariables
    >
> &
    TChildProps;
export type ViewMerchantUpdateApplicationMutationFn = ReactApollo.MutationFn<
    ViewMerchantUpdateApplicationMutation,
    ViewMerchantUpdateApplicationVariables
>;
export function ViewMerchantUpdateApplicationHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ViewMerchantUpdateApplicationMutation,
              ViewMerchantUpdateApplicationVariables,
              ViewMerchantUpdateApplicationProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ViewMerchantUpdateApplicationMutation,
        ViewMerchantUpdateApplicationVariables,
        ViewMerchantUpdateApplicationProps<TChildProps>
    >(ViewMerchantUpdateApplicationDocument, operationOptions);
}
export const ViewMerchantUpdateLeadDocument = gql`
    mutation ViewMerchantUpdateLead($id: ID!, $data: MerchantLeadUpdateInput!) {
        updateMerchantLead(where: { id: $id }, data: $data) {
            ...ViewMerchantLeadFields
        }
    }

    ${ViewMerchantLeadFieldsFragmentDoc}
`;
export class ViewMerchantUpdateLeadComponent extends React.Component<
    Partial<
        ReactApollo.MutationProps<
            ViewMerchantUpdateLeadMutation,
            ViewMerchantUpdateLeadVariables
        >
    >
> {
    render() {
        return (
            <ReactApollo.Mutation<
                ViewMerchantUpdateLeadMutation,
                ViewMerchantUpdateLeadVariables
            >
                mutation={ViewMerchantUpdateLeadDocument}
                {...(this as any)['props'] as any}
            />
        );
    }
}
export type ViewMerchantUpdateLeadProps<TChildProps = any> = Partial<
    ReactApollo.MutateProps<
        ViewMerchantUpdateLeadMutation,
        ViewMerchantUpdateLeadVariables
    >
> &
    TChildProps;
export type ViewMerchantUpdateLeadMutationFn = ReactApollo.MutationFn<
    ViewMerchantUpdateLeadMutation,
    ViewMerchantUpdateLeadVariables
>;
export function ViewMerchantUpdateLeadHOC<TProps, TChildProps = any>(
    operationOptions:
        | ReactApollo.OperationOption<
              TProps,
              ViewMerchantUpdateLeadMutation,
              ViewMerchantUpdateLeadVariables,
              ViewMerchantUpdateLeadProps<TChildProps>
          >
        | undefined,
) {
    return ReactApollo.graphql<
        TProps,
        ViewMerchantUpdateLeadMutation,
        ViewMerchantUpdateLeadVariables,
        ViewMerchantUpdateLeadProps<TChildProps>
    >(ViewMerchantUpdateLeadDocument, operationOptions);
}
