import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Paper } from '@material-ui/core';
import style from './style';

export const AppLayout = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <Fragment>
                    <Navbar />
                    <Component {...matchProps} />
                </Fragment>
            )}
        />
    );
};

export const NoLinkAppLayout = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <Fragment>
                    <Navbar links={false} />
                    <Component {...matchProps} />
                </Fragment>
            )}
        />
    );
};

export const BlankLayout = ({ component: Component, ...rest }) => {
    const Content = style.div({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    });

    const Main = style(Paper)({
        // border: '5px solid red',
        margin: '0px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    });
    return (
        <Main>
            <Route
                {...rest}
                render={matchProps => (
                    <Content>
                        <Component {...matchProps} />
                    </Content>
                )}
            />
        </Main>
    );
};
